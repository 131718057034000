import React, { useState, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import FormSelect from 'react-bootstrap/FormSelect';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import number_1 from '../images/number-1.png';
import number_2 from '../images/number-2.png';
import number_3 from '../images/number-3.png';
import connected_icon from '../images/correct.png';
import axios from 'axios';
import Cookies from 'js-cookie';
import {showToast as toast} from '../toastManager';



function EnrichAndBid({ViewedPage, shop_domain, app_url}) {
    ViewedPage(5)

    // Facebook Settings
    const [facebook_ads_acc_pixel_json, setFacebookAdsAccPixelJson] = useState({});
    const [facebook_ads_account_select_dict, setFacebookAdsAccountSelectDict] = useState([]);
    const [facebook_all_account_details, setAllFBAccountDetails] = useState({});
    const [facebook_selected_account_details, setFBSelectedAccountDetails] = useState({});
    const [facebook_selected_ads_account, setFacebookSelectedAccount] = useState(null);
    const [facebook_selected_ads_account_pixel, setFacebookSelectedAccountPixel] = useState(null);
    const [facebook_Step2Indicator, setFacebookStep2Indicator] = useState(false);
    const [facebook_Step3Indicator, setFacebookStep3Indicator] = useState(false);
    const [facebook_loading, setFacebookLoading] = useState(true);
    const [facebook_all_account_unused_event_json, setFacebookAllAccountUnusedEventJson] = useState({});
    const [facebook_edit_Modal_active, setFacebookEditModalActive] = useState(false);
    const [facebook_setup_setting, setFacebookSetupSetting] = useState({"conv_display_name":"","id":"","db_id":"","name":"","detail":"","formula":""});
    const [facebook_recommendation_checkbox, setFacebookRecommendation_checkbox] = useState(false);
    const [facebook_send_revenue_with_tax, setFacebookSendRevenueWithTax] = useState(false);
    
    // Google Settings
    const [google_loaderData, setGoogleLoaderData ] = useState(null);
    const [google_ads_account_select_dict, setGoogleAdsAccountSelectDict] = useState([]);
    const [show_google_login_btn, setShowGoogleLogin] = useState(true);
    const [google_selected_account, setGoogleSelectedAccount] = useState([]);
    var [google_all_account_details, setGoogleAllAccountDetails] = useState({});
    var [google_selected_account_details, setGoogleSelectedAccountDetails] = useState({});
    const [google_active, setGoogleActive] = useState(false);
    const [google_edit_Modal_active, setGoogleEditModalActive] = useState(false);
    const [google_Step1Indicator, setGoogleStep1Indicator] = useState(!show_google_login_btn);
    const [google_Step2Indicator, setGoogleStep2Indicator] = useState(false);
    const [google_Step3Indicator, setGoogleStep3Indicator] = useState(false);
    const [google_loading, setGoogleLoading] = useState(true);
    const [google_setup_setting, setGoogleSetupSetting] = useState({"conv_display_name":"","id":"","db_id":"","name":"","detail":"","formula":""});
    const [google_recommendation_checkbox, setGoogleRecommendation_checkbox] = useState(false);
    const [google_send_revenue_with_tax, setGoogleSendRevenueWithTax] = useState(false);

    const handle_sign_out = async (e) => {
        e.preventDefault();
        try {
            if(Cookies.get('shop')){
                const { data } = await axios.post(app_url+"/api/google/sign_out",{
                    shop_domain:Cookies.get('shop')
                }, {withCredentials:true});
                if(data.success){
                    toast.success(data.message);
                    setShowGoogleLogin(true);
                    setGoogleSelectedAccount("");
                    setGoogleAllAccountDetails({});
                    setGoogleSelectedAccountDetails({
                        send_adjustment_in_main_conv:"null",
                        is_show_new_customer_setup_btn:true,
                        is_show_profit_setup_btn: true,
                        is_show_netRevenue_setup_btn: true,
                        is_show_aov_setup_btn: true,
                        is_show_new_customer_sync_off_btn:true,
                        is_show_profit_sync_off_btn: true,
                        is_show_netRevenue_sync_off_btn: true,
                        is_show_aov_sync_off_btn:true,
                        global_site_tag: "",
                        new_customer_event_snippet:"",
                        profit_event_snippet: "",
                        netRevenue_event_snippet: "",
                        aov_event_snippet: "",
                        profit_conv_label: "",
                        new_customer_conv_label:"",
                        netRevenue_conv_label: "",
                        aov_conv_label: "",
                        aov_value_limit:100,
                    });
                    setGoogleStep2Indicator(false);
                    setGoogleStep3Indicator(false);
                }
                else{
                    toast.error(data.message);
                }
            }
        } catch (error) {
            toast.error("Please try again.");
        }
    };


    const handle_change_ads_account = async (e) => {
        e.preventDefault();
        try {
            if(Cookies.get('shop')){
                var ad_account = document.getElementById("ads_account_select").value;
                const { data } = await axios.post(app_url+"/api/google/change_ads_account",{
                    shop_domain:Cookies.get('shop'),
                    ads_account:ad_account
                }, {withCredentials:true});
                if(data.success){
                    toast.success(data.message);
                    setGoogleSelectedAccount(ad_account);
                    setGoogleSelectedAccountDetails(google_all_account_details[ad_account]);
                    var step2_flag = false;
                    if( (show_google_login_btn === false) &&
                    ((google_all_account_details[ad_account]["is_show_profit_setup_btn"] === false) ||  
                    (google_all_account_details[ad_account]["is_show_netRevenue_setup_btn"] === false) ||
                    (google_all_account_details[ad_account]["is_show_new_customer_setup_btn"] === false) ||    
                    (google_all_account_details[ad_account]["is_show_aov_setup_btn"] === false))){    
                      step2_flag = true;
                    }
                    setGoogleStep2Indicator(step2_flag)
                }
                else{
                    toast.error(data.message);
                }
            }
        } catch (error) {
            toast.error("Please try again.");
        }
    };


    useEffect(() => {
        setFacebookLoading(true);
        setGoogleLoading(true);
        try {
            if(Cookies.get('shop')){
                console.log("Facebook",new Date())
                // Facebook Loader data
                axios.post(app_url+"/api/facebook/get_loader_data",{
                    shop_domain:Cookies.get('shop')
                }, {withCredentials:true})
                .then(res => res.data)
                .then(data => {
                    if(data.success){

                        var temp_loader_data = data.data;
                        console.log(temp_loader_data)
                        var temp_ads_acc_pixel_json = {};
                        temp_loader_data["ads_acc_pixel_json"].map(function (ad_account_iter){
                            temp_ads_acc_pixel_json[ad_account_iter["id"]] = [];
                            ad_account_iter["pixel_id"].map(function (pixel_iter){
                                temp_ads_acc_pixel_json[ad_account_iter["id"]].push({"label":pixel_iter["id"] + " - " + pixel_iter["name"], "value":pixel_iter["id"]})
                                return null;
                            })
                            return null;
                        })
                        setFacebookAdsAccPixelJson(temp_ads_acc_pixel_json);
                        setFacebookAdsAccountSelectDict(temp_loader_data["ads_acc_pixel_json"].length > 0 ? temp_loader_data["ads_acc_pixel_json"].map((ad_account_iter) => { return {"label":ad_account_iter["id"] + " - " + ad_account_iter["name"], "value":ad_account_iter["id"]}}) : []);                      
                        setAllFBAccountDetails(temp_loader_data["all_account_details"]);
                        setFBSelectedAccountDetails(temp_loader_data["account_details"]);
                        setFacebookSelectedAccount(temp_loader_data["selected_ads_account"]);
                        setFacebookSelectedAccountPixel(temp_loader_data?.selected_ads_account_pixel);
                        setFacebookAllAccountUnusedEventJson(temp_loader_data["all_account_unused_event_json"]);
                        
                        var step2_flag = true;
                        if( (temp_loader_data["is_show_continue_with_facebook_btn"] === false) &&
                        ((temp_loader_data["account_details"]["is_show_profit_sync_off_btn"] === false) ||  
                        (temp_loader_data["account_details"]["is_show_netRevenue_sync_off_btn"] === false) ||
                        (temp_loader_data["account_details"]["is_show_aov_sync_off_btn"] === false) ||  
                        (temp_loader_data["account_details"]["is_show_new_customer_sync_off_btn"] === false))){    
                          step2_flag = false;
                        }
                        setFacebookStep2Indicator(step2_flag);
                        setFacebookStep3Indicator(false);
                        setFacebookRecommendation_checkbox(temp_loader_data["send_recommendations"]);
                        setFacebookSendRevenueWithTax(temp_loader_data["send_revenue_with_tax"]);

                        setFacebookLoading(false);
                        console.log("End Facebook",new Date())
                    }
                })
                .catch(error => {
                    setFacebookLoading(false);
                    toast.error(error)
                })

                console.log("Google",new Date())
                // Google Loader Data
                axios.post(app_url+"/api/google/get_loader_data",{
                    shop_domain:Cookies.get('shop')
                }, {withCredentials:true})
                .then(res => res.data)
                .then(data => {
                    if(data.success){
                        console.log("Google got res",new Date())
                        setGoogleLoaderData(data.data);
                        // Render Select Ads Account and conversion action setting
                        var temp_loader_data = data.data;
                        setGoogleAdsAccountSelectDict(temp_loader_data["account_list"].map((ad_account) => { return {"label":ad_account, "value":ad_account.split(" - ")[0]}}));
                        setShowGoogleLogin(temp_loader_data["show_google_login_btn"]);
                        setGoogleSelectedAccount(temp_loader_data["selected_account"]);
                        setGoogleAllAccountDetails(temp_loader_data["all_account_details"]);
                        setGoogleSelectedAccountDetails(temp_loader_data["account_details"]);
                        setGoogleActive(false);
                        setGoogleEditModalActive(false);
                        var step2_flag = false;
                        if( (temp_loader_data["show_google_login_btn"] === false) &&
                        ((temp_loader_data["account_details"]["is_show_profit_setup_btn"] === false) ||  
                        (temp_loader_data["account_details"]["is_show_netRevenue_setup_btn"] === false) ||
                        (temp_loader_data["account_details"]["is_show_aov_setup_btn"] === false) ||  
                        (temp_loader_data["account_details"]["is_show_new_customer_setup_btn"] === false))){    
                            step2_flag = true;
                        }
                        setGoogleStep1Indicator(!temp_loader_data["show_google_login_btn"]);
                        setGoogleStep2Indicator(step2_flag);
                        setGoogleStep3Indicator(false);
                        setGoogleLoading(false);
                        setGoogleSetupSetting({"conv_display_name":"","id":"","db_id":"","name":"","detail":"","formula":""});
                        setGoogleRecommendation_checkbox(temp_loader_data["send_recommendations"]);
                        setGoogleSendRevenueWithTax(temp_loader_data["send_revenue_with_tax"]);
                        console.log("End Google",new Date())
                    }
                })
                .catch(error => {
                    toast.error(error)
                })
            }
        } catch (error) {
            setFacebookLoading(false);
            toast.error(error)
        }
    }, [app_url, shop_domain]);

    const handleGoogleClose = () => setGoogleActive(false);
    const handleGoogleShow = () => setGoogleActive(true);
    const handleGoogleSubmitSetup = () => {
        setGoogleLoading(true);
        setGoogleActive(false);
        fetch(
            "https://us-central1-voice-reporting-engine.cloudfunctions.net/shopify-create-conversion-action",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
              body: JSON.stringify({
                "shop_domain": shop_domain,
                "customer_id": google_selected_account,
                "conversion_name": google_setup_setting["db_id"],
              }),
            }
          )
          .then((response) => response.json())
          .then((data) => {
            google_selected_account_details[`is_show_${google_setup_setting["id"]}_setup_btn`] = false;
            google_selected_account_details[`is_show_${google_setup_setting["id"]}_sync_off_btn`] = false;
            google_selected_account_details["global_site_tag"] = data["global_site_tag"]
            if(google_setup_setting["db_id"] === "profit"){
              google_selected_account_details["profit_event_snippet"] = data["snippet"];
              google_selected_account_details["profit_conv_label"] = data["conversion_label"];
            }
            else if(google_setup_setting["db_id"] === "new_customer"){
              google_selected_account_details["new_customer_event_snippet"] = data["snippet"];
              google_selected_account_details["new_customer_conv_label"] = data["conversion_label"];
            }
            else if(google_setup_setting["db_id"] === "net_revenue"){
              google_selected_account_details["netRevenue_event_snippet"] = data["snippet"];
              google_selected_account_details["netRevenue_conv_label"] = data["conversion_label"];
            }
            else{
              google_selected_account_details["aov_event_snippet"] = data["snippet"];
              google_selected_account_details["aov_conv_label"] = data["conversion_label"];
            }
            google_all_account_details[google_selected_account] = google_selected_account_details
            setGoogleAllAccountDetails(google_all_account_details);
            setGoogleSelectedAccountDetails(google_selected_account_details);
            setGoogleLoading(false);
            if(
              (google_selected_account_details["is_show_profit_setup_btn"] === false) ||
              (google_selected_account_details["is_show_netRevenue_setup_btn"] === false) ||
              (google_selected_account_details["is_show_new_customer_setup_btn"] === false) ||
              (google_selected_account_details["is_show_aov_setup_btn"] === false)){    
                setGoogleStep2Indicator(true);
            }
          })
          .catch((error) => {
            setGoogleLoading(false);
            toast.error("Conversion action not created.\nPlease try again.")
            console.log("Error :- " + error);
          });
          if(google_setup_setting["db_id"] === "higher_aov"){
            google_selected_account_details["aov_value_limit"] = document.getElementById("aov_value_limit_id").value;
            google_all_account_details[google_selected_account] = google_selected_account_details
            setGoogleAllAccountDetails(google_all_account_details);
            setGoogleSelectedAccountDetails(google_selected_account_details)
            axios.post(app_url+"/api/google/save_aov_value_limit",{
                "shop_domain":shop_domain.replace(".myshopify.com",""),
                "aov_value_limit":document.getElementById("aov_value_limit_id").value,
                "ad_account":google_selected_account
            }, {withCredentials:true})
        }
    };
    const handleGoogleEditClose = () => setGoogleEditModalActive(false);
    const handleGoogleEditShow = () => setGoogleEditModalActive(true);
    const handleGoogleSubmitEdit = () => {
        if(google_setup_setting["db_id"] === "higher_aov"){
            google_selected_account_details["aov_value_limit"] = document.getElementById("aov_value_limit_id").value;
            google_all_account_details[google_selected_account] = google_selected_account_details
            setGoogleAllAccountDetails(google_all_account_details);
            setGoogleSelectedAccountDetails(google_selected_account_details)
            axios.post(app_url+"/api/google/save_aov_value_limit",{
                "shop_domain":shop_domain.replace(".myshopify.com",""),
                "aov_value_limit":document.getElementById("aov_value_limit_id").value,
                "ad_account":google_selected_account
            }, {withCredentials:true})
            setGoogleEditModalActive(false)
        }
    };
    
    function handleGoogleSyncStatusChange(conv_dict_id,conv_name,sync_status){
        google_selected_account_details[conv_dict_id] = sync_status === "on" ? false:true;
        google_all_account_details[google_selected_account] = google_selected_account_details
        setGoogleAllAccountDetails(google_all_account_details);
        setGoogleSelectedAccountDetails(google_selected_account_details);
        axios.post(app_url+"/api/google/change_sync_status",{
            "shop_domain":shop_domain.replace(".myshopify.com",""),
            "ad_account":google_selected_account,
            "conv_settings_id":conv_dict_id,
            "conv_name":conv_name,
            "sync_status":sync_status
        }, {withCredentials:true})
    }

    const handleFacebookChangeAdsAccount = async (e) => {
        e.preventDefault();
        try{
            if(Cookies.get('shop')){
                var ad_account = document.getElementById("ads_account_select").value;
                const { data } = await axios.post(app_url+"/api/facebook/change_ads_account",{
                    shop_domain:Cookies.get('shop'),
                    ads_account:ad_account,
                    pixel_id:facebook_all_account_details[ad_account]["selected_pixel_id"]
                }, {withCredentials:true});
                if(data.success){
                    toast.success(data.message);
                    setFacebookSelectedAccountPixel(facebook_all_account_details[ad_account]["selected_pixel_id"]);
                    setFacebookSelectedAccount(ad_account);
                    setFBSelectedAccountDetails(facebook_all_account_details[ad_account][facebook_all_account_details[ad_account]["selected_pixel_id"]]);
                    var step2_flag = true;
                    if((facebook_all_account_details[ad_account][facebook_all_account_details[ad_account]["selected_pixel_id"]]["is_show_profit_sync_off_btn"] === false) ||  
                    (facebook_all_account_details[ad_account][facebook_all_account_details[ad_account]["selected_pixel_id"]]["is_show_netRevenue_sync_off_btn"] === false) ||
                    (facebook_all_account_details[ad_account][facebook_all_account_details[ad_account]["selected_pixel_id"]]["is_show_aov_sync_off_btn"] === false) ||  
                    (facebook_all_account_details[ad_account][facebook_all_account_details[ad_account]["selected_pixel_id"]]["is_show_new_customer_sync_off_btn"] === false)){    
                      step2_flag = false;
                    }
                    setFacebookStep2Indicator(step2_flag);
                }
                else{
                    toast.error(data.message);
                }
            }
        } catch(err){
            toast.error("Please try again.")
        }
    }

    
    const handleFacebookChangeAdsAccountPixel = async (e) => {
        e.preventDefault();
        try{
            if(Cookies.get('shop')){
                var ad_account_pixel = document.getElementById("ads_account_pixel_select").value;
                const { data } = await axios.post(app_url+"/api/facebook/change_ads_account_pixel",{
                    shop_domain:Cookies.get('shop'),
                    ads_account:facebook_selected_ads_account,
                    pixel_id:ad_account_pixel
                }, {withCredentials:true});
                if(data.success){
                    toast.success(data.message);
                    facebook_all_account_details[facebook_selected_ads_account]["selected_pixel_id"] = ad_account_pixel;
                    setFacebookSelectedAccountPixel(ad_account_pixel);
                    setFBSelectedAccountDetails(facebook_all_account_details[facebook_selected_ads_account][ad_account_pixel]); 
                    setAllFBAccountDetails(facebook_all_account_details);
                    var step2_flag = true;
                    if((facebook_all_account_details[facebook_selected_ads_account][ad_account_pixel]["is_show_profit_sync_off_btn"] === false) ||  
                    (facebook_all_account_details[facebook_selected_ads_account][ad_account_pixel]["is_show_netRevenue_sync_off_btn"] === false) ||
                    (facebook_all_account_details[facebook_selected_ads_account][ad_account_pixel]["is_show_aov_sync_off_btn"] === false) ||  
                    (facebook_all_account_details[facebook_selected_ads_account][ad_account_pixel]["is_show_new_customer_sync_off_btn"] === false)){    
                      step2_flag = false;
                    }
                    setFacebookStep2Indicator(step2_flag);
                }
                else{
                    toast.error(data.message);
                }
            }
        } catch(err){
            toast.error("Please try again.")
        }
    }

    
    function handleFacebookSyncStatusChange(conv_dict_id,event_name_id,conv_name,sync_status){
        try{
            if(Cookies.get('shop')){
                var event_name_for_req = facebook_selected_account_details[event_name_id] === "" ? facebook_get_unused_event_name(conv_name): facebook_selected_account_details[event_name_id]
                axios.post(app_url+"/api/facebook/change_sync_status",{
                    "shop_domain":shop_domain.replace(".myshopify.com",""),
                    "conv_settings_id":conv_dict_id,
                    "ads_account":facebook_selected_ads_account,
                    "pixel_id":facebook_selected_ads_account_pixel,
                    "conv_name":conv_name,
                    "sync_status":sync_status,
                    "event_name":event_name_for_req
                }, {withCredentials:true})
                .then((request) => request.data)
                .then((data) => {
                    if(data.success){
                        var temp_selected_account_details = { ...facebook_selected_account_details }
                        var temp_all_account_details = { ...facebook_all_account_details }
                        temp_selected_account_details[conv_dict_id] = sync_status === "on" ? true:false; 
                        temp_selected_account_details[event_name_id] = event_name_for_req; 
                        temp_all_account_details[facebook_selected_ads_account][facebook_selected_ads_account_pixel] = temp_selected_account_details
                        setAllFBAccountDetails(temp_all_account_details);
                        setFBSelectedAccountDetails(temp_selected_account_details)
                        toast.success(data.message)
                    }
                    else{
                        toast.error(data.message)
                    }
                })
            }
        } catch(err){
            toast.error("Please try again.")
            console.error(err)
        }
    }

    function facebook_get_unused_event_name(conv_name){
        if(facebook_all_account_unused_event_json[facebook_selected_ads_account].length > 0){
            var event_name = facebook_all_account_unused_event_json[facebook_selected_ads_account][0];
            facebook_all_account_unused_event_json[facebook_selected_ads_account].shift();
            setFacebookAllAccountUnusedEventJson(facebook_all_account_unused_event_json)
            return event_name;
        }
        else{
            return conv_name.split("_").map((x) => x[0].toUpperCase() + x.slice(1)).join('') + "_Shopify_DM";
        }
    }

    
    const handleFacebookEditClose = () => setFacebookEditModalActive(false);
    const handleFacebookEditShow = () => setFacebookEditModalActive(true);
    const handleFacebookSubmitEdit = () => {
        if(facebook_setup_setting["db_id"] === "higher_aov"){
            var temp_selected_account_details = { ...facebook_selected_account_details }
            var temp_all_account_details = { ...facebook_all_account_details }
            temp_selected_account_details["aov_value_limit"] = document.getElementById("aov_value_limit_id").value;
            facebook_all_account_details[facebook_selected_ads_account] = temp_selected_account_details
            setAllFBAccountDetails(temp_all_account_details);
            setFBSelectedAccountDetails(temp_selected_account_details)
            axios.post(app_url+"/api/facebook/save_aov_value_limit",{
                "shop_domain":shop_domain.replace(".myshopify.com",""),
                "aov_value_limit":document.getElementById("aov_value_limit_id").value,
                "ad_account":facebook_selected_ads_account,
                "pixel_id":facebook_selected_ads_account_pixel
            }, {withCredentials:true})
            setFacebookEditModalActive(false)
        }
    };


    return (
        <div className='main-container'>
            {!shop_domain?
                <div className='go_to_shopify_admin_div'>
                    <h6 style={{display:'inline-block'}}>!! Go to Shopify Admin and open our app !!</h6>
                </div>
                :
                <>
                    <Modal show={facebook_edit_Modal_active} onHide={handleFacebookEditClose}>
                        <Modal.Header closeButton>
                        <Modal.Title>{`Conversion Action: ${facebook_setup_setting["conv_display_name"]}`}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p><b>Details</b> : {facebook_setup_setting["detail"]} (Formula : {facebook_setup_setting["formula"]})</p>
                            <p><b>Conversion Name {"("}on Facebook Ads{")"}</b> : {facebook_setup_setting["name"].split(" ").join("")}_Shopify_DM</p>
                            {facebook_setup_setting["db_id"] === "higher_aov" &&
                            <>
                                <label htmlFor="aov_value_limit_id"><b>Price</b> : </label>
                                <input style={{borderRadius:"10px", padding:"5px", border:"1px solid grey"}} type="number" id="aov_value_limit_id" placeholder={facebook_selected_account_details["aov_value_limit"]} />
                            </>
                            }
                        </Modal.Body>
                        <Modal.Footer>
                            <Button className="enrich_bid_button" variant="secondary" onClick={handleFacebookEditClose}>
                                Close
                            </Button>
                            <Button className="enrich_bid_button" variant="primary" onClick={handleFacebookSubmitEdit}>
                                Save
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={google_active} onHide={handleGoogleClose}>
                        <Modal.Header closeButton>
                        <Modal.Title>{`Conversion Action: ${google_setup_setting["conv_display_name"]}`}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>            
                            <p><b>Details</b> : {google_setup_setting["detail"]} (Formula : {google_setup_setting["formula"]})</p>
                            <p><b>Conversion Name {"("}on Google Ads{")"}</b> : {google_setup_setting["name"].split(" ").join("")}_Shopify_DM</p>
                            {google_setup_setting["db_id"] === "higher_aov" &&
                            <>
                                <label htmlFor="aov_value_limit_id"><b>Price</b> : </label>
                                <input style={{borderRadius:"10px", padding:"5px", border:"1px solid grey"}} type="number" id="aov_value_limit_id" placeholder={google_selected_account_details["aov_value_limit"]} />
                            </>
                            }
                        </Modal.Body>
                        <Modal.Footer>
                            <Button className="enrich_bid_button" variant="secondary" onClick={handleGoogleClose}>
                                Close
                            </Button>
                            <Button className="enrich_bid_button" variant="primary" onClick={handleGoogleSubmitSetup}>
                                Setup
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal show={google_edit_Modal_active} onHide={handleGoogleEditClose}>
                        <Modal.Header closeButton>
                        <Modal.Title>{`Conversion Action: ${google_setup_setting["conv_display_name"]}`}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p><b>Details</b> : {google_setup_setting["detail"]} (Formula : {google_setup_setting["formula"]})</p>
                            <p><b>Conversion Name {"("}on Google Ads{")"}</b> : {google_setup_setting["name"].split(" ").join("")}_Shopify_DM</p>
                            {google_setup_setting["db_id"] === "higher_aov" &&
                            <>
                                <label htmlFor="aov_value_limit_id"><b>Price</b> : </label>
                                <input style={{borderRadius:"10px", padding:"5px", border:"1px solid grey"}} type="number" id="aov_value_limit_id" placeholder={google_selected_account_details["aov_value_limit"]} />
                            </>
                            }
                        </Modal.Body>
                        <Modal.Footer>
                            <Button className="enrich_bid_button" variant="secondary" onClick={handleGoogleEditClose}>
                                Close
                            </Button>
                            <Button className="enrich_bid_button" variant="primary" onClick={handleGoogleSubmitEdit}>
                                Save
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    { ((facebook_loading !== true) && (google_loading !== true)) ?
                        <>
                           <div className='main-title mt-4'>
                                <h2>Google Ads Integration</h2>
                            </div>
                            <div className='main-accordion-body mt-4 p-2'>
                            <Accordion defaultActiveKey={ google_Step2Indicator ? google_Step3Indicator ? "3" : "2" : "1" }>

                               {/* Step - 2 (Setup conversion action(s) ) */}
                               <Accordion.Item eventKey="1">
                                   <Accordion.Header>
                                       <img src={number_1} alt='1' width={"15px"} height={"15px"} /> 
                                       <b style={{marginLeft:"10px"}}>Set up Conversion Action(s)</b> 
                                       { google_Step2Indicator && <img alt='connected' style={{padding:"6px", marginRight:"10px"}} src={connected_icon} width={"30px"}/> }
                                   </Accordion.Header>
                                   <Accordion.Body>
                                       <FloatingLabel controlId='floatingSelect' label='Select Ads Account'>
                                           <FormSelect defaultValue={google_selected_account} id='ads_account_select' onChange={handle_change_ads_account}>
                                               { google_ads_account_select_dict.map((ads_acc_iter) => <option key={ads_acc_iter["value"]} value={ads_acc_iter["value"]}>{ads_acc_iter["label"]}</option>)}
                                           </FormSelect>
                                       </FloatingLabel>


                                       <Row xs={1} sm={3}>
                                           <Col className='main_conv_col'>
                                               <Card className='main_conv_card'>
                                                   <Card.Body>
                                                       <div className='main-conv-card-body-left'>
                                                           <h5 className='main-conv-card-heading' >Profit</h5>
                                                           <p className='main-conv-card-p'>Total Sales after deducting Cost of Items</p>
                                                           { google_selected_account_details["is_show_profit_setup_btn"] ?
                                                               <Button className="enrich_bid_button" variant='primary' onClick={() => {
                                                                   google_setup_setting["conv_display_name"] = "Profit"
                                                                   google_setup_setting["db_id"] = "profit"
                                                                   google_setup_setting["id"] = "profit"
                                                                   google_setup_setting["name"] = "Profit"
                                                                   google_setup_setting["detail"] = "Total Sales after deducting Cost of Items"
                                                                   google_setup_setting["formula"] = "Current Total - Current Tax - Unit Cost"
                                                                   setGoogleSetupSetting(google_setup_setting);
                                                                   handleGoogleShow();
                                                               }}>Setup</Button>
                                                               :
                                                                google_selected_account_details["is_show_profit_sync_off_btn"] ?
                                                                   <Button className="enrich_bid_button" variant='secondary' onClick={() => {handleGoogleSyncStatusChange("is_show_profit_sync_off_btn","profit","on")}}>Sync is On</Button>
                                                                   :
                                                                   <Button className="enrich_bid_button" variant='success' onClick={() => {handleGoogleSyncStatusChange("is_show_profit_sync_off_btn","profit","off")}}>Sync is Off</Button>
                                                           }
                                                       </div>
                                                   </Card.Body>
                                               </Card>
                                           </Col>
                                           <Col className='main_conv_col'>
                                               <Card className='main_conv_card'>
                                                   <Card.Body>
                                                       <div className='main-conv-card-body-left'>
                                                           <h5 className='main-conv-card-heading' >Net Revenue</h5>
                                                           <p className='main-conv-card-p'>Total Sales after returns</p>
                                                           { google_selected_account_details["is_show_netRevenue_setup_btn"] ?
                                                               <Button className="enrich_bid_button" variant='primary' onClick={() => {
                                                                   google_setup_setting["conv_display_name"] = "Net Revenue"
                                                                   google_setup_setting["db_id"] = "net_revenue"
                                                                   google_setup_setting["id"] = "netRevenue"
                                                                   google_setup_setting["name"] = "Net Revenue"
                                                                   google_setup_setting["detail"] = "Total Sales after returns"
                                                                   google_setup_setting["formula"] = "Current Total - Current Tax"
                                                                  setGoogleSetupSetting(google_setup_setting);
                                                                  handleGoogleShow();
                                                              }}>Setup</Button>
                                                              :
                                                                google_selected_account_details["is_show_netRevenue_sync_off_btn"] ?
                                                                  <Button className="enrich_bid_button" variant='secondary' onClick={() => {handleGoogleSyncStatusChange("is_show_netRevenue_sync_off_btn","net_revenue","on")}}>Sync is On</Button>
                                                                   :
                                                                   <Button className="enrich_bid_button" variant='success' onClick={() => {handleGoogleSyncStatusChange("is_show_netRevenue_sync_off_btn","net_revenue","off")}}>Sync is Off</Button>
                                                           }
                                                       </div>
                                                   </Card.Body>
                                               </Card>
                                           </Col>
                                           <Col className='main_conv_col'>
                                               <Card className='main_conv_card'>
                                                   <Card.Body>
                                                       <div className='main-conv-card-body-left'>
                                                           <h5 className='main-conv-card-heading' >Higher AOV</h5>
                                                           <p className='main-conv-card-p'>Orders with revenue greater than {google_selected_account_details["aov_value_limit"]}</p>
                                                           {google_selected_account_details["is_show_aov_setup_btn"] === false &&
                                                               <>
                                                                   <Button className="enrich_bid_button" variant='primary' onClick={ () => {
                                                                       google_setup_setting["conv_display_name"] = "Higher AOV"
                                                                       google_setup_setting["db_id"] = "higher_aov"
                                                                       google_setup_setting["id"] = "aov"
                                                                       google_setup_setting["name"] = "Higher AOV"
                                                                       google_setup_setting["detail"] = `Orders with revenue greater than ${google_selected_account_details["aov_value_limit"]}`
                                                                       google_setup_setting["formula"] = `Revenue > ${google_selected_account_details["aov_value_limit"]}`
                                                                       setGoogleSetupSetting(google_setup_setting);
                                                                       handleGoogleEditShow();
                                                                   }}>Edit</Button>
                                                                   {" "}
                                                               </>

                                                           }
                                                           { google_selected_account_details["is_show_aov_setup_btn"] ?
                                                               <Button className="enrich_bid_button" variant='primary' onClick={() => {
                                                                   google_setup_setting["conv_display_name"] = "Higher AOV"
                                                                   google_setup_setting["db_id"] = "higher_aov"
                                                                   google_setup_setting["id"] = "aov"
                                                                   google_setup_setting["name"] = "Higher AOV"
                                                                   google_setup_setting["detail"] = `Orders with revenue greater than ${google_selected_account_details["aov_value_limit"]}`
                                                                   google_setup_setting["formula"] = `Revenue > ${google_selected_account_details["aov_value_limit"]}`
                                                                   setGoogleSetupSetting(google_setup_setting);
                                                                   handleGoogleShow();
                                                               }}>Setup</Button>
                                                               :
                                                               google_selected_account_details["is_show_aov_sync_off_btn"] ?
                                                                   <Button className="enrich_bid_button" variant='secondary' onClick={() => {handleGoogleSyncStatusChange("is_show_aov_sync_off_btn","higher_aov","on")}}>Sync is On</Button>
                                                                   :
                                                                   <Button className="enrich_bid_button" variant='success' onClick={() => {handleGoogleSyncStatusChange("is_show_aov_sync_off_btn","higher_aov","off")}}>Sync is Off</Button>
                                                           }
                                                       </div>
                                                   </Card.Body>
                                               </Card>
                                         </Col>
                                         <Col className='main_conv_col'>
                                             <Card className='main_conv_card'>
                                                 <Card.Body>
                                                     <div className='main-conv-card-body-left'>
                                                         <h5 className='main-conv-card-heading' >New Customer</h5>
                                                         <p className='main-conv-card-p'>First-time online shopper or buyer</p>
                                                         { google_selected_account_details["is_show_new_customer_setup_btn"] ?
                                                             <Button className="enrich_bid_button" variant='primary' onClick={() => {
                                                                 google_setup_setting["conv_display_name"] = "New Customer"
                                                                 google_setup_setting["db_id"] = "new_customer"
                                                                 google_setup_setting["id"] = "new_customer"
                                                                 google_setup_setting["name"] = "New Customer"
                                                                 google_setup_setting["detail"] = "First-time online shopper or buyer."
                                                                 google_setup_setting["formula"] = ""
                                                                 setGoogleSetupSetting(google_setup_setting);
                                                                   handleGoogleShow();
                                                               }}>Setup</Button>
                                                               :
                                                               google_selected_account_details["is_show_new_customer_sync_off_btn"] ?
                                                                   <Button className="enrich_bid_button" variant='secondary' onClick={() => {handleGoogleSyncStatusChange("is_show_new_customer_sync_off_btn","new_customer","on")}}>Sync is On</Button>
                                                                   :
                                                                   <Button className="enrich_bid_button" variant='success' onClick={() => {handleGoogleSyncStatusChange("is_show_new_customer_sync_off_btn","new_customer","off")}}>Sync is Off</Button>
                                                           }
                                                       </div>
                                                   </Card.Body>
                                               </Card>
                                           </Col>
                                           { shop_domain.includes("quit-with-jones") &&
                                                <Col className='main_conv_col'>
                                                    <Card className='main_conv_card'>
                                                        <Card.Body>
                                                            <div className='main-conv-card-body-left'>
                                                                <h5 className='main-conv-card-heading' >Subscription</h5>
                                                                <p className='main-conv-card-p'>Orders that have the tag 'Subscription'</p>
                                                                <Button className="enrich_bid_button" variant='success'>Sync is On</Button>
                                                            </div>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                           }
                                       </Row>

                                       <div key={`inline-radio`} className="mt-5">
                                           <Form.Label><b>Do you recognize revenue with or without tax on Google ads ?</b></Form.Label>
                                           <br />
                                           <Form.Check
                                               inline
                                               checked={!google_send_revenue_with_tax}
                                               label="Without Tax"
                                               name="group1"
                                               type={'radio'}
                                               id={`inline-radio-1`}
                                               onChange={()=>{
                                                   setGoogleSendRevenueWithTax(false);
                                                   axios.post(app_url+"/api/google/send_revenue_with_tax",{
                                                       "shop_domain":shop_domain.replace(".myshopify.com",""),
                                                       "status":false
                                                   }, {withCredentials:true})
                                               }}
                                               />
                                           <Form.Check
                                               inline
                                               checked={google_send_revenue_with_tax}
                                               label="With Tax"
                                               name="group1"
                                               type={'radio'}
                                               id={`inline-radio-2`}
                                               onChange={()=>{
                                                   setGoogleSendRevenueWithTax(true);
                                                   axios.post(app_url+"/api/google/send_revenue_with_tax",{
                                                       "shop_domain":shop_domain.replace(".myshopify.com",""),
                                                       "status":"true"
                                                   }, {withCredentials:true})
                                               }}
                                               />
                                       </div>
                                   </Accordion.Body>
                               </Accordion.Item>

                               {/* Step - 3) Show GTM parameter */}
                               { (google_loaderData["is_show_upload_from_code"] || google_loaderData["is_show_upload_from_gtm"]) &&
                                   <>
                                       <Accordion.Item eventKey="2">
                                           <Accordion.Header>
                                               <img src={number_2} alt='2' width={"15px"} height={"15px"} /> 
                                               <b style={{marginLeft:"10px"}}>Copy the code to Shopify Store</b> 
                                               { google_Step3Indicator && <img alt='connected' style={{padding:"6px", marginRight:"10px"}} src={connected_icon} width={"30px"}/> }
                                           </Accordion.Header>
                                           <Accordion.Body>
                                           { google_loaderData["is_show_upload_from_code"] &&
                                               <>
                                               <Card className='my-3'>
                                                   <Card.Body>
                                                       <p>{google_selected_account_details["global_site_tag"]}</p>
                                                   </Card.Body>
                                                   <Card.Footer>
                                                       <span className='gtm_conv_label'>1. Go to the "Online Store" section in "Sales channels".</span>
                                                       <span className='gtm_conv_label'>2. Click on "•••" button just near "Customize" button.</span>
                                                       <span className='gtm_conv_label'>3. After that, Click the "Edit code" button.</span>
                                                       <span className='gtm_conv_label'>4. Finally, open the "theme.liquid" file </span>
                                                       <span className='gtm_conv_label'>5. Paste the above code just above the {"</head>"} section</span>
                                                   </Card.Footer>
                                               </Card>
                                                    
                                               <Card className='my-3'>
                                                   <Card.Body>
                                                       { ((google_selected_account_details["profit_event_snippet"] !== "") || (google_selected_account_details["netRevenue_event_snippet"] !== "") || (google_selected_account_details["aov_event_snippet"] !== "")) && 
                                                           <p>
                                                           {`<script>`}<br />
                                                           &nbsp;&nbsp;{`function(){`}<br />
                                                           &nbsp;&nbsp;&nbsp;&nbsp;{`var _uid_;`}<br />
                                                           &nbsp;&nbsp;&nbsp;&nbsp;{`var formated_id = ""+_uid_;`}<br />
                                                           &nbsp;&nbsp;&nbsp;&nbsp;{`var encodedString = btoa(formated_id);`}<br />
                                                           &nbsp;&nbsp;&nbsp;&nbsp;{`window.localStorage.setItem("_uid_",_uid_);`}<br />
                                                           &nbsp;&nbsp;{`}`}<br />
                                                           {`<script/>`}
                                                           </p>
                                                       }
                                                       <p>{google_selected_account_details["global_site_tag"]}</p>
                                                       <p>{google_selected_account_details["profit_event_snippet"]}</p>
                                                       <p>{google_selected_account_details["netRevenue_event_snippet"]}</p>
                                                       <p>{google_selected_account_details["aov_event_snippet"]}</p>
                                                       <p>{google_selected_account_details["new_customer_event_snippet"]}</p>
                                                   </Card.Body>
                                                   <Card.Footer>
                                                       <span>
                                                           1. Copy the above code and go to <b style={{ cursor:"pointer", textDecoration:"underline", color:"blue" }} onClick={() => {
                                                           if((google_Step1Indicator) && (google_Step2Indicator)){
                                                           setGoogleStep3Indicator(!google_Step3Indicator)
                                                           }
                                                           window.open(`https://admin.shopify.com/store/${shop_domain.replace(".myshopify.com","")}/settings/checkout`);
                                                       }}>Settings{">"}Checkout{">"}Additional Script</b>
                                                       </span>
                                                       <span className='gtm_conv_label'>2. Paste the code in Additional Script section</span>
                                                       <span className='gtm_conv_label'>3. Save the code</span>
                                                   </Card.Footer>
                                               </Card>

                                               </>
                                           }


                                           { google_loaderData["is_show_upload_from_gtm"] &&
                                               <Card className='my-3'>
                                                   <Card.Body>
                                                       { google_selected_account_details["profit_conv_label"] !== "" &&
                                                           <span className='gtm_conv_label'>Profit - {google_selected_account_details["profit_conv_label"]}</span>
                                                       }
                                                       { google_selected_account_details["netRevenue_conv_label"] !== "" &&
                                                           <span className='gtm_conv_label'>Net Revenue - {google_selected_account_details["netRevenue_conv_label"]}</span>
                                                       }
                                                       { google_selected_account_details["aov_conv_label"] !== "" &&
                                                           <span className='gtm_conv_label'>Higher AOV - {google_selected_account_details["aov_conv_label"]}</span>
                                                       }
                                                       { google_selected_account_details["new_customer_conv_label"] !== "" &&
                                                           <span className='gtm_conv_label'>New Customer - {google_selected_account_details["new_customer_conv_label"]}</span>
                                                       }
                                                   </Card.Body>
                                                   <Card.Footer>Set up GTM with the above labels</Card.Footer>
                                               </Card>
                                           }
                                           </Accordion.Body>
                                       </Accordion.Item>
                                   </>
                               }

                               {/* Step - 4 */}
                               <Accordion.Item eventKey="3">
                                   <Accordion.Header>
                                       { ((google_loaderData["is_show_upload_from_code"] === false) && (google_loaderData["is_show_upload_from_gtm"] === false)) ?
                                           <img src={number_2} alt='2' width={"15px"} height={"15px"} /> :
                                           <img src={number_3} alt='3' width={"15px"} height={"15px"} />
                                       } 
                                       <b style={{marginLeft:"10px"}}>You are all set!</b>
                                   </Accordion.Header>
                                   <Accordion.Body>
                                   </Accordion.Body>
                               </Accordion.Item>
                           </Accordion>
                           </div>

                            <div className='main-title mt-4'>
                                <h2>Facebook Ads Integration</h2>
                            </div>
                            <div className='main-accordion-body p-2 mt-4'>
                            <Accordion defaultActiveKey={ facebook_Step2Indicator ? facebook_Step3Indicator ? "3" : "2" : "1" }>

                                {/* Step - 2 (Setup conversion action(s) ) */}
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>
                                        <img src={number_1} alt='1' width={"15px"} height={"15px"} /> 
                                        <b style={{marginLeft:"10px"}}>Set up Conversion Action(s)</b> 
                                        { facebook_Step2Indicator && <img alt='connected' style={{padding:"6px", marginRight:"10px"}} src={connected_icon} width={"30px"}/> }
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <Row xs={1} sm={2}>
                                            <Col className='main_conv_col'>
                                                <FloatingLabel controlId='floatingSelect' label='Select Ads Account'>
                                                    <FormSelect value={facebook_selected_ads_account} id='ads_account_select' onChange={handleFacebookChangeAdsAccount}>
                                                        { facebook_ads_account_select_dict.map((ads_acc_iter) => <option key={ads_acc_iter["value"]} value={ads_acc_iter["value"]}>{ads_acc_iter["label"]}</option>)}
                                                    </FormSelect>
                                                </FloatingLabel>
                                            </Col>
                                            <Col className='main_conv_col'>
                                                <FloatingLabel controlId='floatingSelect' label='Select Ads Account Pixel'>
                                                    <FormSelect value={facebook_selected_ads_account_pixel} id='ads_account_pixel_select' onChange={handleFacebookChangeAdsAccountPixel}>
                                                        { ((facebook_selected_ads_account !== undefined) && (facebook_selected_ads_account !== null) && (Object.keys(facebook_ads_acc_pixel_json).includes(facebook_selected_ads_account))) ? facebook_ads_acc_pixel_json[facebook_selected_ads_account].map((pixel_iter) => <option key={pixel_iter["value"]} value={pixel_iter["value"]}>{pixel_iter["label"]}</option>) : ""}
                                                    </FormSelect>
                                                </FloatingLabel>
                                            </Col>
                                        </Row>


                                        <Row xs={1} sm={3}>
                                            <Col className='main_conv_col'>
                                                <Card className='main_conv_card'>
                                                    <Card.Body>
                                                        <div className='main-conv-card-body-left'>
                                                            <h5 className='main-conv-card-heading' >Profit</h5>
                                                            <p className='main-conv-card-p'>Total Sales after deducting Cost of Items</p>
                                                            { ((facebook_selected_account_details?.profit_event_name !== undefined) && (facebook_selected_account_details?.profit_event_name !== "")) && <p className='main-conv-card-p'><b>Conversion Name (on Facebook Ads)</b> : {facebook_selected_account_details["profit_event_name"]}</p>}
                                                            { facebook_selected_account_details?.is_show_profit_sync_off_btn ?
                                                                <Button className="enrich_bid_button" variant='secondary' onClick={() => {
                                                                    handleFacebookSyncStatusChange("is_show_profit_sync_off_btn","profit_event_name","profit","off")
                                                                }}>Sync is On</Button>
                                                                :
                                                                <Button className="enrich_bid_button" variant='success' onClick={() => {
                                                                    handleFacebookSyncStatusChange("is_show_profit_sync_off_btn","profit_event_name","profit","on")
                                                                }}>Sync is Off</Button>
                                                            }
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                            <Col className='main_conv_col'>
                                                <Card className='main_conv_card'>
                                                    <Card.Body>
                                                        <div className='main-conv-card-body-left'>
                                                            <h5 className='main-conv-card-heading' >Net Revenue</h5>
                                                            <p className='main-conv-card-p'>Total Sales after returns</p>
                                                            { ((facebook_selected_account_details?.netRevenue_event_name !== undefined) && (facebook_selected_account_details?.netRevenue_event_name !== "")) && <p className='main-conv-card-p'><b>Conversion Name (on Facebook Ads)</b> : {facebook_selected_account_details["netRevenue_event_name"]}</p>}
                                                            
                                                            { facebook_selected_account_details?.is_show_netRevenue_sync_off_btn ?
                                                                <Button className="enrich_bid_button" variant='secondary' onClick={() => {
                                                                    handleFacebookSyncStatusChange("is_show_netRevenue_sync_off_btn","netRevenue_event_name","net_revenue","off")
                                                                }}>Sync is On</Button>
                                                                :
                                                                <Button className="enrich_bid_button" variant='success' onClick={() => {
                                                                    handleFacebookSyncStatusChange("is_show_netRevenue_sync_off_btn","netRevenue_event_name","net_revenue","on")
                                                                }}>Sync is Off</Button>
                                                            }
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                            <Col className='main_conv_col'>
                                                <Card className='main_conv_card'>
                                                    <Card.Body>
                                                        <div className='main-conv-card-body-left'>
                                                            <h5 className='main-conv-card-heading' >Higher AOV</h5>
                                                            <p className='main-conv-card-p'>Orders with revenue greater than {facebook_selected_account_details["aov_value_limit"]}</p>
                                                            { ((facebook_selected_account_details?.aov_event_name !== undefined) && (facebook_selected_account_details?.aov_event_name !== "")) && <p className='main-conv-card-p'><b>Conversion Name (on Facebook Ads)</b> : {facebook_selected_account_details["aov_event_name"]}</p>}
                                                            
                                                            <Button className="enrich_bid_button" variant='primary' onClick={ () => {
                                                                var temp_setup_setting = { ...facebook_setup_setting }
                                                                temp_setup_setting["conv_display_name"] = "Higher AOV"
                                                                temp_setup_setting["db_id"] = "higher_aov"
                                                                temp_setup_setting["id"] = "aov"
                                                                temp_setup_setting["name"] = facebook_selected_account_details["aov_event_name"]
                                                                temp_setup_setting["detail"] = `Orders with revenue greater than ${facebook_selected_account_details["aov_value_limit"]}`
                                                                temp_setup_setting["formula"] = `Revenue > ${facebook_selected_account_details["aov_value_limit"]}`
                                                                setFacebookSetupSetting(temp_setup_setting);
                                                                handleFacebookEditShow();
                                                            }}>Edit</Button>
                                                            { " " }
                                                            { facebook_selected_account_details?.is_show_aov_sync_off_btn ?
                                                                <Button className="enrich_bid_button" variant='secondary' onClick={() => {
                                                                    handleFacebookSyncStatusChange("is_show_aov_sync_off_btn","aov_event_name","higher_aov","off")
                                                                    }}>Sync is On</Button>
                                                                :
                                                                <Button className="enrich_bid_button" variant='success' onClick={() => {
                                                                    handleFacebookSyncStatusChange("is_show_aov_sync_off_btn","aov_event_name","higher_aov","on")
                                                                }}>Sync is Off</Button>
                                                            }
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                            <Col className='main_conv_col'>
                                                <Card className='main_conv_card'>
                                                    <Card.Body>
                                                        <div className='main-conv-card-body-left'>
                                                            <h5 className='main-conv-card-heading' >New Customer</h5>
                                                            <p className='main-conv-card-p'>First-time online shopper or buyer</p>
                                                            { ((facebook_selected_account_details?.new_customer_event_name !== undefined) && (facebook_selected_account_details?.new_customer_event_name !== "")) && <p className='main-conv-card-p'><b>Conversion Name (on Facebook Ads)</b> : {facebook_selected_account_details["new_customer_event_name"]}</p>}
                                                            { facebook_selected_account_details?.is_show_new_customer_sync_off_btn ?
                                                                <Button className="enrich_bid_button" variant='secondary' onClick={() => {
                                                                    handleFacebookSyncStatusChange("is_show_new_customer_sync_off_btn","new_customer_event_name","new_customer","off")
                                                                }}>Sync is On</Button>
                                                                :
                                                                <Button className="enrich_bid_button" variant='success' onClick={() => {
                                                                    handleFacebookSyncStatusChange("is_show_new_customer_sync_off_btn","new_customer_event_name","new_customer","on")
                                                                }}>Sync is Off</Button>
                                                            }
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                            { shop_domain.includes("quit-with-jones") &&
                                                <Col className='main_conv_col'>
                                                    <Card className='main_conv_card'>
                                                        <Card.Body>
                                                            <div className='main-conv-card-body-left'>
                                                                <h5 className='main-conv-card-heading' >Subscription</h5>
                                                                <p className='main-conv-card-p'>Orders that have the tag 'Subscription'</p>
                                                                <Button className="enrich_bid_button" variant='success'>Sync is On</Button>
                                                            </div>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                           }
                                        </Row>

                                        <div key={`inline-radio`} className="mt-5">
                                            <Form.Label><b>Do you recognize revenue with or without tax on Facebook ads ?</b></Form.Label>
                                            <br />
                                            <Form.Check
                                                inline
                                                checked={!facebook_send_revenue_with_tax}
                                                label="Without Tax"
                                                name="group1"
                                                type={'radio'}
                                                id={`inline-radio-1`}
                                                onChange={()=>{
                                                    setFacebookSendRevenueWithTax(false);
                                                    axios.post(app_url+"/api/facebook/send_revenue_with_tax",{
                                                        "shop_domain":shop_domain.replace(".myshopify.com",""),
                                                        "status":false
                                                    }, {withCredentials:true})
                                                }}
                                                />
                                            <Form.Check
                                                inline
                                                checked={facebook_send_revenue_with_tax}
                                                label="With Tax"
                                                name="group1"
                                                type={'radio'}
                                                id={`inline-radio-2`}
                                                onChange={()=>{
                                                    setFacebookSendRevenueWithTax(true);
                                                    axios.post(app_url+"/api/facebook/send_revenue_with_tax",{
                                                        "shop_domain":shop_domain.replace(".myshopify.com",""),
                                                        "status":true
                                                    }, {withCredentials:true})
                                                }}
                                                />
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                {/* Step - 3 (You are all set ) */}
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>
                                        <img src={number_2} alt='2' width={"15px"} height={"15px"} /> 
                                        <b style={{marginLeft:"10px"}}>You are all set!</b> 
                                        { facebook_Step3Indicator && <img alt='connected' style={{padding:"6px", marginRight:"10px"}} src={connected_icon} width={"30px"}/> }
                                    </Accordion.Header>
                                    <Accordion.Body>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            </div>
                        </>
                        :
                        <div className='main_conatiner_spinner'>
                            <Spinner animation="border"/>
                        </div>
                    }
                </>
            }
        </div>
    )
}

export default EnrichAndBid