import React from 'react'
import scalex_ai_logo from "../images/scalex_ai_logo.png"
import google_logo from "../images/google.png"
import meta_logo from "../images/meta.png"
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form"
import axios from 'axios';
import toast from 'react-hot-toast';

function Sidebar({app_url, openSidebarToggle, OpenSidebar, selectedPage, stores, changeStoreDomain, changeStoreName, shop_domain, ShopName, show_under_dev_pages}) {

  return (
    <aside id="sidebar" className={openSidebarToggle ? "sidebar-responsive": ""}>
        <div className='sidebar-title' id='sidebar-title-responsive'>
            <div className='sidebar-brand'>
                <img src={scalex_ai_logo} alt='logo' width={40} className='icon_header'/> ScaleX AI
            </div>
        </div>
        <div className='sidebar-title' id='top-bottom-border'>
            <div className='sidebar-customer'>
                {/* {ShopName} */}
                <Form.Select onChange={(e)=>{
                    e.preventDefault()
                    axios.post(app_url+"/change_current_shop",{
                        new_shop_domain:e.target.value,
                    }, {withCredentials:true})
                    .then(res => res.data)
                    .then(data => {
                        changeStoreDomain(e.target.value) 
                        console.log("In sidebar",e.target.value)
                    })
                    .catch(err => {
                        toast.error("Please try after some time.");
                        console.log(err);
                    })
                }}>
                    {stores.map((store_iter)=>{
                        let temp_shop_name = store_iter.replace(".myshopify.com","");
                        if(temp_shop_name === shop_domain.replace(".myshopify.com","")){
                            return (<option selected value={store_iter} key={store_iter}>{temp_shop_name[0].toUpperCase() + temp_shop_name.slice(1)}</option>)
                        }
                        return (<option value={store_iter} key={store_iter}>{temp_shop_name[0].toUpperCase() + temp_shop_name.slice(1)}</option>)
                    })}
                </Form.Select>
            </div>
            <span className='icon close_icon' onClick={OpenSidebar}>X</span>
        </div>

        <ul className='sidebar-list'>

            {/* <li style={{display:`${shop_domain.replace(".myshopify.com","") === "dm-test-store-01" ? 'block': 'none'}`}} className={[9,11,12,19].includes(selectedPage) ?'sidebar-list-item show-list-item-dropdown':'sidebar-list-item'} > */}

            <li className={[10,15,16,20,14,18].includes(selectedPage) ?'sidebar-list-item show-list-item-dropdown':'sidebar-list-item'} >
                <span className='sidebar-list-hover-title' onClick={()=>{
                    let display_style = document.getElementById("sidebar-product").style.display;
                    if(display_style === "none"){
                        document.getElementById("sidebar-product-down").style.display = "none";
                        document.getElementById("sidebar-product-up").style.display = "block";
                        document.getElementById("sidebar-website-optimization-up").style.display = "none";
                        document.getElementById("sidebar-website-optimization-down").style.display = "block";
                        document.getElementById("sidebar-ad-copy-optimization-up").style.display = "none";
                        document.getElementById("sidebar-ad-copy-optimization-down").style.display = "block";
                        document.getElementById("sidebar-customer-behavior-up").style.display = "none";
                        document.getElementById("sidebar-customer-behavior-down").style.display = "block";
                        document.getElementById("sidebar-integration-up").style.display = "none";
                        document.getElementById("sidebar-integration-down").style.display = "block";
                        document.getElementById("sidebar-performance-up").style.display = "none";
                        document.getElementById("sidebar-performance-down").style.display = "block";
                    }
                    else{
                        document.getElementById("sidebar-product-down").style.display = "block";
                        document.getElementById("sidebar-product-up").style.display = "none";
                        document.getElementById("sidebar-website-optimization-up").style.display = "none";
                        document.getElementById("sidebar-website-optimization-down").style.display = "block";
                        document.getElementById("sidebar-ad-copy-optimization-up").style.display = "none";
                        document.getElementById("sidebar-ad-copy-optimization-down").style.display = "block";
                        document.getElementById("sidebar-customer-behavior-up").style.display = "none";
                        document.getElementById("sidebar-customer-behavior-down").style.display = "block";
                        document.getElementById("sidebar-integration-up").style.display = "none";
                        document.getElementById("sidebar-integration-down").style.display = "block";
                        document.getElementById("sidebar-performance-up").style.display = "none";
                        document.getElementById("sidebar-performance-down").style.display = "block";
                    }
                    
                    document.getElementById("sidebar-performance").style.display = "none";
                    document.getElementById("sidebar-integration").style.display = "none";
                    document.getElementById("sidebar-website-optimization").style.display = "none";
                    document.getElementById("sidebar-ad-copy-optimization").style.display = "none";
                    document.getElementById("sidebar-customer-behavior").style.display = "none";
                    document.getElementById("sidebar-product").style.display = display_style === "block" ? "none": "block";
                }}>
                    <p style={{margin:"0"}}>Communication AI</p>
                    <svg id='sidebar-product-up' style={{display:`${[10,15,16,20,14,18].includes(selectedPage) ? "block" : "none"}`}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-up" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708z"/>
                    </svg>
                    <svg id='sidebar-product-down' style={{display:`${[10,15,16,20,14,18].includes(selectedPage) ? "none" : "block"}`}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"/>
                    </svg>
                </span>
                <ul className='sidebar-list-dropdown' id='sidebar-product'>
                    <li className={selectedPage === 20 ?'sidebar-list-item sidebar-page-active':'sidebar-list-item'}>
                        <Link to="/chatbot">Contextual Ideation</Link>
                    </li>
                    <li className={selectedPage === 10 ?'sidebar-list-item sidebar-page-active':'sidebar-list-item'}>
                        <img src={google_logo} alt='Meta' width={15} height={15} style={{marginRight:"5px"}} /><Link to="/ads-copy-optimization">Ad Copy Optimizations</Link>
                    </li>
                    <li  style={{display:`${shop_domain.replace(".myshopify.com","") === "dm-test-store-01" ? 'block': 'none'}`}} className={selectedPage === 15 ?'sidebar-list-item sidebar-page-active':'sidebar-list-item'}>
                        <img src={google_logo} alt='Meta' width={15} height={15} style={{marginRight:"5px"}} /><Link to="/google-ads-copy-ss">Ad Copies Generation (New Adgroups)</Link>
                    </li>
                    <li style={{display:`${shop_domain.replace(".myshopify.com","") === "dm-test-store-01" ? 'block': 'none'}`}} className={selectedPage === 16 ?'sidebar-list-item sidebar-page-active':'sidebar-list-item'}>
                        <img src={meta_logo} alt='Meta' width={15} height={15} style={{marginRight:"5px"}} /><Link to="/creative-analysis">Analytics</Link>
                    </li>
                    <li className={selectedPage === 14 ?'sidebar-list-item sidebar-page-active':'sidebar-list-item'}>
                        <img src={meta_logo} alt='Meta' width={15} height={15} style={{marginRight:"5px"}} /><Link to="/creative_ideation">Creative Ideation</Link>
                    </li>
                    <li className={selectedPage === 18 ?'sidebar-list-item sidebar-page-active':'sidebar-list-item'}>
                        <img src={meta_logo} alt='Meta' width={15} height={15} style={{marginRight:"5px"}} /><Link to="/reports">Reports</Link>
                    </li>
                </ul>
            </li>

            <li className={[9,11,12,19].includes(selectedPage) ?'sidebar-list-item show-list-item-dropdown':'sidebar-list-item'} >
                <span className='sidebar-list-hover-title' onClick={()=>{
                    let display_style = document.getElementById("sidebar-website-optimization").style.display;
                    if(display_style === "none"){
                        document.getElementById("sidebar-website-optimization-down").style.display = "none";
                        document.getElementById("sidebar-website-optimization-up").style.display = "block";
                        document.getElementById("sidebar-ad-copy-optimization-up").style.display = "none";
                        document.getElementById("sidebar-ad-copy-optimization-down").style.display = "block";
                        document.getElementById("sidebar-product-up").style.display = "none";
                        document.getElementById("sidebar-product-down").style.display = "block";
                        document.getElementById("sidebar-customer-behavior-up").style.display = "none";
                        document.getElementById("sidebar-customer-behavior-down").style.display = "block";
                        document.getElementById("sidebar-integration-up").style.display = "none";
                        document.getElementById("sidebar-integration-down").style.display = "block";
                        document.getElementById("sidebar-performance-up").style.display = "none";
                        document.getElementById("sidebar-performance-down").style.display = "block";
                    }
                    else{
                        document.getElementById("sidebar-website-optimization-down").style.display = "block";
                        document.getElementById("sidebar-website-optimization-up").style.display = "none";
                        document.getElementById("sidebar-ad-copy-optimization-up").style.display = "none";
                        document.getElementById("sidebar-ad-copy-optimization-down").style.display = "block";
                        document.getElementById("sidebar-product-up").style.display = "none";
                        document.getElementById("sidebar-product-down").style.display = "block";
                        document.getElementById("sidebar-customer-behavior-up").style.display = "none";
                        document.getElementById("sidebar-customer-behavior-down").style.display = "block";
                        document.getElementById("sidebar-integration-up").style.display = "none";
                        document.getElementById("sidebar-integration-down").style.display = "block";
                        document.getElementById("sidebar-performance-up").style.display = "none";
                        document.getElementById("sidebar-performance-down").style.display = "block";
                    }
                    
                    document.getElementById("sidebar-performance").style.display = "none";
                    document.getElementById("sidebar-integration").style.display = "none";
                    document.getElementById("sidebar-customer-behavior").style.display = "none";
                    document.getElementById("sidebar-product").style.display = "none";
                    document.getElementById("sidebar-ad-copy-optimization").style.display = "none";
                    document.getElementById("sidebar-website-optimization").style.display = display_style === "block" ? "none": "block";
                }}>
                    <p style={{margin:"0"}}>Experience AI</p>
                    <svg id='sidebar-website-optimization-up' style={{display:`${[9,11,12,19].includes(selectedPage) ? "block" : "none"}`}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-up" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708z"/>
                    </svg>
                    <svg id='sidebar-website-optimization-down' style={{display:`${[9,11,12,19].includes(selectedPage) ? "none" : "block"}`}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"/>
                    </svg>
                </span>
                <ul className='sidebar-list-dropdown' id='sidebar-website-optimization'>
                    <li style={{display:`${shop_domain.replace(".myshopify.com","") === "dm-test-store-01" ? 'block': 'none'}`}} className={selectedPage === 12 ?'sidebar-list-item sidebar-page-active':'sidebar-list-item'}>
                        <Link to="/all-product-audit">All Product Audit</Link>
                    </li>
                    <li className={selectedPage === 19 ?'sidebar-list-item sidebar-page-active':'sidebar-list-item'}>
                        <img src={google_logo} alt='Meta' width={15} height={15} style={{marginRight:"5px"}} /><Link to="/landing-page-optimization">Keyword Intent Optimization</Link>
                    </li>
                    <li style={{display:`${shop_domain.replace(".myshopify.com","") === "dm-test-store-01" ? 'block': 'none'}`}} className={selectedPage === 9 ?'sidebar-list-item sidebar-page-active':'sidebar-list-item'}>
                        <Link to="/product-cro">Product Optimization</Link>
                    </li>
                    <li style={{display:`${shop_domain.replace(".myshopify.com","") === "dm-test-store-01" ? 'block': 'none'}`}} className={selectedPage === 11 ?'sidebar-list-item sidebar-page-active':'sidebar-list-item'}>
                        <Link to="/segment-bestseller">Segment Best Sellers</Link>
                    </li>
                </ul>
            </li>

            <li style={{display:'none'}} className={[].includes(selectedPage) ?'sidebar-list-item show-list-item-dropdown':'sidebar-list-item'} >
                <span className='sidebar-list-hover-title' onClick={()=>{
                    let display_style = document.getElementById("sidebar-ad-copy-optimization").style.display;
                    if(display_style === "none"){
                        document.getElementById("sidebar-ad-copy-optimization-down").style.display = "none";
                        document.getElementById("sidebar-ad-copy-optimization-up").style.display = "block";
                        document.getElementById("sidebar-website-optimization-up").style.display = "none";
                        document.getElementById("sidebar-website-optimization-down").style.display = "block";
                        document.getElementById("sidebar-product-up").style.display = "none";
                        document.getElementById("sidebar-product-down").style.display = "block";
                        document.getElementById("sidebar-customer-behavior-up").style.display = "none";
                        document.getElementById("sidebar-customer-behavior-down").style.display = "block";
                        document.getElementById("sidebar-integration-up").style.display = "none";
                        document.getElementById("sidebar-integration-down").style.display = "block";
                        document.getElementById("sidebar-performance-up").style.display = "none";
                        document.getElementById("sidebar-performance-down").style.display = "block";
                    }
                    else{
                        document.getElementById("sidebar-ad-copy-optimization-down").style.display = "block";
                        document.getElementById("sidebar-ad-copy-optimization-up").style.display = "none";
                        document.getElementById("sidebar-website-optimization-up").style.display = "none";
                        document.getElementById("sidebar-website-optimization-down").style.display = "block";
                        document.getElementById("sidebar-product-up").style.display = "none";
                        document.getElementById("sidebar-product-down").style.display = "block";
                        document.getElementById("sidebar-customer-behavior-up").style.display = "none";
                        document.getElementById("sidebar-customer-behavior-down").style.display = "block";
                        document.getElementById("sidebar-integration-up").style.display = "none";
                        document.getElementById("sidebar-integration-down").style.display = "block";
                        document.getElementById("sidebar-performance-up").style.display = "none";
                        document.getElementById("sidebar-performance-down").style.display = "block";
                    }
                    
                    document.getElementById("sidebar-performance").style.display = "none";
                    document.getElementById("sidebar-integration").style.display = "none";
                    document.getElementById("sidebar-customer-behavior").style.display = "none";
                    document.getElementById("sidebar-product").style.display = "none";
                    document.getElementById("sidebar-website-optimization").style.display = "none";
                    document.getElementById("sidebar-ad-copy-optimization").style.display = display_style === "block" ? "none": "block";
                }}>
                    <p style={{margin:"0"}}>Meta Ads</p>
                    <svg id='sidebar-ad-copy-optimization-up' style={{display:`${[14,16,18,19,20].includes(selectedPage) ? "block" : "none"}`}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-up" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708z"/>
                    </svg>
                    <svg id='sidebar-ad-copy-optimization-down' style={{display:`${[14,16,18,19,20].includes(selectedPage) ? "none" : "block"}`}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"/>
                    </svg>
                </span>
                <ul className='sidebar-list-dropdown' id='sidebar-ad-copy-optimization'>

                </ul>
            </li>


            <li className={[5,2,7,13,0,1,3,6,8].includes(selectedPage) ?'sidebar-list-item show-list-item-dropdown':'sidebar-list-item'}>
                <span className='sidebar-list-hover-title' onClick={()=>{
                    let display_style = document.getElementById("sidebar-customer-behavior").style.display;
                    if(display_style === "none"){
                        document.getElementById("sidebar-customer-behavior-down").style.display = "none";
                        document.getElementById("sidebar-customer-behavior-up").style.display = "block";
                        document.getElementById("sidebar-website-optimization-up").style.display = "none";
                        document.getElementById("sidebar-website-optimization-down").style.display = "block";
                        document.getElementById("sidebar-ad-copy-optimization-up").style.display = "none";
                        document.getElementById("sidebar-ad-copy-optimization-down").style.display = "block";
                        document.getElementById("sidebar-product-up").style.display = "none";
                        document.getElementById("sidebar-product-down").style.display = "block";
                        document.getElementById("sidebar-integration-up").style.display = "none";
                        document.getElementById("sidebar-integration-down").style.display = "block";
                        document.getElementById("sidebar-performance-up").style.display = "none";
                        document.getElementById("sidebar-performance-down").style.display = "block";
                    }
                    else{
                        document.getElementById("sidebar-customer-behavior-down").style.display = "block";
                        document.getElementById("sidebar-customer-behavior-up").style.display = "none";
                        document.getElementById("sidebar-website-optimization-up").style.display = "none";
                        document.getElementById("sidebar-website-optimization-down").style.display = "block";
                        document.getElementById("sidebar-ad-copy-optimization-up").style.display = "none";
                        document.getElementById("sidebar-ad-copy-optimization-down").style.display = "block";
                        document.getElementById("sidebar-product-up").style.display = "none";
                        document.getElementById("sidebar-product-down").style.display = "block";
                        document.getElementById("sidebar-integration-up").style.display = "none";
                        document.getElementById("sidebar-integration-down").style.display = "block";
                        document.getElementById("sidebar-performance-up").style.display = "none";
                        document.getElementById("sidebar-performance-down").style.display = "block";
                    }
                    
                    document.getElementById("sidebar-performance").style.display = "none";
                    document.getElementById("sidebar-integration").style.display = "none";
                    document.getElementById("sidebar-product").style.display = "none";
                    document.getElementById("sidebar-ad-copy-optimization").style.display = "none";
                    document.getElementById("sidebar-website-optimization").style.display = "none";
                    document.getElementById("sidebar-customer-behavior").style.display = display_style === "block" ? "none": "block";
                }}>
                    <p style={{margin:"0"}}>LTV AI</p>
                    <svg id='sidebar-customer-behavior-up' style={{display:`${[5,2,7,13,0,1,3,6,8].includes(selectedPage) ? "block" : "none"}`}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-up" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708z"/>
                    </svg>
                    <svg id='sidebar-customer-behavior-down' style={{display:`${[5,2,7,13,0,1,3,6,8].includes(selectedPage) ? "none" : "block"}`}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"/>
                    </svg>
                </span>
                <ul className='sidebar-list-dropdown' id='sidebar-customer-behavior'>
                    <li className={selectedPage === 2 ?'sidebar-list-item sidebar-page-active':'sidebar-list-item'}>
                        <Link to="/lifetime-value">Lifetime Value</Link>
                    </li>
                    <li className={selectedPage === 5 ?'sidebar-list-item sidebar-page-active':'sidebar-list-item'}>
                        <Link to="/enrich-and-bid">Enrich & Bid</Link>
                    </li>
                    <li style={{display:`${shop_domain.replace(".myshopify.com","") === "dm-test-store-01" ? 'block': 'none'}`}} className={selectedPage === 7 ?'sidebar-list-item sidebar-page-active':'sidebar-list-item'}>
                        <img src={google_logo} alt='Meta' width={15} height={15} style={{marginRight:"5px"}} /><Link to="/feed-enrichment">Feed Enrichment</Link>
                    </li>
                    <li style={{display:`${shop_domain.replace(".myshopify.com","") === "dm-test-store-01" ? 'block': 'none'}`}} className={selectedPage === 13 ?'sidebar-list-item sidebar-page-active':'sidebar-list-item'}>
                        <img src={google_logo} alt='Meta' width={15} height={15} style={{marginRight:"5px"}} /><Link to="/shopping">Feed Optimization</Link>
                    </li>
                    <li style={{display:`${shop_domain.replace(".myshopify.com","") === "dm-test-store-01" ? 'block': 'none'}`}} className={selectedPage === 0 ?'sidebar-list-item sidebar-page-active':'sidebar-list-item'}>
                        <Link to="/metrics-performance">Metrics Trend</Link>
                    </li>
                    <li style={{display:`${shop_domain.replace(".myshopify.com","") === "dm-test-store-01" ? 'block': 'none'}`}} className={selectedPage === 1 ?'sidebar-list-item sidebar-page-active':'sidebar-list-item'}>
                        <Link to="/metrics-deepdive">Metrics Deepdive</Link>
                    </li>
                    <li style={{display:`${shop_domain.replace(".myshopify.com","") === "dm-test-store-01" ? 'block': 'none'}`}} className={selectedPage === 3 ?'sidebar-list-item sidebar-page-active':'sidebar-list-item'}>
                        <Link to="/product-journey">Product Journey</Link>
                    </li>
                    <li style={{display:`${shop_domain.replace(".myshopify.com","") === "dm-test-store-01" ? 'block': 'none'}`}} className={selectedPage === 6 ?'sidebar-list-item sidebar-page-active':'sidebar-list-item'}>
                        <Link to="/product-performance">Performance By Channels</Link>
                    </li>
                    <li className={selectedPage === 8 ?'sidebar-list-item sidebar-page-active':'sidebar-list-item'}>
                        <Link to="/repurchase-rate">Repurchase Rate</Link>
                    </li>
                </ul>
            </li>

            <li style={{display:"none"}} className={[0,1,5].includes(selectedPage) ?'sidebar-list-item show-list-item-dropdown':'sidebar-list-item'}>
                <span className='sidebar-list-hover-title' onClick={()=>{
                    let display_style = document.getElementById("sidebar-performance").style.display;
                    if(display_style === "none"){
                        document.getElementById("sidebar-performance-down").style.display = "none";
                        document.getElementById("sidebar-performance-up").style.display = "block";
                        document.getElementById("sidebar-website-optimization-up").style.display = "none";
                        document.getElementById("sidebar-website-optimization-down").style.display = "block";
                        document.getElementById("sidebar-ad-copy-optimization-up").style.display = "none";
                        document.getElementById("sidebar-ad-copy-optimization-down").style.display = "block";
                        document.getElementById("sidebar-product-up").style.display = "none";
                        document.getElementById("sidebar-product-down").style.display = "block";
                        document.getElementById("sidebar-integration-up").style.display = "none";
                        document.getElementById("sidebar-integration-down").style.display = "block";
                        document.getElementById("sidebar-customer-behavior-up").style.display = "none";
                        document.getElementById("sidebar-customer-behavior-down").style.display = "block";
                    }
                    else{
                        document.getElementById("sidebar-performance-down").style.display = "block";
                        document.getElementById("sidebar-performance-up").style.display = "none";
                        document.getElementById("sidebar-website-optimization-up").style.display = "none";
                        document.getElementById("sidebar-website-optimization-down").style.display = "block";
                        document.getElementById("sidebar-ad-copy-optimization-up").style.display = "none";
                        document.getElementById("sidebar-ad-copy-optimization-down").style.display = "block";
                        document.getElementById("sidebar-product-up").style.display = "none";
                        document.getElementById("sidebar-product-down").style.display = "block";
                        document.getElementById("sidebar-integration-up").style.display = "none";
                        document.getElementById("sidebar-integration-down").style.display = "block";
                        document.getElementById("sidebar-customer-behavior-up").style.display = "none";
                        document.getElementById("sidebar-customer-behavior-down").style.display = "block";
                    }
                    
                    document.getElementById("sidebar-customer-behavior").style.display = "none";
                    document.getElementById("sidebar-integration").style.display = "none";
                    document.getElementById("sidebar-website-optimization").style.display = "none";
                    document.getElementById("sidebar-ad-copy-optimization").style.display = "none";
                    document.getElementById("sidebar-product").style.display = "none";
                    document.getElementById("sidebar-performance").style.display = display_style === "block" ? "none": "block";
                }}>
                    <p style={{margin:"0"}}>Advanced Channel Performance</p>
                    <svg id='sidebar-performance-up' style={{display:`${[0,1,5].includes(selectedPage) ? "block" : "none"}`}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-up" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708z"/>
                    </svg>
                    <svg id='sidebar-performance-down' style={{display:`${[0,1,5].includes(selectedPage) ? "none" : "block"}`}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"/>
                    </svg>
                </span>
                <ul className='sidebar-list-dropdown' id='sidebar-performance'>

                </ul>
            </li>

            <li className={[4,17,21].includes(selectedPage) ?'sidebar-list-item show-list-item-dropdown':'sidebar-list-item'}>
                <span className='sidebar-list-hover-title' onClick={()=>{
                    let display_style = document.getElementById("sidebar-integration").style.display;
                    if(display_style === "none"){
                        document.getElementById("sidebar-integration-down").style.display = "none";
                        document.getElementById("sidebar-integration-up").style.display = "block";
                        document.getElementById("sidebar-website-optimization-up").style.display = "none";
                        document.getElementById("sidebar-website-optimization-down").style.display = "block";
                        document.getElementById("sidebar-ad-copy-optimization-up").style.display = "none";
                        document.getElementById("sidebar-ad-copy-optimization-down").style.display = "block";
                        document.getElementById("sidebar-product-up").style.display = "none";
                        document.getElementById("sidebar-product-down").style.display = "block";
                        document.getElementById("sidebar-performance-up").style.display = "none";
                        document.getElementById("sidebar-performance-down").style.display = "block";
                        document.getElementById("sidebar-customer-behavior-up").style.display = "none";
                        document.getElementById("sidebar-customer-behavior-down").style.display = "block";
                    }
                    else{
                        document.getElementById("sidebar-integration-down").style.display = "block";
                        document.getElementById("sidebar-integration-up").style.display = "none";
                        document.getElementById("sidebar-website-optimization-up").style.display = "none";
                        document.getElementById("sidebar-website-optimization-down").style.display = "block";
                        document.getElementById("sidebar-ad-copy-optimization-up").style.display = "none";
                        document.getElementById("sidebar-ad-copy-optimization-down").style.display = "block";
                        document.getElementById("sidebar-product-up").style.display = "none";
                        document.getElementById("sidebar-product-down").style.display = "block";
                        document.getElementById("sidebar-performance-up").style.display = "none";
                        document.getElementById("sidebar-customer-behavior-up").style.display = "none";
                        document.getElementById("sidebar-performance-down").style.display = "block";
                        document.getElementById("sidebar-customer-behavior-down").style.display = "block";
                    }
                    document.getElementById("sidebar-performance").style.display = "none";
                    document.getElementById("sidebar-customer-behavior").style.display = "none";
                    document.getElementById("sidebar-product").style.display = "none";
                    document.getElementById("sidebar-website-optimization").style.display = "none";
                    document.getElementById("sidebar-ad-copy-optimization").style.display = "none";
                    document.getElementById("sidebar-integration").style.display = display_style === "block" ? "none": "block";
                }}>
                    <p style={{margin:"0"}}>Settings</p>
                    <svg id='sidebar-integration-up' style={{display:`${[4,17,21].includes(selectedPage) ? "block" : "none"}`}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-up" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708z"/>
                    </svg>
                    <svg id='sidebar-integration-down' style={{display:`${[4,17,21].includes(selectedPage) ? "none" : "block"}`}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"/>
                    </svg>
                </span>
                <ul className='sidebar-list-dropdown' id='sidebar-integration'>
                    <li className={selectedPage === 4 ?'sidebar-list-item sidebar-page-active':'sidebar-list-item'}>
                        <Link to="/">Google & Meta Inegration</Link>
                    </li>
                    <li className={selectedPage === 21 ?'sidebar-list-item sidebar-page-active':'sidebar-list-item'}>
                        <Link to="/groups">Groups</Link>
                    </li>
                    <li style={{display:`${shop_domain.replace(".myshopify.com","") === "dm-test-store-01" ? 'block': 'none'}`}} className={selectedPage === 17 ?'sidebar-list-item sidebar-page-active':'sidebar-list-item'}>
                        <Link to="/meta-creative-settings">Meta Creative Settings</Link>
                    </li>
                </ul>
            </li>
        </ul>
    </aside>
  )
}

export default Sidebar


// function Sidebar({app_url, openSidebarToggle, OpenSidebar, selectedPage, stores, changeStoreDomain, changeStoreName, shop_domain, ShopName, show_under_dev_pages}) {

//     return (
//       <aside id="sidebar" className={openSidebarToggle ? "sidebar-responsive": ""}>
//           <div className='sidebar-title' id='sidebar-title-responsive'>
//               <div className='sidebar-brand'>
//                   <img src={scalex_ai_logo} alt='logo' width={40} className='icon_header'/> ScaleX AI
//               </div>
//           </div>
//           <div className='sidebar-title' id='top-bottom-border'>
//               <div className='sidebar-customer'>
//                   {/* {ShopName} */}
//                   <Form.Select onChange={(e)=>{
//                       e.preventDefault()
//                       axios.post(app_url+"/change_current_shop",{
//                           new_shop_domain:e.target.value,
//                       }, {withCredentials:true})
//                       .then(res => res.data)
//                       .then(data => {
//                           changeStoreDomain(e.target.value) 
//                           console.log("In sidebar",e.target.value)
//                       })
//                       .catch(err => {
//                           toast.error("Please try after some time.");
//                           console.log(err);
//                       })
//                   }}>
//                       {stores.map((store_iter)=>{
//                           if(store_iter.replace(".myshopify.com","") === shop_domain.replace(".myshopify.com","")){
//                               return (<option selected value={store_iter} key={store_iter}>{store_iter.replace(".myshopify.com","")}</option>)
//                           }
//                           return (<option value={store_iter} key={store_iter}>{store_iter.replace(".myshopify.com","")}</option>)
//                       })}
//                   </Form.Select>
//               </div>
//               <span className='icon close_icon' onClick={OpenSidebar}>X</span>
//           </div>
  
//           <ul className='sidebar-list'>
  
//               <li style={{display:`${shop_domain.replace(".myshopify.com","") === "dm-test-store-01" ? 'block': 'none'}`}} className={[9,11,12,19].includes(selectedPage) ?'sidebar-list-item show-list-item-dropdown':'sidebar-list-item'} >
//                   <span className='sidebar-list-hover-title' onClick={()=>{
//                       let display_style = document.getElementById("sidebar-website-optimization").style.display;
//                       if(display_style === "none"){
//                           document.getElementById("sidebar-website-optimization-down").style.display = "none";
//                           document.getElementById("sidebar-website-optimization-up").style.display = "block";
//                           document.getElementById("sidebar-ad-copy-optimization-up").style.display = "none";
//                           document.getElementById("sidebar-ad-copy-optimization-down").style.display = "block";
//                           document.getElementById("sidebar-product-up").style.display = "none";
//                           document.getElementById("sidebar-product-down").style.display = "block";
//                           document.getElementById("sidebar-customer-behavior-up").style.display = "none";
//                           document.getElementById("sidebar-customer-behavior-down").style.display = "block";
//                           document.getElementById("sidebar-integration-up").style.display = "none";
//                           document.getElementById("sidebar-integration-down").style.display = "block";
//                           document.getElementById("sidebar-performance-up").style.display = "none";
//                           document.getElementById("sidebar-performance-down").style.display = "block";
//                       }
//                       else{
//                           document.getElementById("sidebar-website-optimization-down").style.display = "block";
//                           document.getElementById("sidebar-website-optimization-up").style.display = "none";
//                           document.getElementById("sidebar-ad-copy-optimization-up").style.display = "none";
//                           document.getElementById("sidebar-ad-copy-optimization-down").style.display = "block";
//                           document.getElementById("sidebar-product-up").style.display = "none";
//                           document.getElementById("sidebar-product-down").style.display = "block";
//                           document.getElementById("sidebar-customer-behavior-up").style.display = "none";
//                           document.getElementById("sidebar-customer-behavior-down").style.display = "block";
//                           document.getElementById("sidebar-integration-up").style.display = "none";
//                           document.getElementById("sidebar-integration-down").style.display = "block";
//                           document.getElementById("sidebar-performance-up").style.display = "none";
//                           document.getElementById("sidebar-performance-down").style.display = "block";
//                       }
                      
//                       document.getElementById("sidebar-performance").style.display = "none";
//                       document.getElementById("sidebar-integration").style.display = "none";
//                       document.getElementById("sidebar-customer-behavior").style.display = "none";
//                       document.getElementById("sidebar-product").style.display = "none";
//                       document.getElementById("sidebar-ad-copy-optimization").style.display = "none";
//                       document.getElementById("sidebar-website-optimization").style.display = display_style === "block" ? "none": "block";
//                   }}>
//                       <p style={{margin:"0"}}>CRO</p>
//                       <svg id='sidebar-website-optimization-up' style={{display:`${[9,11,12,19].includes(selectedPage) ? "block" : "none"}`}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-up" viewBox="0 0 16 16">
//                           <path fillRule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708z"/>
//                       </svg>
//                       <svg id='sidebar-website-optimization-down' style={{display:`${[9,11,12,19].includes(selectedPage) ? "none" : "block"}`}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
//                           <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"/>
//                       </svg>
//                   </span>
//                   <ul className='sidebar-list-dropdown' id='sidebar-website-optimization'>
//                       <li className={selectedPage === 9 ?'sidebar-list-item sidebar-page-active':'sidebar-list-item'}>
//                           <Link to="/product-cro">Product Optimization</Link>
//                       </li>
//                       <li className={selectedPage === 11 ?'sidebar-list-item sidebar-page-active':'sidebar-list-item'}>
//                           <Link to="/segment-bestseller">Segment Best Sellers</Link>
//                       </li>
//                       <li className={selectedPage === 12 ?'sidebar-list-item sidebar-page-active':'sidebar-list-item'}>
//                           <Link to="/all-product-audit">All Product Audit</Link>
//                       </li>
//                   </ul>
//               </li>
  
//               <li style={{display:`${shop_domain.replace(".myshopify.com","") === "dm-test-store-01" ? 'block': 'none'}`}} className={[10,15,16,20,14,18].includes(selectedPage) ?'sidebar-list-item show-list-item-dropdown':'sidebar-list-item'} >
//                   <span className='sidebar-list-hover-title' onClick={()=>{
//                       let display_style = document.getElementById("sidebar-product").style.display;
//                       if(display_style === "none"){
//                           document.getElementById("sidebar-product-down").style.display = "none";
//                           document.getElementById("sidebar-product-up").style.display = "block";
//                           document.getElementById("sidebar-website-optimization-up").style.display = "none";
//                           document.getElementById("sidebar-website-optimization-down").style.display = "block";
//                           document.getElementById("sidebar-ad-copy-optimization-up").style.display = "none";
//                           document.getElementById("sidebar-ad-copy-optimization-down").style.display = "block";
//                           document.getElementById("sidebar-customer-behavior-up").style.display = "none";
//                           document.getElementById("sidebar-customer-behavior-down").style.display = "block";
//                           document.getElementById("sidebar-integration-up").style.display = "none";
//                           document.getElementById("sidebar-integration-down").style.display = "block";
//                           document.getElementById("sidebar-performance-up").style.display = "none";
//                           document.getElementById("sidebar-performance-down").style.display = "block";
//                       }
//                       else{
//                           document.getElementById("sidebar-product-down").style.display = "block";
//                           document.getElementById("sidebar-product-up").style.display = "none";
//                           document.getElementById("sidebar-website-optimization-up").style.display = "none";
//                           document.getElementById("sidebar-website-optimization-down").style.display = "block";
//                           document.getElementById("sidebar-ad-copy-optimization-up").style.display = "none";
//                           document.getElementById("sidebar-ad-copy-optimization-down").style.display = "block";
//                           document.getElementById("sidebar-customer-behavior-up").style.display = "none";
//                           document.getElementById("sidebar-customer-behavior-down").style.display = "block";
//                           document.getElementById("sidebar-integration-up").style.display = "none";
//                           document.getElementById("sidebar-integration-down").style.display = "block";
//                           document.getElementById("sidebar-performance-up").style.display = "none";
//                           document.getElementById("sidebar-performance-down").style.display = "block";
//                       }
                      
//                       document.getElementById("sidebar-performance").style.display = "none";
//                       document.getElementById("sidebar-integration").style.display = "none";
//                       document.getElementById("sidebar-website-optimization").style.display = "none";
//                       document.getElementById("sidebar-ad-copy-optimization").style.display = "none";
//                       document.getElementById("sidebar-customer-behavior").style.display = "none";
//                       document.getElementById("sidebar-product").style.display = display_style === "block" ? "none": "block";
//                   }}>
//                       <p style={{margin:"0"}}>Google Ads</p>
//                       <svg id='sidebar-product-up' style={{display:`${[10,15,16,20,14,18].includes(selectedPage) ? "block" : "none"}`}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-up" viewBox="0 0 16 16">
//                           <path fillRule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708z"/>
//                       </svg>
//                       <svg id='sidebar-product-down' style={{display:`${[10,15,16,20,14,18].includes(selectedPage) ? "none" : "block"}`}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
//                           <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"/>
//                       </svg>
//                   </span>
//                   <ul className='sidebar-list-dropdown' id='sidebar-product'>
//                       <li className={selectedPage === 10 ?'sidebar-list-item sidebar-page-active':'sidebar-list-item'}>
//                           <img src={google_logo} alt='Meta' width={15} height={15} style={{marginRight:"5px"}} /><Link to="/ads-copy-optimization">Ad Copies Generation (Existing Adgroups)</Link>
//                       </li>
//                       <li className={selectedPage === 15 ?'sidebar-list-item sidebar-page-active':'sidebar-list-item'}>
//                           <img src={google_logo} alt='Meta' width={15} height={15} style={{marginRight:"5px"}} /><Link to="/google-ads-copy-ss">Ad Copies Generation (New Adgroups)</Link>
//                       </li>
//                       <li className={selectedPage === 13 ?'sidebar-list-item sidebar-page-active':'sidebar-list-item'}>
//                           <img src={google_logo} alt='Meta' width={15} height={15} style={{marginRight:"5px"}} /><Link to="/shopping">Feed Optimization</Link>
//                       </li>
//                       <li className={selectedPage === 7 ?'sidebar-list-item sidebar-page-active':'sidebar-list-item'}>
//                           <img src={google_logo} alt='Meta' width={15} height={15} style={{marginRight:"5px"}} /><Link to="/feed-enrichment">Feed Enrichment</Link>
//                       </li>
//                   </ul>
//               </li>
  
//               <li style={{display:`${show_under_dev_pages === "true" ? 'block': 'none'}`}} className={[14,16,18,19,20].includes(selectedPage) ?'sidebar-list-item show-list-item-dropdown':'sidebar-list-item'} >
//                   <span className='sidebar-list-hover-title' onClick={()=>{
//                       let display_style = document.getElementById("sidebar-ad-copy-optimization").style.display;
//                       if(display_style === "none"){
//                           document.getElementById("sidebar-ad-copy-optimization-down").style.display = "none";
//                           document.getElementById("sidebar-ad-copy-optimization-up").style.display = "block";
//                           document.getElementById("sidebar-website-optimization-up").style.display = "none";
//                           document.getElementById("sidebar-website-optimization-down").style.display = "block";
//                           document.getElementById("sidebar-product-up").style.display = "none";
//                           document.getElementById("sidebar-product-down").style.display = "block";
//                           document.getElementById("sidebar-customer-behavior-up").style.display = "none";
//                           document.getElementById("sidebar-customer-behavior-down").style.display = "block";
//                           document.getElementById("sidebar-integration-up").style.display = "none";
//                           document.getElementById("sidebar-integration-down").style.display = "block";
//                           document.getElementById("sidebar-performance-up").style.display = "none";
//                           document.getElementById("sidebar-performance-down").style.display = "block";
//                       }
//                       else{
//                           document.getElementById("sidebar-ad-copy-optimization-down").style.display = "block";
//                           document.getElementById("sidebar-ad-copy-optimization-up").style.display = "none";
//                           document.getElementById("sidebar-website-optimization-up").style.display = "none";
//                           document.getElementById("sidebar-website-optimization-down").style.display = "block";
//                           document.getElementById("sidebar-product-up").style.display = "none";
//                           document.getElementById("sidebar-product-down").style.display = "block";
//                           document.getElementById("sidebar-customer-behavior-up").style.display = "none";
//                           document.getElementById("sidebar-customer-behavior-down").style.display = "block";
//                           document.getElementById("sidebar-integration-up").style.display = "none";
//                           document.getElementById("sidebar-integration-down").style.display = "block";
//                           document.getElementById("sidebar-performance-up").style.display = "none";
//                           document.getElementById("sidebar-performance-down").style.display = "block";
//                       }
                      
//                       document.getElementById("sidebar-performance").style.display = "none";
//                       document.getElementById("sidebar-integration").style.display = "none";
//                       document.getElementById("sidebar-customer-behavior").style.display = "none";
//                       document.getElementById("sidebar-product").style.display = "none";
//                       document.getElementById("sidebar-website-optimization").style.display = "none";
//                       document.getElementById("sidebar-ad-copy-optimization").style.display = display_style === "block" ? "none": "block";
//                   }}>
//                       <p style={{margin:"0"}}>Meta Ads</p>
//                       <svg id='sidebar-ad-copy-optimization-up' style={{display:`${[14,16,18,19,20].includes(selectedPage) ? "block" : "none"}`}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-up" viewBox="0 0 16 16">
//                           <path fillRule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708z"/>
//                       </svg>
//                       <svg id='sidebar-ad-copy-optimization-down' style={{display:`${[14,16,18,19,20].includes(selectedPage) ? "none" : "block"}`}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
//                           <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"/>
//                       </svg>
//                   </span>
//                   <ul className='sidebar-list-dropdown' id='sidebar-ad-copy-optimization'>
//                       <li className={selectedPage === 18 ?'sidebar-list-item sidebar-page-active':'sidebar-list-item'}>
//                           <img src={meta_logo} alt='Meta' width={15} height={15} style={{marginRight:"5px"}} /><Link to="/reports">Reports</Link>
//                       </li>
//                       <li className={selectedPage === 16 ?'sidebar-list-item sidebar-page-active':'sidebar-list-item'}>
//                           <img src={meta_logo} alt='Meta' width={15} height={15} style={{marginRight:"5px"}} /><Link to="/creative-analysis">Analytics</Link>
//                       </li>
//                       <li className={selectedPage === 14 ?'sidebar-list-item sidebar-page-active':'sidebar-list-item'}>
//                           <img src={meta_logo} alt='Meta' width={15} height={15} style={{marginRight:"5px"}} /><Link to="/creative_ideation">Creative Ideation</Link>
//                       </li>
//                       <li className={selectedPage === 19 ?'sidebar-list-item sidebar-page-active':'sidebar-list-item'}>
//                           <img src={meta_logo} alt='Meta' width={15} height={15} style={{marginRight:"5px"}} /><Link to="/landing-page-optimization">Landing Page Optimization</Link>
//                       </li>
//                       <li className={selectedPage === 20 ?'sidebar-list-item sidebar-page-active':'sidebar-list-item'}>
//                           <img src={meta_logo} alt='Meta' width={15} height={15} style={{marginRight:"5px"}} /><Link to="/chatbot">Communication AI</Link>
//                       </li>
//                   </ul>
//               </li>
  
  
//               <li className={[5,2,7,13,0,1,3,6,8].includes(selectedPage) ?'sidebar-list-item show-list-item-dropdown':'sidebar-list-item'}>
//                   <span className='sidebar-list-hover-title' onClick={()=>{
//                       let display_style = document.getElementById("sidebar-customer-behavior").style.display;
//                       if(display_style === "none"){
//                           document.getElementById("sidebar-customer-behavior-down").style.display = "none";
//                           document.getElementById("sidebar-customer-behavior-up").style.display = "block";
//                           document.getElementById("sidebar-website-optimization-up").style.display = "none";
//                           document.getElementById("sidebar-website-optimization-down").style.display = "block";
//                           document.getElementById("sidebar-ad-copy-optimization-up").style.display = "none";
//                           document.getElementById("sidebar-ad-copy-optimization-down").style.display = "block";
//                           document.getElementById("sidebar-product-up").style.display = "none";
//                           document.getElementById("sidebar-product-down").style.display = "block";
//                           document.getElementById("sidebar-integration-up").style.display = "none";
//                           document.getElementById("sidebar-integration-down").style.display = "block";
//                           document.getElementById("sidebar-performance-up").style.display = "none";
//                           document.getElementById("sidebar-performance-down").style.display = "block";
//                       }
//                       else{
//                           document.getElementById("sidebar-customer-behavior-down").style.display = "block";
//                           document.getElementById("sidebar-customer-behavior-up").style.display = "none";
//                           document.getElementById("sidebar-website-optimization-up").style.display = "none";
//                           document.getElementById("sidebar-website-optimization-down").style.display = "block";
//                           document.getElementById("sidebar-ad-copy-optimization-up").style.display = "none";
//                           document.getElementById("sidebar-ad-copy-optimization-down").style.display = "block";
//                           document.getElementById("sidebar-product-up").style.display = "none";
//                           document.getElementById("sidebar-product-down").style.display = "block";
//                           document.getElementById("sidebar-integration-up").style.display = "none";
//                           document.getElementById("sidebar-integration-down").style.display = "block";
//                           document.getElementById("sidebar-performance-up").style.display = "none";
//                           document.getElementById("sidebar-performance-down").style.display = "block";
//                       }
                      
//                       document.getElementById("sidebar-performance").style.display = "none";
//                       document.getElementById("sidebar-integration").style.display = "none";
//                       document.getElementById("sidebar-product").style.display = "none";
//                       document.getElementById("sidebar-ad-copy-optimization").style.display = "none";
//                       document.getElementById("sidebar-website-optimization").style.display = "none";
//                       document.getElementById("sidebar-customer-behavior").style.display = display_style === "block" ? "none": "block";
//                   }}>
//                       <p style={{margin:"0"}}>LTV Analytics</p>
//                       <svg id='sidebar-customer-behavior-up' style={{display:`${[5,2,7,13,0,1,3,6,8].includes(selectedPage) ? "block" : "none"}`}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-up" viewBox="0 0 16 16">
//                           <path fillRule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708z"/>
//                       </svg>
//                       <svg id='sidebar-customer-behavior-down' style={{display:`${[5,2,7,13,0,1,3,6,8].includes(selectedPage) ? "none" : "block"}`}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
//                           <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"/>
//                       </svg>
//                   </span>
//                   <ul className='sidebar-list-dropdown' id='sidebar-customer-behavior'>
//                       <li className={selectedPage === 2 ?'sidebar-list-item sidebar-page-active':'sidebar-list-item'}>
//                           <Link to="/lifetime-value">Lifetime Value</Link>
//                       </li>
//                       <li className={selectedPage === 3 ?'sidebar-list-item sidebar-page-active':'sidebar-list-item'}>
//                           <Link to="/product-journey">Product Journey</Link>
//                       </li>
//                       <li className={selectedPage === 8 ?'sidebar-list-item sidebar-page-active':'sidebar-list-item'}>
//                           <Link to="/repurchase-rate">Repurchase Rate</Link>
//                       </li>
//                       <li className={selectedPage === 6 ?'sidebar-list-item sidebar-page-active':'sidebar-list-item'}>
//                           <Link to="/product-performance">Performance By Channels</Link>
//                       </li>
//                   </ul>
//               </li>
  
//               <li className={[0,1,5].includes(selectedPage) ?'sidebar-list-item show-list-item-dropdown':'sidebar-list-item'}>
//                   <span className='sidebar-list-hover-title' onClick={()=>{
//                       let display_style = document.getElementById("sidebar-performance").style.display;
//                       if(display_style === "none"){
//                           document.getElementById("sidebar-performance-down").style.display = "none";
//                           document.getElementById("sidebar-performance-up").style.display = "block";
//                           document.getElementById("sidebar-website-optimization-up").style.display = "none";
//                           document.getElementById("sidebar-website-optimization-down").style.display = "block";
//                           document.getElementById("sidebar-ad-copy-optimization-up").style.display = "none";
//                           document.getElementById("sidebar-ad-copy-optimization-down").style.display = "block";
//                           document.getElementById("sidebar-product-up").style.display = "none";
//                           document.getElementById("sidebar-product-down").style.display = "block";
//                           document.getElementById("sidebar-integration-up").style.display = "none";
//                           document.getElementById("sidebar-integration-down").style.display = "block";
//                           document.getElementById("sidebar-customer-behavior-up").style.display = "none";
//                           document.getElementById("sidebar-customer-behavior-down").style.display = "block";
//                       }
//                       else{
//                           document.getElementById("sidebar-performance-down").style.display = "block";
//                           document.getElementById("sidebar-performance-up").style.display = "none";
//                           document.getElementById("sidebar-website-optimization-up").style.display = "none";
//                           document.getElementById("sidebar-website-optimization-down").style.display = "block";
//                           document.getElementById("sidebar-ad-copy-optimization-up").style.display = "none";
//                           document.getElementById("sidebar-ad-copy-optimization-down").style.display = "block";
//                           document.getElementById("sidebar-product-up").style.display = "none";
//                           document.getElementById("sidebar-product-down").style.display = "block";
//                           document.getElementById("sidebar-integration-up").style.display = "none";
//                           document.getElementById("sidebar-integration-down").style.display = "block";
//                           document.getElementById("sidebar-customer-behavior-up").style.display = "none";
//                           document.getElementById("sidebar-customer-behavior-down").style.display = "block";
//                       }
                      
//                       document.getElementById("sidebar-customer-behavior").style.display = "none";
//                       document.getElementById("sidebar-integration").style.display = "none";
//                       document.getElementById("sidebar-website-optimization").style.display = "none";
//                       document.getElementById("sidebar-ad-copy-optimization").style.display = "none";
//                       document.getElementById("sidebar-product").style.display = "none";
//                       document.getElementById("sidebar-performance").style.display = display_style === "block" ? "none": "block";
//                   }}>
//                       <p style={{margin:"0"}}>Advanced Channel Performance</p>
//                       <svg id='sidebar-performance-up' style={{display:`${[0,1,5].includes(selectedPage) ? "block" : "none"}`}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-up" viewBox="0 0 16 16">
//                           <path fillRule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708z"/>
//                       </svg>
//                       <svg id='sidebar-performance-down' style={{display:`${[0,1,5].includes(selectedPage) ? "none" : "block"}`}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
//                           <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"/>
//                       </svg>
//                   </span>
//                   <ul className='sidebar-list-dropdown' id='sidebar-performance'>
//                       <li className={selectedPage === 0 ?'sidebar-list-item sidebar-page-active':'sidebar-list-item'}>
//                           <Link to="/metrics-performance">Metrics Trend</Link>
//                       </li>
//                       <li className={selectedPage === 1 ?'sidebar-list-item sidebar-page-active':'sidebar-list-item'}>
//                           <Link to="/metrics-deepdive">Metrics Deepdive</Link>
//                       </li>
//                       <li className={selectedPage === 5 ?'sidebar-list-item sidebar-page-active':'sidebar-list-item'}>
//                           <Link to="/enrich-and-bid">Enrich & Bid</Link>
//                       </li>
//                   </ul>
//               </li>
  
//               <li className={[4,17].includes(selectedPage) ?'sidebar-list-item show-list-item-dropdown':'sidebar-list-item'}>
//                   <span className='sidebar-list-hover-title' onClick={()=>{
//                       let display_style = document.getElementById("sidebar-integration").style.display;
//                       if(display_style === "none"){
//                           document.getElementById("sidebar-integration-down").style.display = "none";
//                           document.getElementById("sidebar-integration-up").style.display = "block";
//                           document.getElementById("sidebar-website-optimization-up").style.display = "none";
//                           document.getElementById("sidebar-website-optimization-down").style.display = "block";
//                           document.getElementById("sidebar-ad-copy-optimization-up").style.display = "none";
//                           document.getElementById("sidebar-ad-copy-optimization-down").style.display = "block";
//                           document.getElementById("sidebar-product-up").style.display = "none";
//                           document.getElementById("sidebar-product-down").style.display = "block";
//                           document.getElementById("sidebar-performance-up").style.display = "none";
//                           document.getElementById("sidebar-performance-down").style.display = "block";
//                           document.getElementById("sidebar-customer-behavior-up").style.display = "none";
//                           document.getElementById("sidebar-customer-behavior-down").style.display = "block";
//                       }
//                       else{
//                           document.getElementById("sidebar-integration-down").style.display = "block";
//                           document.getElementById("sidebar-integration-up").style.display = "none";
//                           document.getElementById("sidebar-website-optimization-up").style.display = "none";
//                           document.getElementById("sidebar-website-optimization-down").style.display = "block";
//                           document.getElementById("sidebar-ad-copy-optimization-up").style.display = "none";
//                           document.getElementById("sidebar-ad-copy-optimization-down").style.display = "block";
//                           document.getElementById("sidebar-product-up").style.display = "none";
//                           document.getElementById("sidebar-product-down").style.display = "block";
//                           document.getElementById("sidebar-performance-up").style.display = "none";
//                           document.getElementById("sidebar-customer-behavior-up").style.display = "none";
//                           document.getElementById("sidebar-performance-down").style.display = "block";
//                           document.getElementById("sidebar-customer-behavior-down").style.display = "block";
//                       }
//                       document.getElementById("sidebar-performance").style.display = "none";
//                       document.getElementById("sidebar-customer-behavior").style.display = "none";
//                       document.getElementById("sidebar-product").style.display = "none";
//                       document.getElementById("sidebar-website-optimization").style.display = "none";
//                       document.getElementById("sidebar-ad-copy-optimization").style.display = "none";
//                       document.getElementById("sidebar-integration").style.display = display_style === "block" ? "none": "block";
//                   }}>
//                       <p style={{margin:"0"}}>Settings</p>
//                       <svg id='sidebar-integration-up' style={{display:`${[4,17].includes(selectedPage) ? "block" : "none"}`}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-up" viewBox="0 0 16 16">
//                           <path fillRule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708z"/>
//                       </svg>
//                       <svg id='sidebar-integration-down' style={{display:`${[4,17].includes(selectedPage) ? "none" : "block"}`}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
//                           <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"/>
//                       </svg>
//                   </span>
//                   <ul className='sidebar-list-dropdown' id='sidebar-integration'>
//                       <li className={selectedPage === 4 ?'sidebar-list-item sidebar-page-active':'sidebar-list-item'}>
//                           <Link to="/">Google & Meta Inegration</Link>
//                       </li>
//                       <li className={selectedPage === 17 ?'sidebar-list-item sidebar-page-active':'sidebar-list-item'}>
//                           <Link to="/meta-creative-settings">Meta Creative Settings</Link>
//                       </li>
//                   </ul>
//               </li>
//           </ul>
//       </aside>
//     )
//   }