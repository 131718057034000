import React, { useState, useEffect } from 'react'
import { useParams, useLocation, Link } from 'react-router-dom';
import { Chart } from "react-google-charts";
import Spinner from 'react-bootstrap/Spinner';
import Container from 'react-bootstrap/Container';
import Label from 'react-bootstrap/FormLabel';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import ReMultiSelect from 'react-select';
import ReMultiSelectAnimated from 'react-select/animated';
import { DateRangePicker } from "rsuite";
import {showToast as toast} from '../toastManager';
import line_chart from '../images/chart.png';
import column_chart from '../images/column.png';
import cards_logo from '../images/collection.png';
import Cookies from "js-cookie";
import axios from 'axios';
import alasql from 'alasql';
import { FormControl } from 'react-bootstrap';

alasql.fn.DateToStringFormat = function (date){
    return formatDate(date);
}

alasql.fn.Strip_URL = function (url){
    return url.split("?")[0];
}

alasql.fn.GET_CREATIVE_URL = function (shop_domain, ad_id, creative_ad_id_mapping){
    let selected_ad_creative_mapping = JSON.parse(creative_ad_id_mapping).filter((map_iter) => { return  ad_id.flat().includes(map_iter["ad_id"])});
    let creative_id = selected_ad_creative_mapping.length > 0 ?  selected_ad_creative_mapping[0]["creative_id"][0] : "default_img.png";
    console.log(creative_id)
    return `https://storage.googleapis.com/scalex-ai/scaleX_AI_${shop_domain.replace(".myshopify.com","")}/Meta_Creative/${creative_id}`;
}

const custom_metrics_arr = ["ROAS","CTR","CPC"]

function ReportTopPerforming({ViewedPage, shop_domain, app_url}) {
    ViewedPage(18);

    const { combine, before, afterToday } = DateRangePicker;
    const max_date = new Date();
    max_date.setDate(max_date.getDate() - 365);

    const { report_id } = useParams();
    const location = useLocation();
    const saved_report_settings = location.state;

    const [filter_date, setFilterDate] = useState([]);
    const [groupby_filter, setGroupByFilter] = useState(saved_report_settings["groupby"]);
    const [current_selected_ads_level, setCurrentSelectedAdsLevel] = useState({ value: 'campaign_name', label: 'Campaign name' });
    const [current_selected_ads_level_operator, setCurrentSelectedAdsLevelOperator] = useState({ value: 'LIKE', label: 'contains' });
    const [current_selected_ads_level_input_value, setCurrentSelectedAdsLevelInputValue] = useState("");
    const [current_selected_filters_data, setCurrentSelectedFilterData] = useState([]);
    const [all_filters_data, setAllFiltersData] = useState(saved_report_settings["filters"]);
    const [creative_image_id_line_chart, setCreativeImageIDLineChart] = useState([]);
    const [all_creative_data, setCreativeData] = useState([]);
    const [all_insights_data, setInsightsData] = useState([]);
    const [metrics_default_value, setMetricsDefaultValue] = useState({"value":"Add Metrics","label": "Add Metrics"})
    const [all_metrics, setAllMetrics] = useState([
        { value:"clicks", label:"Clicks"},
        { value:"impressions", label:"Impressions"},
        { value:"spend", label:"Spend"},
        { value:"purchase_value", label:"Purchase"},
        { value:"SUM(CAST(purchase_value AS FLOAT))/SUM(CAST(spend AS FLOAT))", label:"ROAS"},
        { value:"SUM(CAST(spend AS FLOAT))/SUM(CAST(clicks AS FLOAT))*100", label:"CPC"},
        { value:"SUM(CAST(clicks AS FLOAT))/SUM(CAST(impressions AS FLOAT))*100", label:"CTR"},
        { value:"video_view", label:"3 sec video play"},
        { value:"video_15_sec_watched_actions_video_view", label:"15 sec video play"},
        { value:"video_30_sec_watched_actions_video_view", label:"30 sec video play"},
        { value:"video_p25_watched_actions_video_view", label:"25% video play"},
        { value:"video_p50_watched_actions_video_view", label:"50% video play"},
        { value:"video_p75_watched_actions_video_view", label:"75% video play"},
        { value:"video_p95_watched_actions_video_view", label:"95% video play"},
        { value:"video_p100_watched_actions_video_view", label:"100% video play"},
        { value:"video_thruplay_watched_actions_video_view", label:"Thruplay"},
        { value:"unique_video_continuous_2_sec_watched_actions_video_view", label:"Unique 2 sec continuous video play"},
    ]);
    const [all_selected_metrics, setAllSelectedMetrics] = useState(saved_report_settings["metrics"]);
    const [show_group_by_filter, setShowGroupByFilter] = useState(false);
    const [loading, setLoading] = useState(true);
    const [chart_loading, setChartLoading] = useState(false);
    const [report_save_loading, setReportSaveLoading] = useState(false);
    
    const [selected_charts_type, setSelectedChartsType] = useState("LineChart");
    const [chart_data, setChartData] = useState([]);
    const [table_chart_data, setTableChartData] = useState([]);
    const [unique_ads_value_of_groupby, setAllUniqueAdsValueOfGroupBY] = useState([]);
    const [report_settings, setReportSettings] = useState(saved_report_settings);

    const number_of_metrics_allowed = {
        "LineChart":2,
        "ColumnChart":4,
        "Cards":6,
    };

    useEffect(() => {
        setLoading(true);
        try {
            if(Cookies.get('shop')){
                // Facebook Loader data
                var created_at_max = new Date();
                var created_at_min = new Date(new Date().setDate(created_at_max.getDate() - 15));
                setFilterDate([created_at_min, created_at_max]);
                axios.post(app_url+"/api/facebook/get_meta_creatives_data_from_redis",{
                    shop_domain:Cookies.get('shop'),
                    date_start:formatDate(created_at_min),
                    date_end:formatDate(created_at_max)
                }, {withCredentials:true})
                .then(res => res.data)
                .then(data => {
                    console.log(data)
                    setCreativeData(data["data"]["creative_data"]);
                    setInsightsData(data["data"]["insights_data"]);
                    setFilterDate(get_filter_date_from_data(data["data"]["insights_data"]));
                    let {creative_ids, line_chart_data}= get_line_charts_data(shop_domain, data["data"]["insights_data"], data["data"]["creative_data"], saved_report_settings["metrics"], saved_report_settings["groupby"], saved_report_settings["filters"]);
                    setCreativeImageIDLineChart(creative_ids)
                    setChartData(line_chart_data)
                    setTableChartData(get_overview_table_data(shop_domain, data["data"]["insights_data"], data["data"]["creative_data"], saved_report_settings["metrics"], saved_report_settings["groupby"], saved_report_settings["filters"]));
                    setAllUniqueAdsValueOfGroupBY(get_all_unique_ads(data["data"]["creative_data"], saved_report_settings["groupby"]))
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                    toast.error("Please try after some time.");
                    console.log(err);
                })
            }
        } catch (error) {
            setLoading(false)
            toast.error("Please try after some time.");
            console.log(error);
        }

    }, [app_url, shop_domain, saved_report_settings]);

    const default_colors_list = ["#ca1500","#d88300","#46a400","#008ba4","#7c00a4"];

    const line_charts_config = {
        chartArea: {'width': '80%', 'height': '70%', 'top':'10%'},
        titleTextStyle : {fontSize: 16},
        curveType: 'function',
        hAxis: { 
            format: 'MMM d',
            gridlines: {
                color: 'transparent',
            },
        },
        vAxis: {
            format: "short",
            gridlines: {
                color: '#edf1f4',
            },
            viewWindow : { min:0 },
        },
        // vAxes: {
        //     // Adds titles to each axis.
        //     0: { title: 'Clicks' },
        //     1: { title: 'Spend' },
        // },
        legend: "none",
        tooltip: { isHtml: true },
        colors:default_colors_list,
        animation: { startup: true, easing: "linear", duration: 1000 },
    }
    
    const column_charts_config = {
        hAxis: { 
            format: 'MMM d, y',
            gridlines: {
                color: 'transparent',
            },
        },
        series:{
            0:{ targetAxisIndex : 0},
            1:{ targetAxisIndex : 1},
        },
        // vAxes: {
        //     // Adds titles to each axis.
        //     0: { title: 'Clicks' },
        //     1: { title: 'Spend' },
        // },
        vAxis: {
            format: "short",
            gridlines: {
                color: '#edf1f4',
            },
        },
        tooltip: { isHtml: true },
        animation: { startup: true, easing: "linear", duration: 1000 },
    }
    


    const handle_group_filters_hide = () => {
        if(current_selected_filters_data.flat().length === 0){
            setShowGroupByFilter(false)
        }
        else{
            let temp_all_filter_data = [all_filters_data, [current_selected_filters_data]].flat();
            setAllFiltersData(temp_all_filter_data);
            if(selected_charts_type === "LineChart"){
                let {creative_ids, line_chart_data}= get_line_charts_data(shop_domain, all_insights_data, all_creative_data, all_selected_metrics, groupby_filter, temp_all_filter_data);
                setCreativeImageIDLineChart(creative_ids);
                setChartData(line_chart_data);
            }
            else if(selected_charts_type === "ColumnChart"){
                setChartData(get_bar_charts_data(shop_domain, all_insights_data, all_creative_data, all_selected_metrics, groupby_filter, temp_all_filter_data));
            }
            else{
                setChartData(get_cards_data(shop_domain, all_insights_data, all_creative_data, all_selected_metrics, groupby_filter, temp_all_filter_data));
            }
            setTableChartData(get_overview_table_data(shop_domain, all_insights_data, all_creative_data, all_selected_metrics, groupby_filter, temp_all_filter_data));
            setCurrentSelectedFilterData([]);
            setShowGroupByFilter(false)
        }
    };
    const handle_group_filters_show = () => setShowGroupByFilter(true);


    const handleDateChange = async (date_range) => {
        setChartLoading(true)
        if((date_range !== undefined) && (date_range !== null) && (date_range.length > 0)){
            setFilterDate(date_range);
            axios.post(app_url+"/api/facebook/get_meta_creatives_data",{
                shop_domain:Cookies.get('shop'),
                date_start:formatDate(date_range[0]),
                date_end:formatDate(date_range[1])
            }, {withCredentials:true})
            .then(res => res.data)
            .then(data => {
                console.log(data)
                setCreativeData(data["data"]["creative_data"]);
                setInsightsData(data["data"]["insights_data"]);
                    if(selected_charts_type === "LineChart"){
                        let {creative_ids, line_chart_data}= get_line_charts_data(shop_domain, data["data"]["insights_data"], data["data"]["creative_data"], all_selected_metrics, groupby_filter, all_filters_data);
                        setCreativeImageIDLineChart(creative_ids);
                        setChartData(line_chart_data);
                    }
                    else if(selected_charts_type === "ColumnChart"){
                        setChartData(get_bar_charts_data(shop_domain, data["data"]["creative_data"], all_selected_metrics, groupby_filter, all_filters_data))
                    }
                    else{
                        setChartData(get_cards_data(shop_domain, data["data"]["insights_data"], data["data"]["creative_data"], all_selected_metrics, groupby_filter, all_filters_data))
                    }
                    setTableChartData(get_overview_table_data(shop_domain, data["data"]["insights_data"], data["data"]["creative_data"], all_selected_metrics, groupby_filter, all_filters_data))
                setAllUniqueAdsValueOfGroupBY(get_all_unique_ads(data["data"]["creative_data"], {"value":"ad_name", "label":"Ad name"}))
                setChartLoading(false)
            })
            .catch(err => {
                setChartLoading(false)
                toast.error("Please try after some time.");
                console.log(err);
            })
        }
        else{
            setFilterDate([]);
        }
    }


    const handle_save_reports_settings = (e) => {
        setReportSaveLoading(true);
        let temp_report_settings = report_settings;
        temp_report_settings["start_date"] = formatDate(filter_date[0]);
        temp_report_settings["end_date"] = formatDate(filter_date[1]);
        temp_report_settings["filters"] = all_filters_data;
        temp_report_settings["metrics"] = all_selected_metrics;
        temp_report_settings["groupby"] = groupby_filter;
        console.log(temp_report_settings)
        setReportSettings(temp_report_settings);
        axios.post(app_url+"/api/facebook/save_meta_reports_settings",{
            shop_domain:Cookies.get('shop'),
            id:report_id,
            reports_settings:temp_report_settings
        }, {withCredentials:true})
        .then(res => res.data)
        .then(data => {
            setReportSaveLoading(false);
            console.log(data)
            toast.success("Saved successfully.");
        })
        .catch(err => {
            setReportSaveLoading(false);
            console.log(err);
            toast.error("Please try after some time.");
        })
    }

    return (
    <div className='main-container'>
        { !loading ?
            <>
                <Modal size='lg' show={show_group_by_filter} onHide={handle_group_filters_hide}>
                    <Modal.Header closeButton>
                        <Modal.Title>Filters</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{height:"300px", overflowY:"scroll"}}>
                        <Container className='my-4'>
                            <Row className='justify-content-md-left' lg={4} xs={1}>
                                <Col className='py-2'>
                                    <ReMultiSelect 
                                        id="x_axis"
                                        closeMenuOnSelect={false}
                                        components={ReMultiSelectAnimated()}
                                        defaultValue={current_selected_ads_level}
                                        options={[
                                            { value: 'campaign_name', label: 'Campaign name' },
                                            { value: 'adset_name', label: 'Adset name' },
                                            { value: 'ad_name', label: 'Ad name' },
                                        ]}
                                        onChange={(selected_vals) => {
                                            setCurrentSelectedAdsLevel(selected_vals);
                                        }}
                                    />
                                </Col>
                                <Col className='py-2'>
                                    <ReMultiSelect 
                                        id="x_axis"
                                        closeMenuOnSelect={false}
                                        components={ReMultiSelectAnimated()}
                                        defaultValue={current_selected_ads_level_operator}
                                        options={[
                                            { value: 'LIKE', label: 'contains' },
                                            { value: 'NOT LIKE', label: "doesn't contain" },
                                        ]}
                                        onChange={(selected_vals) => {
                                            setCurrentSelectedAdsLevelOperator(selected_vals);
                                        }}
                                    />
                                </Col>
                                <Col className='py-2'>
                                    <Form.Control type="text" placeholder="Group Name" value={current_selected_ads_level_input_value} onChange={(e) => {
                                        setCurrentSelectedAdsLevelInputValue(e.target.value);
                                    }} />
                                </Col>
                                <Col className='py-2'>
                                    <Button variant='primary' onClick={(e) => {
                                        e.preventDefault();
                                        let temp_current_selected_ads_level_input_value = current_selected_ads_level_input_value;
                                        if((current_selected_ads_level_input_value === null) || (current_selected_ads_level_input_value === undefined) || (current_selected_ads_level_input_value.trim() === "")){
                                            temp_current_selected_ads_level_input_value = "";
                                        }
                                        let temp_current_selected_filters_data = [current_selected_filters_data, [[current_selected_ads_level, current_selected_ads_level_operator, temp_current_selected_ads_level_input_value]]].flat();
                                        setCurrentSelectedFilterData(temp_current_selected_filters_data);
                                    }} className='w-100'>Add</Button>
                                </Col>
                            </Row>

                            <Row className='justify-content-md-left' lg={1} xs={1}>
                                <Col className='py-4'>
                                    <div>
                                        {current_selected_filters_data.map((val, idx) => {
                                            return (
                                                <p style={{margin:"5px", backgroundColor:"#edefff", padding:"0.5rem", borderRadius:"5px", display:"inline-block"}}>
                                                    {val[0]["label"]} {val[1]["label"]} {val[2]} 
                                                    <button style={{backgroundColor:"#edefff", border:"none", padding:"0px 5px 0px 10px", marginLeft:"5px", borderLeft:"1px solid #ccc"}} onClick={(e) => {
                                                        e.preventDefault();

                                                        let temp_current_selected_filters_data = current_selected_filters_data.filter((all_val,all_idx) => { return all_idx !== idx })
                                                        setCurrentSelectedFilterData(temp_current_selected_filters_data);
                                                    }}>
                                                        &#10005;
                                                    </button>
                                                </p> 
                                            );
                                        })}
                                    </div>
                                    <Button className='mt-3' onClick={(e) => {
                                        e.preventDefault();
                                        let temp_all_filter_data = [all_filters_data, [current_selected_filters_data]].flat();
                                        setAllFiltersData(temp_all_filter_data);
                                        if(selected_charts_type === "LineChart"){
                                            let {creative_ids, line_chart_data}= get_line_charts_data(shop_domain, all_insights_data, all_creative_data, all_selected_metrics, groupby_filter, temp_all_filter_data);            
                                            setCreativeImageIDLineChart(creative_ids);
                                            setChartData(line_chart_data);
                                        }
                                        else if(selected_charts_type === "LineChart"){
                                            setChartData(get_bar_charts_data(shop_domain, all_insights_data, all_creative_data, all_selected_metrics, groupby_filter, temp_all_filter_data));
                                        }
                                        else{
                                            setChartData(get_cards_data(shop_domain, all_insights_data, all_creative_data, all_selected_metrics, groupby_filter, temp_all_filter_data));
                                        }
                                        setTableChartData(get_overview_table_data(shop_domain, all_insights_data, all_creative_data, all_selected_metrics, groupby_filter, temp_all_filter_data));
                                        setCurrentSelectedFilterData([]);
                                        handle_group_filters_hide();
                                    }}>Apply</Button>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                </Modal>
                
                <div className='d-flex justify-content-between px-3 my-4'>
                    
                    <div style={{width:"80%"}}>
                        <input id="report_name_input" type="text" placeholder="Report Name..." value={(report_settings["name"] !== null) || (report_settings["name"] !== undefined) || (report_settings["name"].strip() !== "") ? report_settings["name"] : "Report Name..."} onChange={(e) => { 
                            let temp_report_settings = {...report_settings};
                            temp_report_settings["name"] = e.target.value;
                            setReportSettings(temp_report_settings);
                        }}></input>
                        <br/>
                        <br/>
                        <textarea id="report_detail_input" type="text" placeholder="Detail..." value={(report_settings["detail"] !== null) || (report_settings["detail"] !== undefined) || (report_settings["detail"].strip() !== "") ? report_settings["detail"] : "Detail..."} onChange={(e) => { 
                            let temp_report_settings = {...report_settings};
                            temp_report_settings["detail"] = e.target.value;
                            setReportSettings(temp_report_settings);
                        }}></textarea>
                        
                    </div>
                    <div style={{width:"15%", marginRight:"0.5rem", marginTop:"0.8rem", textAlign:"right"}}>
                        {report_save_loading ?
                            <Button style={{backgroundColor:"white", color:"#00d96d", border:"none"}}><Spinner animation="border" /></Button>
                            :
                            <Button style={{backgroundColor:"white", color:"#00d96d", border:"none"}} onClick={handle_save_reports_settings}>Save</Button>
                        }
                    </div>

                </div>
                <Container className='my-4'>
                    <Row className='justify-content-md-left' lg={5} xs={1}>
                        <Col className='p-2'>
                            <Label className="charts_filter_label">Time Period</Label>
                            <br />
                            <DateRangePicker shouldDisableDate={combine(before(max_date), afterToday())} editable={false} defaultValue={filter_date} id='date_range' format="yyyy-MM-dd" character=" - " showOneCalendar block onChange={handleDateChange} />
                        </Col>
                        {/* <Col className='p-2'>
                            <Label className="charts_filter_label">Group by</Label>
                            <ReMultiSelect 
                                id="x_axis"
                                closeMenuOnSelect={false}
                                components={ReMultiSelectAnimated()}
                                defaultValue={groupby_filter}
                                options={[
                                    { value: 'ad_name', label: 'Ad name' },
                                    { value: 'title', label: 'Headline' },
                                    { value: 'link', label: 'Landing Page' }
                                ]}
                                onChange={(selected_vals) => {
                                    setGroupByFilter(selected_vals);
                                }}
                            />
                        </Col> */}
                        <Col className='p-2 d-flex align-items-end'>
                            <Button className='d-flex align-items-center justify-content-center' style={{backgroundColor:"white",color:"#00d96d",border:"none",boxShadow:"0 2px 6px rgba(71,86,102,.05)",fontWeight:'500'}} onClick={handle_group_filters_show}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-filter-left" viewBox="0 0 16 16">
                                <path d="M2 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5m0-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5"/>
                            </svg>
                            Filters
                            </Button>
                        </Col>
                    </Row>

                    {all_filters_data.length > 0 && 
                        <div style={{width:"100%"}}> Filters :- &nbsp;
                            {all_filters_data.map((filter_iter, filter_iter_idx) => {
                                return (
                                    <p style={{margin:"5px", backgroundColor:"white", padding:"0.5rem", borderRadius:"5px", display:"inline-block", cursor:"pointer"}}>
                                        <p style={{margin:"0", display:"inline-block"}} onClick={(e) => {
                                            e.preventDefault();
                                            setCurrentSelectedFilterData(filter_iter);
                                            let temp_all_filter_data = all_filters_data.filter((all_val,all_idx) => { return all_idx !== filter_iter_idx })
                                            setAllFiltersData(temp_all_filter_data);
                                            if(selected_charts_type === "LineChart"){
                                                let {creative_ids, line_chart_data}= get_line_charts_data(shop_domain, all_creative_data, all_selected_metrics, groupby_filter, temp_all_filter_data);
                                                setCreativeImageIDLineChart(creative_ids);
                                                setChartData(line_chart_data);
                                            }
                                            else if(selected_charts_type === "ColumnChart"){
                                                setChartData(get_bar_charts_data(shop_domain, all_creative_data, all_selected_metrics, groupby_filter, temp_all_filter_data));
                                            }
                                            else{
                                                setChartData(get_cards_data(shop_domain, all_insights_data, all_creative_data, all_selected_metrics, groupby_filter, temp_all_filter_data));
                                            }
                                            setTableChartData(get_overview_table_data(shop_domain, all_insights_data, all_creative_data, all_selected_metrics, groupby_filter, temp_all_filter_data));
                                            setShowGroupByFilter(true);
                                        }}>
                                            {filter_iter.map((val, idx) => {
                                                return `${val[0]["label"]} ${val[1]["label"]} ${val[2]}`
                                            }).join(" or ")} 
                                        </p>
                                        <button style={{backgroundColor:"white", border:"none", padding:"0px 5px 0px 10px", marginLeft:"5px", borderLeft:"1px solid #ccc"}} onClick={(e) => {
                                            e.preventDefault();
                                            let temp_all_filter_data = all_filters_data.filter((all_val,all_idx) => { return all_idx !== filter_iter_idx })
                                            setAllFiltersData(temp_all_filter_data);
                                            if(selected_charts_type === "LineChart"){
                                                let {creative_ids, line_chart_data}= get_line_charts_data(shop_domain, all_insights_data, all_creative_data, all_selected_metrics, groupby_filter, temp_all_filter_data);
                                                setCreativeImageIDLineChart(creative_ids);
                                                setChartData(line_chart_data);
                                            }
                                            else if(selected_charts_type === "ColumnChart"){
                                                setChartData(get_bar_charts_data(shop_domain, all_insights_data, all_creative_data, all_selected_metrics, groupby_filter, temp_all_filter_data));
                                            }
                                            else{
                                                setChartData(get_cards_data(shop_domain, all_insights_data, all_creative_data, all_selected_metrics, groupby_filter, temp_all_filter_data));
                                            }
                                            setTableChartData(get_overview_table_data(shop_domain, all_insights_data, all_creative_data, all_selected_metrics, groupby_filter, temp_all_filter_data));
                                        }}>
                                            &#10005;
                                        </button>
                                    </p> 
                                );
                            })}
                        </div>
                    }

                    <div className='d-flex justify-content-between p-4 mt-2' style={{backgroundColor:"white", borderRadius:"0.5rem"}} >
                        <div style={{display:"flex", flexWrap:"wrap",width:"60%"}}>
                            { all_selected_metrics.map((val) => {
                                return (
                                    <div style={{border:"1px solid black", padding:"6px 10px", borderRadius:"5px", display:"inline-block", cursor:"pointer", margin:"10px 10px", marginTop:"0px", height:"fit-content"}}>
                                        <p style={{display:"inline-block",marginBottom:"0"}}>{val["label"]}</p>
                                        <button style={{backgroundColor:"white", border:"none", padding:"0px 5px 0px 10px"}} onClick={(e) => {
                                            e.preventDefault();
                                            setChartLoading(true)
                                            setTimeout(() => {
                                                let temp_all_selected_metrics = all_selected_metrics.filter((metric_val) => { return metric_val !== val })
                                                setAllSelectedMetrics(temp_all_selected_metrics);
                                                if(selected_charts_type === "LineChart"){
                                                    let {creative_ids, line_chart_data}= get_line_charts_data(shop_domain, all_insights_data, all_creative_data, temp_all_selected_metrics, groupby_filter, all_filters_data);
                                                    setCreativeImageIDLineChart(creative_ids);
                                                    setChartData(line_chart_data);
                                                }
                                                else if(selected_charts_type === "ColumnChart"){
                                                    setChartData(get_bar_charts_data(shop_domain, all_insights_data, all_creative_data, temp_all_selected_metrics, groupby_filter, all_filters_data));
                                                }
                                                else{
                                                    setChartData(get_cards_data(shop_domain, all_insights_data, all_creative_data, temp_all_selected_metrics, groupby_filter, all_filters_data));
                                                }
                                                setTableChartData(get_overview_table_data(shop_domain, all_insights_data, all_creative_data, temp_all_selected_metrics, groupby_filter, all_filters_data));
                                                setChartLoading(false)
                                            }, 500)
                                        }}>
                                            &#10005;
                                        </button>
                                    </div>
                                )
                            })}
                            <div>
                                <ReMultiSelect 
                                    id="scattered_tags_value"
                                    closeMenuOnSelect={false}
                                    components={ReMultiSelectAnimated()}
                                    defaultValue={metrics_default_value}
                                    options={all_metrics}
                                    onChange={(selected_vals) => {
                                        setChartLoading(true);
                                        setTimeout(() => {
                                            setMetricsDefaultValue({"value":"Add Metrics","label": "Add Metrics"})
                                            let temp_all_selected_metrics = [all_selected_metrics].flat();
                                            if(!temp_all_selected_metrics.includes(selected_vals)){
                                                if(temp_all_selected_metrics.length < number_of_metrics_allowed[selected_charts_type]){
                                                    temp_all_selected_metrics.push(selected_vals);
                                                    setAllSelectedMetrics(temp_all_selected_metrics);
                                                    if(selected_charts_type === "LineChart"){
                                                        let {creative_ids, line_chart_data}= get_line_charts_data(shop_domain, all_insights_data, all_creative_data, temp_all_selected_metrics, groupby_filter, all_filters_data);
                                                        setCreativeImageIDLineChart(creative_ids);
                                                        setChartData(line_chart_data);
                                                    }
                                                    else if(selected_charts_type === "ColumnChart"){
                                                        setChartData(get_bar_charts_data(shop_domain, all_insights_data, all_creative_data, temp_all_selected_metrics, groupby_filter, all_filters_data));
                                                    }
                                                    else{
                                                        setChartData(get_cards_data(shop_domain, all_insights_data, all_creative_data, temp_all_selected_metrics, groupby_filter, all_filters_data));
                                                    }
                                                    setTableChartData(get_overview_table_data(shop_domain, all_insights_data, all_creative_data, temp_all_selected_metrics, groupby_filter, all_filters_data));
                                                }
                                                else{
                                                    toast.error("Number of selected metrics should be less than or equal to "+number_of_metrics_allowed[selected_charts_type]);
                                                }
                                            }
                                            setChartLoading(false);
                                        }, 500);
                                    }}
                                />
                            </div>
                        </div>
                        <div style={{width:"fit-content", display:"flex", alignItems:"center", justifyContent:"space-evenly", backgroundColor:"rgb(246 246 246)", border:"1px solid #aba9a9", borderRadius:"0.5rem", height:"fit-content"}}>
                            <button style={{backgroundColor:selected_charts_type === "LineChart"?"#fff":"transparent", border:selected_charts_type === "LineChart"?"1px solid #aba9a9":"none", padding:"5px", borderRadius:"0.5rem"}}><img width={20} src={line_chart} alt='Line' onClick={(e) => {
                                e.preventDefault();
                                setChartLoading(true);
                                setTimeout(() => {
                                    setSelectedChartsType("LineChart");
                                    let temp_all_selected_metrics = [all_selected_metrics].flat();
                                    temp_all_selected_metrics = temp_all_selected_metrics.slice(0, number_of_metrics_allowed[selected_charts_type]);
                                    setAllSelectedMetrics(temp_all_selected_metrics);
                                    let {creative_ids, line_chart_data}= get_line_charts_data(shop_domain, all_insights_data, all_creative_data, all_selected_metrics, groupby_filter, all_filters_data);
                                    setCreativeImageIDLineChart(creative_ids);
                                    setChartData(line_chart_data);
                                    setChartLoading(false);
                                },500)
                            }}></img></button>                    
                            <button style={{backgroundColor:selected_charts_type === "ColumnChart"?"#fff":"transparent", border:selected_charts_type === "ColumnChart"?"1px solid #aba9a9":"none", padding:"5px", borderRadius:"0.5rem"}}><img width={20} src={column_chart} alt='Bar' onClick={(e) => {
                                e.preventDefault();
                                setChartLoading(true);
                                setTimeout(() => {
                                    setSelectedChartsType("ColumnChart");
                                    let temp_all_selected_metrics = [all_selected_metrics].flat();
                                    temp_all_selected_metrics = temp_all_selected_metrics.slice(0, number_of_metrics_allowed[selected_charts_type]);
                                    setAllSelectedMetrics(temp_all_selected_metrics);
                                    setChartData(get_bar_charts_data(shop_domain, all_insights_data, all_creative_data, all_selected_metrics, groupby_filter, all_filters_data));
                                    setChartLoading(false);
                                },500);
                            }}></img></button>                    
                            <button style={{backgroundColor:selected_charts_type === "Cards"?"#fff":"transparent", border:selected_charts_type === "Cards"?"1px solid #aba9a9":"none", padding:"5px", borderRadius:"0.5rem"}}><img width={18} src={cards_logo} alt='Bar' onClick={(e) => {
                                e.preventDefault();
                                setChartLoading(true);
                                setTimeout(() => {
                                    setSelectedChartsType("Cards");
                                    let temp_all_selected_metrics = [all_selected_metrics].flat();
                                    temp_all_selected_metrics = temp_all_selected_metrics.slice(0, number_of_metrics_allowed[selected_charts_type]);
                                    setAllSelectedMetrics(temp_all_selected_metrics);
                                    setChartData(get_cards_data(shop_domain, all_insights_data, all_creative_data, all_selected_metrics, groupby_filter, all_filters_data));
                                    setChartLoading(false);
                                },500);
                            }}></img></button>                    
                        </div>
                    </div>  

                    { !chart_loading ?
                        <div className='mt-3' style={{backgroundColor:"white", borderRadius:"0.5rem"}}>
                            {   chart_data.length > 0 ?
                                <>
                                    { selected_charts_type === "Cards" ?
                                            <Container className='my-4 p-4'>
                                                <Row className='justify-content-md-left' lg={5} xs={1}>
                                                    {chart_data.map((data, index) => {
                                                        let creative_url = data["creative_url"]
                                                        // let creative_url = `https://storage.googleapis.com/scalex-ai/scaleX_AI_dm-test-store-01/Meta_Creative/default_img.png`;
                                                        // checkIfImageExists(data["creative_url"], (exists) => {
                                                        //     creative_url = data["creative_url"]
                                                        //     if (exists) {
                                                        //         console.log(`${new Date()} - Card Creative Data - ${data["creative_url"]}`)
                                                        //     } else {
                                                        //         console.error(`${new Date()} - Card Creative Data - ${data["creative_url"]} Image does not exists.`)
                                                        //         creative_url = `https://storage.googleapis.com/scalex-ai/scaleX_AI_dm-test-store-01/Meta_Creative/default_img.png`
                                                        //     }
                                                        // })
                                                        return (
                                                        <Col className='py-2'>
                                                            <Card className='creative-analysis-cards' key={index}>                                                
                                                                <Card.Img variant="top" height={150} src={creative_url} />
                                                                <Card.Body>
                                                                    <Card.Text>
                                                                        { ((groupby_filter["value"] === "link") && (data[groupby_filter["value"]] !== undefined) && (data[groupby_filter["value"]] !== null) && (data[groupby_filter["value"]].trim() !== "")) ?
                                                                            <Link to={data[groupby_filter["value"]]} target="_blank" className='mb-1'><on_hover_tooltip title={data[groupby_filter["value"]]}>{(data[groupby_filter["value"]] === undefined) || (data[groupby_filter["value"]] === null) ? "" : data[groupby_filter["value"]].substring(0,25)+"..."}</on_hover_tooltip></Link>
                                                                        :
                                                                            <p className='mb-1'><on_hover_tooltip title={data[groupby_filter["value"]]}>{(data[groupby_filter["value"]] === undefined) || (data[groupby_filter["value"]] === null) ? "" : data[groupby_filter["value"]].substring(0,25)+"..."}</on_hover_tooltip></p>
                                                                        }
                                                                        {all_selected_metrics.map((metric, index) => {
                                                                            if(custom_metrics_arr.includes(metric["label"])){
                                                                                return (
                                                                                    <p className='mb-1'>{metric["label"]} : {(data[metric["label"]] === undefined) || (data[metric["label"]] === null) ? 0: data[metric["label"]].toLocaleString('en-US', {minimumFractionDigits: 0,maximumFractionDigits: 1})+`${metric["label"]==="ROAS"?"":"%"}`}</p>
                                                                                )   
                                                                            }
                                                                            return (
                                                                                <p className='mb-1'>{metric["label"]} : {(data[metric["label"]] === undefined) || (data[metric["label"]] === null) ? 0: data[metric["label"]].toLocaleString('en-US', {minimumFractionDigits: 0,maximumFractionDigits: 1})}</p>
                                                                            )   
                                                                        })}
                                                                    </Card.Text>
                                                                </Card.Body>
                                                            </Card>
                                                        </Col>
                                                    )})}
                                                </Row>
                                            </Container>
                                    :
                                            <>
                                                <Chart
                                                    chartType={selected_charts_type}
                                                    width="100%"
                                                    height="500px"
                                                    data={chart_data}
                                                    options={ selected_charts_type === "LineChart" ? line_charts_config : column_charts_config }
                                                />
                                                { selected_charts_type === "LineChart" &&
                                                    <div className='d-flex justify-content-evenly flex-wrap align-items-center ' style={{width:"80%", margin:"auto", paddingBottom:"4rem"}}>
                                                        {creative_image_id_line_chart.map((iter, index) => {
                                                            let temp_shop_domain = shop_domain.replace(".myshopify.com", "");
                                                            let creative_url = `https://storage.googleapis.com/scalex-ai/scaleX_AI_${temp_shop_domain}/Meta_Creative/${iter["creative_id"][0]}`;
                                                            return (
                                                                <on_hover_tooltip title={iter["group_name"]}>
                                                                    <div style={{border:`3px solid ${default_colors_list[index%5]}`, borderRadius:"0.5rem"}}>
                                                                        <img style={{borderRadius:"0.5rem"}} src={creative_url} alt='Creative' width={50} height={50}/>
                                                                    </div>
                                                                </on_hover_tooltip>
                                                            )
                                                        })}
                                                    </div>
                                                }
                                            </>
                                    }
                                </>
                            :
                                "No data to Show."
                            }
                        </div>  
                        :
                        <div className='mt-3' style={{backgroundColor:"white", borderRadius:"0.5rem", height:"500px", display:"flex", justifyContent:"center", alignItems:"center"}}>
                            <Spinner animation="border" />
                        </div>
                    }

                    { !chart_loading &&
                        <>
                            {   table_chart_data.length > 0 &&
                                <>
                                    <Chart
                                    className="mt-3"
                                    chartType="Table"
                                    width="100%"
                                    data={table_chart_data}
                                    options={{
                                        width: '100%',
                                        curveType: "function",
                                        legend: { position: "bottom" },
                                        pageSize:10,
                                        allowHtml: true,
                                        cssClassNames:{
                                            tableCell :"reports_table_cell",
                                            headerCell :"reports_header_cell",
                                            tableRow :"reports_table_row", 
                                            selectedTableRow  : "reports_selected_table_row",
                                            hoverTableRow : "reports_hover_table_row",
                                            oddTableRow :"reports_table_row" 
                                        }
                                    }}
                                    />
                                </>
                            }
                        </>
                    }
                </Container>
            </>
            :   
            <div className='main_conatiner_spinner'>
                <Spinner animation="border"/>
            </div>
        }
      </div>
    );

}

export default ReportTopPerforming


function get_all_unique_ads(data, group_by){
    let res = alasql(`SELECT * FROM ? WHERE creative_link != "Link Missing"`,[data]);
    let all_unique_img_arr = alasql(`SELECT FIRST(creative_link) as unique_arr FROM ? GROUP BY ${group_by["value"]}`,[res]).map((val) => {
        return val["unique_arr"];
    });
    return all_unique_img_arr;
}


function get_line_charts_data(shop_domain, insight_data, creative_data, metrics, group_by, where_arr){
    let filtered_data = alasql("SELECT * FROM ? AS arr1 JOIN ? AS arr2 ON arr1.ad_id = arr2.ad_id ", [creative_data, insight_data]);
    // let filtered_data = insight_data;
    if(where_arr.length > 0){
        let where_clause = [];
        where_arr.forEach((where_clause_iter) => {
            let or_clauses = [];
            where_clause_iter.forEach((val) => {
                or_clauses.push(`([${val[0]["value"]}] ${val[1]["value"]} '%${val[2].replaceAll("'", "''")}%')`);
            })
            where_clause.push("("+or_clauses.join(" OR ")+")");
        })
        console.log(where_arr)
        console.log(where_clause)
        console.log(`SELECT * FROM ? WHERE (${where_clause.join(" AND ")})`)
        if(where_clause.length > 0){
            filtered_data = alasql(`SELECT * FROM ? WHERE (${where_clause.join(" AND ")})`, [insight_data]);
        }
    }

    let unique_arr_res = alasql(`SELECT ${group_by["value"]}, SUM(CAST(spend as FLOAT)) as total_spend FROM ? GROUP BY ${group_by["value"]} ORDER BY total_spend DESC LIMIT 10`,[filtered_data]);
    let unique_arr = alasql(`SELECT ARRAY(DISTINCT ${group_by["value"]}) as unique_arr FROM ? ORDER BY spend DESC LIMIT 10`,[unique_arr_res])[0]["unique_arr"];
    let unique_ad_id_res = alasql(`SELECT ${group_by["value"]}, ARRAY(DISTINCT ad_id) as ad_ids_arr, SUM(CAST(spend as FLOAT)) as total_spend FROM ? GROUP BY ${group_by["value"]} ORDER BY total_spend DESC LIMIT 10`,[filtered_data]);
    let all_creative_data_res = alasql(`SELECT * FROM ? ORDER BY image_hash `,[creative_data]);
    let all_creative_id_dict = alasql(`SELECT ad_id, ARRAY(DISTINCT creative_id) as creative_id FROM ? GROUP BY ad_id `,[all_creative_data_res]);
    let all_creative_id_arr = [];
    unique_ad_id_res.forEach((val) => {
        let filtered_val = all_creative_id_dict.filter((val_iter) => { return val["ad_ids_arr"].includes(val_iter["ad_id"])});
        if((filtered_val !== undefined) && ((filtered_val !== null)) && (filtered_val.length > 0)){
            all_creative_id_arr.push({"group_name":val[group_by["value"]],"creative_id":filtered_val[0]["creative_id"]});
        }
    })

    let res_tooltip_data = alasql(`SELECT DateToStringFormat(date_start) as Date, ARRAY(DISTINCT ad_id) as ad_id, ${group_by["value"]}, ${metrics.map((val) => { 
        if(custom_metrics_arr.includes(val["label"])) return val["value"]+" AS ["+val["label"]+"]";
        return "SUM(CAST("+val["value"]+" AS FLOAT)) AS "+val["value"]
    })} FROM ? GROUP BY date_start,${group_by["value"]}`,[filtered_data]);

    let res = alasql(`SELECT DateToStringFormat(date_start) as Date, ${group_by["value"]}, ${[metrics[0]].map((val) => { 
        if(custom_metrics_arr.includes(val["label"])) return "COALESCE("+val["value"]+",0) AS ["+val["label"]+"]";
        return "SUM(CAST("+val["value"]+" AS FLOAT)) AS "+val["value"]
    })} FROM ? GROUP BY date_start,${group_by["value"]}`,[filtered_data]);

    let all_unique_dates = [...new Set(res.map(item => item["Date"]))]; 
    let line_formatted_res = [];

    all_unique_dates.forEach((date_iter) => {
        let current_date_data = res.filter((val) => {return val["Date"] === date_iter})
        let row = {"Date":new Date(date_iter)};
        unique_arr.forEach((key) => {
            let current_group_by_data = current_date_data.filter((val) => {return val[group_by["value"]] === key});
            if(current_group_by_data.length > 0) row[key] = current_group_by_data[0][(custom_metrics_arr.includes(metrics[0]["label"])) ? metrics[0]["label"] : metrics[0]["value"]];
            else row[key] = 0;
        })
        line_formatted_res.push(row);
    })
    line_formatted_res = alasql("SELECT * FROM ? ORDER BY Date",[line_formatted_res]);
    console.log(line_formatted_res)

    // let res = alasql(`SELECT DATE(date_start) as Date, ${unique_arr.map((groupby_iter) => {
    //     if(custom_metrics_arr.includes(metrics[0]["label"])) return metrics[0]["value"]+" AS ["+groupby_iter+"]";
    //     return ("SUM(CASE WHEN "+group_by["value"]+" = '"+groupby_iter+"' THEN CAST("+metrics[0]["value"]+" AS FLOAT) ELSE 0 END) AS ["+groupby_iter+"]")
    // }).join(", ")} FROM ? GROUP BY date_start ORDER BY Date DESC`,[filtered_data]);

    unique_arr = unique_arr.map((val) => {return [val,{ role: 'tooltip', type: 'string', p: { html: true } }]}).flat();
    return {creative_ids:all_creative_id_arr,line_chart_data:jsonToArrayObject_for_line_charts(shop_domain, line_formatted_res, [{"type":"date","label":"Date"}, ...unique_arr], metrics, group_by, res_tooltip_data, all_creative_id_dict)};
}


function get_cards_data(shop_domain, insight_data, creative_data, metrics, group_by, where_arr){
    // let filtered_data = insight_data;
    let filtered_data = alasql("SELECT * FROM ? AS arr1 JOIN ? AS arr2 ON arr1.ad_id = arr2.ad_id ", [creative_data, insight_data]);
    if(where_arr.length > 0){
        let where_clause = [];
        where_arr.forEach((where_clause_iter) => {
            let or_clauses = [];
            where_clause_iter.forEach((val) => {
                or_clauses.push(`([${val[0]["value"]}] ${val[1]["value"]} '%${val[2].replaceAll("'", "''")}%')`);
            })
            where_clause.push("("+or_clauses.join(" OR ")+")");
        })
        console.log(where_arr)
        console.log(where_clause)
        console.log(`SELECT * FROM ? WHERE (${where_clause.join(" AND ")})`)
        if(where_clause.length > 0){
            filtered_data = alasql(`SELECT * FROM ? WHERE (${where_clause.join(" AND ")})`, [insight_data]);
        }
    }
    
    let all_creative_data_res = alasql(`SELECT * FROM ? ORDER BY image_hash `,[creative_data]);
    let all_creative_id_dict = alasql(`SELECT ad_id, ARRAY(DISTINCT creative_id) as creative_id FROM ? GROUP BY ad_id `,[all_creative_data_res]);

    let res = alasql(`SELECT ${group_by["value"]}, GET_CREATIVE_URL('${shop_domain}',ARRAY(ad_id),'${JSON.stringify(all_creative_id_dict)}') as creative_url, ${metrics.map((val) => { 
        if(custom_metrics_arr.includes(val["label"])) return "COALESCE("+val["value"]+",0) AS ["+val["label"]+"]"
        return "SUM(CAST("+val["value"]+" AS FLOAT)) AS "+val["label"]
    }).join(", ")} FROM ? GROUP BY ${group_by["value"]} ORDER BY ${metrics[0]["label"]} DESC`,[filtered_data]);
    return res;
}


function get_bar_charts_data(shop_domain, insight_data, creative_data, metrics, group_by, where_arr){
    // let filtered_data = insight_data;
    let filtered_data = alasql("SELECT * FROM ? AS arr1 JOIN ? AS arr2 ON arr1.ad_id = arr2.ad_id ", [creative_data, insight_data]);
    if(where_arr.length > 0){
        let where_clause = [];
        where_arr.forEach((where_clause_iter) => {
            let or_clauses = [];
            where_clause_iter.forEach((val) => {
                or_clauses.push(`([${val[0]["value"]}] ${val[1]["value"]} '%${val[2].replaceAll("'", "''")}%')`);
            })
            where_clause.push("("+or_clauses.join(" OR ")+")");
        })
        console.log(where_arr)
        console.log(where_clause)
        console.log(`SELECT * FROM ? WHERE (${where_clause.join(" AND ")})`)
        if(where_clause.length > 0){
            filtered_data = alasql(`SELECT * FROM ? WHERE (${where_clause.join(" AND ")})`, [insight_data]);
        }
    }
    let res = alasql(`SELECT ${group_by["value"]}, ${metrics.map((val) => { 
        if(custom_metrics_arr.includes(val["label"])) return val["value"]+" AS ["+val["label"]+"]";
        return "SUM(CAST("+val["value"]+" AS FLOAT)) AS ["+val["label"]+"]";
    }).join(", ")} FROM ? GROUP BY ${group_by["value"]} ORDER BY ${metrics[0]["label"]} DESC LIMIT 10`,[filtered_data]);

    let all_creative_data_res = alasql(`SELECT * FROM ? ORDER BY image_hash `,[creative_data]);
    let all_creative_id_dict = alasql(`SELECT ad_id, ARRAY(DISTINCT creative_id) as creative_id FROM ? GROUP BY ad_id `,[all_creative_data_res]);

    let res_tooltip_data = alasql(`SELECT ${group_by["value"]}, DateToStringFormat(date_start) as Date, ARRAY(DISTINCT ad_id) as ad_id, ${metrics.map((val) => { 
        if(custom_metrics_arr.includes(val["label"])) return val["value"]+" AS ["+val["label"]+"]";
        return "SUM(CAST("+val["value"]+" AS FLOAT)) AS ["+val["label"]+"]";
    })} FROM ? GROUP BY ${group_by["value"]} ORDER BY Date`,[filtered_data]);
    let all_metrics = metrics.map((val) => {return val["label"]});
    all_metrics = all_metrics.map((val) => {return [val,{ role: 'tooltip', type: 'string', p: { html: true } }]}).flat();
    return jsonToArrayObject_for_bar_charts(shop_domain, res, [group_by["label"], ...all_metrics], metrics, group_by, res_tooltip_data, all_creative_id_dict);
}


function get_overview_table_data(shop_domain, insight_data, creative_data, metrics, group_by, where_arr){
    // let filtered_data = insight_data;
    let filtered_data = alasql("SELECT * FROM ? AS arr1 JOIN ? AS arr2 ON arr1.ad_id = arr2.ad_id ", [creative_data, insight_data]);
    metrics = [{ value:"clicks", label:"Clicks"},{ value:"impressions", label:"Impressions"},{ value:"spend", label:"Spend"},{ value:"purchase_value", label:"Purchase"},{ value:"SUM(CAST(purchase_value AS FLOAT))/SUM(CAST(spend AS FLOAT))", label:"ROAS"},{ value:"SUM(CAST(spend AS FLOAT))/SUM(CAST(clicks AS FLOAT))*100", label:"CPC"},{ value:"SUM(CAST(clicks AS FLOAT))/SUM(CAST(impressions AS FLOAT))*100", label:"CTR"}];
    if(where_arr.length > 0){
        let where_clause = [];
        where_arr.forEach((where_clause_iter) => {
            let or_clauses = [];
            where_clause_iter.forEach((val) => {
                or_clauses.push(`([${val[0]["value"]}] ${val[1]["value"]} '%${val[2].replaceAll("'", "''")}%')`);
            })
            where_clause.push("("+or_clauses.join(" OR ")+")");
        })
        console.log(where_arr)
        console.log(where_clause)
        console.log(`SELECT * FROM ? WHERE (${where_clause.join(" AND ")})`)
        if(where_clause.length > 0){
            filtered_data = alasql(`SELECT * FROM ? WHERE (${where_clause.join(" AND ")})`, [insight_data]);
        }
    }
    let res_tooltip_data = alasql(`SELECT ad_id, ARRAY(DISTINCT creative_id) as creative_id FROM ? GROUP BY ad_id`,[creative_data]);
    let res = alasql(`SELECT ${group_by["value"]}, COUNT(DISTINCT ad_id) AS ads_count, ARRAY(DISTINCT ad_id) AS ad_id, ${metrics.map((val) => { 
        if(custom_metrics_arr.includes(val["label"])) return val["value"]+" AS ["+val["label"]+"]";
        return "SUM(CAST("+val["value"]+" AS FLOAT)) AS "+val["label"]
    })} FROM ? GROUP BY ${group_by["value"]} ORDER BY Purchase DESC`,[filtered_data]);
    let all_metrics = metrics.map((val) => {return val["label"]});
    return jsonToArrayObject_for_table_charts(shop_domain, res, [group_by["label"], ...all_metrics], metrics, group_by, res_tooltip_data);
}


function jsonToArrayObject_for_table_charts(shop_domain, res, graph_columns, metric=null, group_by=null, tooltip_data=null) {
    shop_domain = shop_domain.replace(".myshopify.com", "");
    var columns = [group_by["value"], ...metric.map((val) => {return val["label"]})];
    var resultArray = res.map(function (obj) {
        var res_arr = [];
        for (let column_name of columns) {
            if(column_name === "creative_url"){
                let creartive_url = `https://storage.googleapis.com/scalex-ai/scaleX_AI_${shop_domain}/Meta_Creative/default_img.png`;
                if(tooltip_data !== null){
                    let temp_res = alasql(`SELECT FIRST(creative_id) as creative_id FROM ? WHERE (${group_by["value"]} = '${obj[group_by["value"]].replaceAll("'","''")}')`,[tooltip_data]);
                    checkIfImageExists(`https://storage.googleapis.com/scalex-ai/scaleX_AI_${shop_domain}/Meta_Creative/${temp_res[0]["creative_id"]}`, (exists) => {
                        if (exists) {
                            creartive_url = `https://storage.googleapis.com/scalex-ai/scaleX_AI_${shop_domain}/Meta_Creative/${temp_res[0]["creative_id"]}`
                        } else {
                          console.error(`https://storage.googleapis.com/scalex-ai/scaleX_AI_${shop_domain}/Meta_Creative/${temp_res[0]["creative_id"]} Image does not exists.`)
                        }
                    })
                }
                res_arr.push('<img src="'+creartive_url+'" width="50" height="50" />');
            }
            if(column_name === group_by["value"]){
                res_arr.push(`
                    <p>${obj[column_name]}</p>
                    <b>${obj["ads_count"]} ads</b>
                `);
                // res_arr.push(`
                //     <div>
                //         <div>
                //             <img src="${}" alt="preview">
                //         </div>
                //         <div>
                //             <p>${obj[column_name]}</p>
                //             <b>${obj["ads_count"]} ads</b>
                //         </div>
                //     </div>
                // `);
            }
            else{
                res_arr.push(obj[column_name]);
            }
      }
      return res_arr;
    });
    resultArray.unshift(graph_columns)
   return resultArray;
}


function checkIfImageExists(url, callback) {
    const img = new Image();
    img.src = url;
    
    if (img.complete) {
      callback(true);
    } else {
      img.onload = () => {
        callback(true);
      };
      
      img.onerror = () => {
        callback(false);
      };
    }
}


function jsonToArrayObject_for_bar_charts(shop_domain, res, graph_columns, metric=null, group_by=null, tooltip_data=null, creative_ad_id_mapping=null) {
    shop_domain = shop_domain.replace(".myshopify.com", "");
    var columns = Object.keys(res[0]);
    var resultArray = res.map(function (obj) {
      var res_arr = [];
      var col_num = 0;
      for (let column_name of columns) {
        if(col_num > 0){
            res_arr.push(obj[column_name]);
            if(tooltip_data !== null){
                let temp_res = alasql(`SELECT ARRAY(ad_id) as ad_id FROM ? WHERE ((Date = '${formatDate(obj["Date"])}') AND (${group_by["value"]} = '${obj[group_by["value"]].replaceAll("'", "''")}'))`,[tooltip_data]);
                
                let selected_ad_creative_mapping = creative_ad_id_mapping.filter((map_iter) => { return  temp_res[0]["ad_id"].flat().includes(map_iter["ad_id"])});
                let creative_id = selected_ad_creative_mapping.length > 0 ?  selected_ad_creative_mapping[0]["creative_id"][0] : "default_img.png";

                res_arr.push(`
                    <div style="background-color:white; padding:10px; width:fit-content; border-radius:5px; box-shadow:0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); text-align:left;">
                        <img src="https://storage.googleapis.com/scalex-ai/scaleX_AI_${shop_domain}/Meta_Creative/${creative_id}" height=100 alt="creative">
                        <p style='margin:3px; font-size:medium; width:fit-content;'>${column_name}: ${(obj[column_name] === undefined) || (obj[column_name] === null) ? 0 : obj[column_name].toLocaleString('en-US', {minimumFractionDigits: 0,maximumFractionDigits: 1})}</p>
                    </div>
                `);
            }
        }
        else{
            res_arr.push(obj[column_name]);
        }
        col_num ++;
      }
      return res_arr;
    });
    resultArray.unshift(graph_columns)
   return resultArray;
}


function jsonToArrayObject_for_line_charts(shop_domain,res, graph_columns, metric=null, group_by=null, tooltip_data=null, creative_ad_id_mapping=null) {
    shop_domain = shop_domain.replace(".myshopify.com", "");
    var columns = Object.keys(res[0]);
    var resultArray = res.map(function (obj) {
      var res_arr = [];
      for (let column_name of columns) {
        if(column_name !== "Date"){
            res_arr.push(obj[column_name]);
            if((tooltip_data !== null) && (metric !== null) && (metric.length > 0)){
                let temp_res = alasql(`SELECT ARRAY(ad_id) as ad_id, ${metric.map((metrics_iter) => {
                    if(custom_metrics_arr.includes(metrics_iter["label"])) return "COALESCE(FIRST("+metrics_iter["label"]+"),0) AS ["+metrics_iter["label"]+"]"
                    return "SUM(CAST("+metrics_iter["value"]+" AS FLOAT)) AS "+metrics_iter["value"]
                }).join(", ")} FROM ? WHERE ((Date = '${formatDate(obj["Date"])}') AND (${group_by["value"]} = '${column_name.replaceAll("'", "''")}'))`,[tooltip_data])
                console.log(formatDate(obj["Date"]))
                console.log(column_name)
                if((temp_res.length === 0) || (temp_res[0]["ad_id"] === undefined) || (temp_res[0]["ad_id"] === null) || (temp_res[0]["ad_id"].length === 0)){
                    res_arr.push(``)
                    continue;
                }
                let selected_ad_creative_mapping = creative_ad_id_mapping.filter((map_iter) => { return  temp_res[0]["ad_id"].flat().includes(map_iter["ad_id"])});
                let creative_id = selected_ad_creative_mapping.length > 0 ?  selected_ad_creative_mapping[0]["creative_id"][0] : "default_img.png";
                res_arr.push(`
                    <div style="background-color:white; padding:20px; width:fit-content; border-radius:5px; box-shadow:0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); text-align:left;">
                        <img src="https://storage.googleapis.com/scalex-ai/scaleX_AI_${shop_domain}/Meta_Creative/${creative_id}" height=100 alt="creative">
                        <p style="margin:3px; font-size:medium;">Date: ${formatDate(obj["Date"])}</b></p>
                        ${metric.map((val) => {
                            if(custom_metrics_arr.includes(val["label"])) return "<p style='margin:3px; font-size:medium;'>"+val["label"]+": "+temp_res[0][val["label"]].toLocaleString('en-US', {minimumFractionDigits: 0,maximumFractionDigits: 1}) + (val["label"] === "ROAS"? "" : "%") +"</p>"
                            return "<p style='margin:3px; font-size:medium;'>"+val["label"]+": "+temp_res[0][val["value"]].toLocaleString('en-US', {minimumFractionDigits: 0,maximumFractionDigits: 1}) +"</p>"
                        }).join("")}
                    </div>
                `);
            }
        }
        else{
            res_arr.push(obj[column_name]);
        }
      }
      return res_arr;
    });
    resultArray.unshift(graph_columns)
   return resultArray;
}


function get_filter_date_from_data(data){
    let min_max_date_dict = alasql(`SELECT MIN(DATE(date_start)) as min_date, MAX(DATE(date_start)) as max_date FROM ?`,[data])[0];
    return [min_max_date_dict["min_date"], min_max_date_dict["max_date"]];
}

function formatDate(date, is_increase_date=false) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + (is_increase_date? d.getDate() + 1: d.getDate()),
      year = d.getFullYear();
  
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
  
    return [year, month, day].join('-');
}