import React, { useState, useEffect } from 'react'
import { useParams, useLocation } from 'react-router-dom';
import { Chart } from "react-google-charts";
import Spinner from 'react-bootstrap/Spinner';
import Container from 'react-bootstrap/Container';
import Label from 'react-bootstrap/FormLabel';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import ReMultiSelect from 'react-select';
import ReMultiSelectAnimated from 'react-select/animated';
import { DateRangePicker } from "rsuite";
import {showToast as toast} from '../toastManager';
import line_chart from '../images/chart.png';
import column_chart from '../images/column.png';
import cards_logo from '../images/collection.png';
import Cookies from "js-cookie";
import axios from 'axios';
import alasql from 'alasql';
import { FormControl } from 'react-bootstrap';

alasql.fn.DateToStringFormat = function (date){
    return formatDate(date);
}


const custom_metrics_arr = ["ROAS","CTR","CPC"]

function ReportComparativeAnalysis({ViewedPage, shop_domain, app_url}) {
    ViewedPage(18);

    const { combine, before, afterToday } = DateRangePicker;
    const max_date = new Date();
    max_date.setDate(max_date.getDate() - 365);

    const { report_id } = useParams();
    const location = useLocation();
    const saved_report_settings = location.state;

    const [filter_date, setFilterDate] = useState([]);
    const [groupby_filter, setGroupByFilter] = useState(saved_report_settings["groupby"]);
    const [current_selected_ads_level, setCurrentSelectedAdsLevel] = useState({ value: 'campaign_name', label: 'Campaign name' });
    const [current_selected_ads_level_operator, setCurrentSelectedAdsLevelOperator] = useState({ value: 'LIKE', label: 'contains' });
    const [current_selected_ads_level_input_value, setCurrentSelectedAdsLevelInputValue] = useState("");
    const [current_selected_filters_data, setCurrentSelectedFilterData] = useState([]);
    const [comparative_current_group_name, setComparativeCurrentGroupName] = useState("");
    const [all_filters_data, setAllFiltersData] = useState([]);
    const [creative_image_id_line_chart, setCreativeImageIDLineChart] = useState([]);
    const [all_comparative_groups_of_ad, setAllComparativeGroupsOfAd] = useState(saved_report_settings["group_of_ads"]);
    const [all_creative_data, setCreativeData] = useState([]);
    const [all_insights_data, setInsightsData] = useState([]);
    const [metrics_default_value, setMetricsDefaultValue] = useState({"value":"Add Metrics","label": "Add Metrics"})
    const [all_metrics, setAllMetrics] = useState([
        { value:"clicks", label:"Clicks"},
        { value:"impressions", label:"Impressions"},
        { value:"spend", label:"Spend"},
        { value:"purchase_value", label:"Purchase"},
        { value:"SUM(CAST(purchase_value AS FLOAT))/SUM(CAST(spend AS FLOAT))", label:"ROAS"},
        { value:"SUM(CAST(spend AS FLOAT))/SUM(CAST(clicks AS FLOAT))*100", label:"CPC"},
        { value:"SUM(CAST(clicks AS FLOAT))/SUM(CAST(impressions AS FLOAT))*100", label:"CTR"},
        { value:"video_view", label:"3 sec video play"},
        { value:"video_15_sec_watched_actions_video_view", label:"15 sec video play"},
        { value:"video_30_sec_watched_actions_video_view", label:"30 sec video play"},
        { value:"video_p25_watched_actions_video_view", label:"25% video play"},
        { value:"video_p50_watched_actions_video_view", label:"50% video play"},
        { value:"video_p75_watched_actions_video_view", label:"75% video play"},
        { value:"video_p95_watched_actions_video_view", label:"95% video play"},
        { value:"video_p100_watched_actions_video_view", label:"100% video play"},
        { value:"video_thruplay_watched_actions_video_view", label:"Thruplay"},
        { value:"unique_video_continuous_2_sec_watched_actions_video_view", label:"Unique 2 sec continuous video play"},
    ]);
    const [all_selected_metrics, setAllSelectedMetrics] = useState(saved_report_settings["metrics"]);
    const [show_group_by_filter, setShowGroupByFilter] = useState(false);
    const [show_comparative_groups_popup, setShowComparativeGroupsPopup] = useState(false);
    const [loading, setLoading] = useState(true);
    const [chart_loading, setChartLoading] = useState(false);
    const [report_save_loading, setReportSaveLoading] = useState(false);
    
    const [selected_charts_type, setSelectedChartsType] = useState("LineChart");
    const [chart_data, setChartData] = useState([]);
    const [table_chart_data, setTableChartData] = useState([]);
    const [unique_ads_value_of_groupby, setAllUniqueAdsValueOfGroupBY] = useState([]);
    const [report_settings, setReportSettings] = useState(saved_report_settings);

    const number_of_metrics_allowed = {
        "LineChart":2,
        "ColumnChart":4,
        "Cards":6,
    };

    useEffect(() => {
        console.log("In Report",shop_domain)
        setLoading(true);
        try {
            if(Cookies.get('shop')){
                // Facebook Loader data
                var created_at_max = new Date();
                var created_at_min = new Date(new Date().setDate(created_at_max.getDate() - 15));
                setFilterDate([created_at_min, created_at_max]);
                axios.post(app_url+"/api/facebook/get_meta_creatives_data_from_redis",{
                    shop_domain:Cookies.get('shop'),
                    date_start:formatDate(created_at_min),
                    date_end:formatDate(created_at_max)
                }, {withCredentials:true})
                .then(res => res.data)
                .then(data => {
                    console.log(data)
                    setCreativeData(data["data"]["creative_data"]);
                    setInsightsData(data["data"]["insights_data"]);
                    setFilterDate(get_filter_date_from_data(data["data"]["insights_data"]));
                    if(saved_report_settings["group_of_ads"].length > 0){
                        let {creative_ids, line_chart_data}= get_line_charts_data(shop_domain, data["data"]["insights_data"], data["data"]["creative_data"], saved_report_settings["metrics"], saved_report_settings["group_of_ads"], saved_report_settings["filters"])
                        // setCreativeImageIDLineChart(creative_ids)
                        setChartData(line_chart_data)
                        setTableChartData(get_overview_table_data(shop_domain, data["data"]["insights_data"], data["data"]["creative_data"], saved_report_settings["metrics"], saved_report_settings["group_of_ads"], saved_report_settings["filters"]))
                    }
                    // setAllUniqueAdsValueOfGroupBY(get_all_unique_ads(data["data"]["creative_data"], {"value":"ad_name", "label":"Ad name"}))
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                    toast.error("Please try after some time.");
                    console.log(err);
                })
            }
        } catch (error) {
            setLoading(false)
            toast.error("Please try after some time.");
            console.log(error);
        }

    }, [app_url, shop_domain, saved_report_settings]);

    const default_colors_list = ["#ca1500","#d88300","#46a400","#008ba4","#7c00a4"];

    const line_charts_config = {
        chartArea: {'width': '80%', 'height': '70%', 'top':'10%'},
        titleTextStyle : {fontSize: 16},
        curveType: 'function',
        hAxis: { 
            format: 'MMM d',
            gridlines: {
                color: 'transparent',
            },
        },
        vAxis: {
            format: "short",
            gridlines: {
                color: '#edf1f4',
            },
            viewWindow : { min:0 },
        },
        // vAxes: {
        //     // Adds titles to each axis.
        //     0: { title: 'Clicks' },
        //     1: { title: 'Spend' },
        // },
        legend: "none",
        tooltip: { isHtml: true },
        colors:default_colors_list,
        animation: { startup: true, easing: "linear", duration: 1000 },
    }
    
    const column_charts_config = {
        hAxis: { 
            format: 'MMM d, y',
            gridlines: {
                color: 'transparent',
            },
        },
        series:{
            0:{ targetAxisIndex : 0},
            1:{ targetAxisIndex : 1},
        },
        // vAxes: {
        //     // Adds titles to each axis.
        //     0: { title: 'Clicks' },
        //     1: { title: 'Spend' },
        // },
        vAxis: {
            format: "short",
            gridlines: {
                color: '#edf1f4',
            },
        },
        tooltip: { isHtml: true },
        animation: { startup: true, easing: "linear", duration: 1000 },
    }
    
    const handle_group_filters_hide = () => {
        let temp_all_filter_data = [all_filters_data, [current_selected_filters_data]].flat();
        setAllFiltersData(temp_all_filter_data);
        if(selected_charts_type === "LineChart"){
            let {creative_ids, line_chart_data}= get_line_charts_data(shop_domain, all_insights_data, all_creative_data, all_selected_metrics, all_comparative_groups_of_ad, temp_all_filter_data);
            setCreativeImageIDLineChart(creative_ids);
            setChartData(line_chart_data);
        }
        else if(selected_charts_type === "ColumnChart"){
            setChartData(get_bar_charts_data(shop_domain, all_insights_data, all_creative_data, all_selected_metrics, all_comparative_groups_of_ad, temp_all_filter_data));
        }
        setTableChartData(get_overview_table_data(shop_domain, all_insights_data, all_creative_data, all_selected_metrics, all_comparative_groups_of_ad, temp_all_filter_data));
        setCurrentSelectedFilterData([]);
        setShowGroupByFilter(false)
    };
    const handle_group_filters_show = () => setShowGroupByFilter(true);
    
    
    const handle_comparative_group_filters_hide = () => {
        let temp_curr_add_group = {}
        setShowComparativeGroupsPopup(false)
        if(current_selected_filters_data.length === 0){
            setShowComparativeGroupsPopup(false)
        }
        else{
            temp_curr_add_group[comparative_current_group_name] = current_selected_filters_data
            let temp_all_comparative_groups_of_ad = [all_comparative_groups_of_ad, temp_curr_add_group].flat();
            console.log("----------------------------")
            console.log(temp_curr_add_group)
            console.log(temp_all_comparative_groups_of_ad)
            console.log("----------------------------")
            setAllComparativeGroupsOfAd(temp_all_comparative_groups_of_ad);
            if(selected_charts_type === "LineChart"){
                let {creative_ids, line_chart_data}= get_line_charts_data(shop_domain, all_insights_data, all_creative_data, all_selected_metrics, temp_all_comparative_groups_of_ad, all_filters_data);
                setCreativeImageIDLineChart(creative_ids);
                setChartData(line_chart_data);
            }
            else if(selected_charts_type === "ColumnChart"){
                setChartData(get_bar_charts_data(shop_domain, all_insights_data, all_creative_data, all_selected_metrics, temp_all_comparative_groups_of_ad, all_filters_data));
            }
            setTableChartData(get_overview_table_data(shop_domain, all_insights_data, all_creative_data, all_selected_metrics, temp_all_comparative_groups_of_ad, all_filters_data));
            setCurrentSelectedFilterData([]);
            setShowComparativeGroupsPopup(false)
        }
    };
    const handle_comparative_groups_show = () => setShowComparativeGroupsPopup(true);


    const handleDateChange = async (date_range) => {
        setChartLoading(true)
        if((date_range !== undefined) && (date_range !== null) && (date_range.length > 0)){
            setFilterDate(date_range);
            axios.post(app_url+"/api/facebook/get_meta_creatives_data",{
                shop_domain:Cookies.get('shop'),
                date_start:formatDate(date_range[0]),
                date_end:formatDate(date_range[1])
            }, {withCredentials:true})
            .then(res => res.data)
            .then(data => {
                console.log(data)
                setCreativeData(data["data"]["creative_data"]);
                setInsightsData(data["data"]["insights_data"]);
                    if(selected_charts_type === "LineChart"){
                        let {creative_ids, line_chart_data}= get_line_charts_data(shop_domain, data["data"]["insights_data"], data["data"]["creative_data"], all_selected_metrics, all_comparative_groups_of_ad, all_filters_data);
                        setCreativeImageIDLineChart(creative_ids);
                        setChartData(line_chart_data);
                    }
                    else if(selected_charts_type === "ColumnChart"){
                        setChartData(get_bar_charts_data(shop_domain, data["data"]["insights_data"], data["data"]["creative_data"], all_selected_metrics, all_comparative_groups_of_ad, all_filters_data))
                    }
                    setTableChartData(get_overview_table_data(shop_domain, data["data"]["insights_data"], data["data"]["creative_data"], all_selected_metrics, all_comparative_groups_of_ad, all_filters_data))
                setAllUniqueAdsValueOfGroupBY(get_all_unique_ads(data["data"]["creative_data"], {"value":"ad_name", "label":"Ad name"}))
                setChartLoading(false)
            })
            .catch(err => {
                setChartLoading(false)
                toast.error("Please try after some time.");
                console.log(err);
            })
        }
        else{
            setFilterDate([]);
        }
    }


    const handle_save_reports_settings = (e) => {
        setReportSaveLoading(true);
        let temp_report_settings = report_settings;
        temp_report_settings["start_date"] = formatDate(filter_date[0]);
        temp_report_settings["end_date"] = formatDate(filter_date[1]);
        temp_report_settings["filters"] = all_filters_data;
        temp_report_settings["metrics"] = all_selected_metrics;
        temp_report_settings["groupby"] = groupby_filter;
        temp_report_settings["group_of_ads"] = all_comparative_groups_of_ad;
        console.log(temp_report_settings)
        setReportSettings(temp_report_settings);
        axios.post(app_url+"/api/facebook/save_meta_reports_settings",{
            shop_domain:Cookies.get('shop'),
            id:report_id,
            reports_settings:temp_report_settings
        }, {withCredentials:true})
        .then(res => res.data)
        .then(data => {
            setReportSaveLoading(false);
            console.log(data)
            toast.success("Saved successfully.");
        })
        .catch(err => {
            setReportSaveLoading(false);
            console.log(err);
            toast.error("Please try after some time.");
        })
    }

    return (
    <div className='main-container'>
        { !loading ?
            <>
                <Modal size='lg' show={show_comparative_groups_popup} onHide={handle_comparative_group_filters_hide}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Group</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{height:"300px", overflowY:"scroll"}}>
                        <Container className='my-4'>
                            <Row className='justify-content-md-left' lg={1} xs={1}>
                                <Col className='py-2'>
                                    <Form.Control style={{display:"inline-block"}} type="text" placeholder="Group name" onChange={(e) => {
                                        e.preventDefault();
                                        setComparativeCurrentGroupName(e.target.value);
                                    }}/>
                                </Col>
                            </Row>
                            <Row className='justify-content-md-left' lg={4} xs={1}>
                                <Col className='py-2'>
                                    <ReMultiSelect 
                                        id="x_axis"
                                        closeMenuOnSelect={false}
                                        components={ReMultiSelectAnimated()}
                                        defaultValue={current_selected_ads_level}
                                        options={[
                                            { value: 'campaign_name', label: 'Campaign name' },
                                            { value: 'adset_name', label: 'Adset name' },
                                            { value: 'ad_name', label: 'Ad name' },
                                            { value: 'ad_type', label: 'Ad Type' },
                                        ]}
                                        onChange={(selected_vals) => {
                                            setCurrentSelectedAdsLevel(selected_vals);
                                        }}
                                    />
                                </Col>
                                <Col className='py-2'>
                                    <ReMultiSelect 
                                        id="x_axis"
                                        closeMenuOnSelect={false}
                                        components={ReMultiSelectAnimated()}
                                        defaultValue={current_selected_ads_level_operator}
                                        options={[
                                            { value: 'LIKE', label: 'contains' },
                                            { value: 'NOT LIKE', label: "doesn't contain" },
                                        ]}
                                        onChange={(selected_vals) => {
                                            setCurrentSelectedAdsLevelOperator(selected_vals);
                                        }}
                                    />
                                </Col>
                                <Col className='py-2'>
                                    <Form.Control type="text" placeholder="Group Name" value={current_selected_ads_level_input_value} onChange={(e) => {
                                        setCurrentSelectedAdsLevelInputValue(e.target.value);
                                    }} />
                                </Col>
                                <Col className='py-2'>
                                    <Button variant='primary' onClick={(e) => {
                                        e.preventDefault();
                                        let temp_current_selected_ads_level_input_value = current_selected_ads_level_input_value;
                                        if((current_selected_ads_level_input_value === null) || (current_selected_ads_level_input_value === undefined) || (current_selected_ads_level_input_value.trim() === "")){
                                            temp_current_selected_ads_level_input_value = "";
                                        }
                                        let temp_current_selected_filters_data = [current_selected_filters_data, [[current_selected_ads_level, current_selected_ads_level_operator, temp_current_selected_ads_level_input_value]]].flat();
                                        setCurrentSelectedFilterData(temp_current_selected_filters_data);
                                    }} className='w-100'>Add</Button>
                                </Col>
                            </Row>

                            <Row className='justify-content-md-left' lg={1} xs={1}>
                                <Col className='py-4'>
                                    <div>
                                        {current_selected_filters_data.map((val, idx) => {
                                            return (
                                                <p style={{margin:"5px", backgroundColor:"#edefff", padding:"0.5rem", borderRadius:"5px", display:"inline-block"}}>
                                                    {val[0]["label"]} {val[1]["label"]} {val[2]} 
                                                    <button style={{backgroundColor:"#edefff", border:"none", padding:"0px 5px 0px 10px", marginLeft:"5px", borderLeft:"1px solid #ccc"}} onClick={(e) => {
                                                        e.preventDefault();

                                                        let temp_current_selected_filters_data = current_selected_filters_data.filter((all_val,all_idx) => { return all_idx !== idx })
                                                        setCurrentSelectedFilterData(temp_current_selected_filters_data);
                                                    }}>
                                                        &#10005;
                                                    </button>
                                                </p> 
                                            );
                                        })}
                                    </div>
                                    <Button className='mt-3' onClick={(e) => {
                                        e.preventDefault();
                                        handle_comparative_group_filters_hide();
                                    }}>Apply</Button>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                </Modal>

                <div className='d-flex justify-content-between px-3 my-4'>
                    
                    <div style={{width:"80%"}}>
                        <input id="report_name_input" type="text" placeholder="Report Name..." value={(report_settings["name"] !== null) || (report_settings["name"] !== undefined) || (report_settings["name"].strip() !== "") ? report_settings["name"] : "Report Name..."} onChange={(e) => { 
                            let temp_report_settings = {...report_settings};
                            temp_report_settings["name"] = e.target.value;
                            setReportSettings(temp_report_settings);
                        }}></input>
                        <br/>
                        <br/>
                        <textarea id="report_detail_input" type="text" placeholder="Detail..." value={(report_settings["detail"] !== null) || (report_settings["detail"] !== undefined) || (report_settings["detail"].strip() !== "") ? report_settings["detail"] : "Detail..."} onChange={(e) => { 
                            let temp_report_settings = {...report_settings};
                            temp_report_settings["detail"] = e.target.value;
                            setReportSettings(temp_report_settings);
                        }}></textarea>
                        
                    </div>
                    <div style={{width:"15%", marginRight:"0.5rem", marginTop:"0.8rem", textAlign:"right"}}>
                        {report_save_loading ?
                            <Button style={{backgroundColor:"white", color:"#00d96d", border:"none"}} ><Spinner animation="border" /></Button>
                            :
                            <Button style={{backgroundColor:"white", color:"#00d96d", border:"none"}} onClick={handle_save_reports_settings}>Save</Button>
                        }
                    </div>

                </div>

                
                <Container className='my-4'>
                    <Row className='justify-content-md-left' lg={3} xs={1}>
                        <Col className='p-2'>
                            <Label className="charts_filter_label">Time Period</Label>
                            <br />
                            <DateRangePicker shouldDisableDate={combine(before(max_date), afterToday())} editable={false} defaultValue={filter_date} id='date_range' format="yyyy-MM-dd" character=" - " showOneCalendar block onChange={handleDateChange} />
                        </Col>
                        {/* <Col className='p-2'>
                            <Label className="charts_filter_label">Group by</Label>
                            <ReMultiSelect 
                                id="x_axis"
                                closeMenuOnSelect={false}
                                components={ReMultiSelectAnimated()}
                                defaultValue={groupby_filter}
                                options={[
                                    { value: 'ad_name', label: 'Ad name' },
                                    { value: 'title', label: 'Headline' },
                                    { value: 'link', label: 'Landing Page' }
                                ]}
                                onChange={(selected_vals) => {
                                    setGroupByFilter(selected_vals);
                                }}
                            />
                        </Col> */}
                    </Row>
                </Container>

                <div className='p-3 mx-2' style={{backgroundColor:"white", borderRadius:"0.5rem", padding:"1rem"}}>
                    <div className='d-flex align-items-center justify-content-between'>
                        <p className='mb-0'>Define groups of ads to compare.</p>
                        <Button className='d-flex align-items-center justify-content-center' style={{backgroundColor:"white",color:"#00d96d",border:"none",boxShadow:"0 2px 6px rgba(71,86,102,.05)",fontWeight:'500'}} onClick={handle_comparative_groups_show}>
                        &#x2719; Add group
                        </Button>
                    </div>
                    <hr />
                    {all_comparative_groups_of_ad.length > 0 && 
                        <div className='d-flex flex-wrap'>
                            {all_comparative_groups_of_ad.map((filter_iter, filter_iter_idx) => {
                                let temp_current_selected_group_name = Object.keys(filter_iter)[0];
                                return (
                                    <div style={{minWidth:"10rem", textAlign:"center", maxWidth:"fit-content", border:"1px solid rgb(179 173 173)", borderRadius:"0.5rem", margin:"0.5rem", cursor:"pointer", padding:"1rem"}} onClick={(e) => {
                                        e.preventDefault();
                                        setComparativeCurrentGroupName(temp_current_selected_group_name);
                                        setCurrentSelectedFilterData(filter_iter[temp_current_selected_group_name]);
                                        setCurrentSelectedAdsLevel({ value: 'campaign_name', label: 'Campaign name' });
                                        setCurrentSelectedAdsLevelInputValue("");
                                        let temp_all_comparative_groups_of_ad = all_comparative_groups_of_ad.filter((all_val,all_idx) => { return all_idx !== filter_iter_idx });
                                        setAllComparativeGroupsOfAd(temp_all_comparative_groups_of_ad);
                                        setShowComparativeGroupsPopup(true);
                                    }}>
                                        Group Name - <b>{temp_current_selected_group_name}</b>
                                    </div>
                                );
                            })}
                        </div>
                    }
                </div>

                <Container className='my-4'>
                    <div className='d-flex justify-content-between p-4 mt-2' style={{backgroundColor:"white", borderRadius:"0.5rem"}} >
                        <div style={{display:"flex", flexWrap:"wrap",width:"60%"}}>
                            { all_selected_metrics.map((val) => {
                                return (
                                    <div style={{border:"1px solid black", padding:"6px 10px", borderRadius:"5px", display:"inline-block", cursor:"pointer", margin:"0.5rem", marginTop:"0px", height:"fit-content"}}>
                                        <p style={{display:"inline-block",marginBottom:"0"}}>{val["label"]}</p>
                                        <button style={{backgroundColor:"white", border:"none", padding:"0px 5px 0px 10px"}} onClick={(e) => {
                                            e.preventDefault();
                                            setChartLoading(true)
                                            setTimeout(() => {
                                                let temp_all_selected_metrics = all_selected_metrics.filter((metric_val) => { return metric_val !== val })
                                                setAllSelectedMetrics(temp_all_selected_metrics);
                                                if(selected_charts_type === "LineChart"){
                                                    let {creative_ids, line_chart_data}= get_line_charts_data(shop_domain, all_insights_data, all_creative_data, temp_all_selected_metrics, all_comparative_groups_of_ad, all_filters_data);
                                                    setCreativeImageIDLineChart(creative_ids);
                                                    setChartData(line_chart_data);
                                                }
                                                else if(selected_charts_type === "ColumnChart"){
                                                    setChartData(get_bar_charts_data(shop_domain, all_insights_data, all_creative_data, temp_all_selected_metrics, all_comparative_groups_of_ad, all_filters_data));
                                                }
                                                setTableChartData(get_overview_table_data(shop_domain, all_insights_data, all_creative_data, temp_all_selected_metrics, all_comparative_groups_of_ad, all_filters_data));
                                                setChartLoading(false)
                                            }, 500)
                                        }}>
                                            &#10005;
                                        </button>
                                    </div>
                                )
                            })}
                            <div style={{marginLeft:"0.5rem"}}>
                                <ReMultiSelect 
                                    id="scattered_tags_value"
                                    closeMenuOnSelect={false}
                                    components={ReMultiSelectAnimated()}
                                    defaultValue={metrics_default_value}
                                    options={all_metrics}
                                    onChange={(selected_vals) => {
                                        setChartLoading(true);
                                        setTimeout(() => {
                                            setMetricsDefaultValue({"value":"Add Metrics","label": "Add Metrics"})
                                            let temp_all_selected_metrics = [all_selected_metrics].flat();
                                            if(!temp_all_selected_metrics.includes(selected_vals)){
                                                if(temp_all_selected_metrics.length < number_of_metrics_allowed[selected_charts_type]){
                                                    temp_all_selected_metrics.push(selected_vals);
                                                    setAllSelectedMetrics(temp_all_selected_metrics);
                                                    if(selected_charts_type === "LineChart"){
                                                        let {creative_ids, line_chart_data}= get_line_charts_data(shop_domain, all_insights_data, all_creative_data, temp_all_selected_metrics, all_comparative_groups_of_ad, all_filters_data);
                                                        setCreativeImageIDLineChart(creative_ids);
                                                        setChartData(line_chart_data);
                                                    }
                                                    else if(selected_charts_type === "ColumnChart"){
                                                        setChartData(get_bar_charts_data(shop_domain, all_insights_data, all_creative_data, temp_all_selected_metrics, all_comparative_groups_of_ad, all_filters_data));
                                                    }
                                                    setTableChartData(get_overview_table_data(shop_domain, all_insights_data, all_creative_data, temp_all_selected_metrics, all_comparative_groups_of_ad, all_filters_data));
                                                }
                                                else{
                                                    toast.error("Number of selected metrics should be less than or equal to "+number_of_metrics_allowed[selected_charts_type]);
                                                }
                                            }
                                            setChartLoading(false);
                                        }, 500);
                                    }}
                                />
                            </div>
                        </div>
                        <div style={{width:"fit-content", display:"flex", alignItems:"center", justifyContent:"space-evenly", backgroundColor:"rgb(246 246 246)", border:"1px solid #aba9a9", borderRadius:"0.5rem", height:"fit-content"}}>
                            <button style={{backgroundColor:selected_charts_type === "LineChart"?"#fff":"transparent", border:selected_charts_type === "LineChart"?"1px solid #aba9a9":"none", padding:"5px", borderRadius:"0.5rem"}}><img width={20} src={line_chart} alt='Line' onClick={(e) => {
                                e.preventDefault();
                                setChartLoading(true);
                                setTimeout(() => {
                                    setSelectedChartsType("LineChart");
                                    let temp_all_selected_metrics = [all_selected_metrics].flat();
                                    temp_all_selected_metrics = temp_all_selected_metrics.slice(0, number_of_metrics_allowed[selected_charts_type]);
                                    setAllSelectedMetrics(temp_all_selected_metrics);
                                    let {creative_ids, line_chart_data}= get_line_charts_data(shop_domain, all_insights_data, all_creative_data, all_selected_metrics, all_comparative_groups_of_ad, all_filters_data);
                                    setCreativeImageIDLineChart(creative_ids);
                                    setChartData(line_chart_data);
                                    setChartLoading(false);
                                },500)
                            }}></img></button>                    
                            <button style={{backgroundColor:selected_charts_type === "ColumnChart"?"#fff":"transparent", border:selected_charts_type === "ColumnChart"?"1px solid #aba9a9":"none", padding:"5px", borderRadius:"0.5rem"}}><img width={20} src={column_chart} alt='Bar' onClick={(e) => {
                                e.preventDefault();
                                setChartLoading(true);
                                setTimeout(() => {
                                    setSelectedChartsType("ColumnChart");
                                    let temp_all_selected_metrics = [all_selected_metrics].flat();
                                    temp_all_selected_metrics = temp_all_selected_metrics.slice(0, number_of_metrics_allowed[selected_charts_type]);
                                    setAllSelectedMetrics(temp_all_selected_metrics);
                                    setChartData(get_bar_charts_data(shop_domain, all_insights_data, all_creative_data, all_selected_metrics, all_comparative_groups_of_ad, all_filters_data));
                                    setChartLoading(false);
                                },500);
                            }}></img></button>                    
                        </div>
                    </div>

                    {all_comparative_groups_of_ad.length > 0 && 
                        <>
                            { !chart_loading ?
                                <div className='mt-3' style={{backgroundColor:"white", borderRadius:"0.5rem"}}>
                                    {   chart_data.length > 0 ?
                                        <>
                                            <Chart
                                                chartType={selected_charts_type}
                                                width="100%"
                                                height="500px"
                                                data={chart_data}
                                                options={ selected_charts_type === "LineChart" ? line_charts_config : column_charts_config }
                                            />
                                        </>
                                        :
                                        "No data to show."
                                    }
                                </div>
                                :
                                <div className='mt-3' style={{backgroundColor:"white", borderRadius:"0.5rem", height:"500px", display:"flex", justifyContent:"center", alignItems:"center"}}>
                                    <Spinner animation="border" />
                                </div>
                            }

                            { !chart_loading &&
                                <>
                                    {   table_chart_data.length > 0 &&
                                        <>
                                            <Chart
                                            className="mt-3"
                                            chartType="Table"
                                            width="100%"
                                            data={table_chart_data}
                                            options={{
                                                width: '100%',
                                                curveType: "function",
                                                legend: { position: "bottom" },
                                                pageSize:5,
                                                allowHtml: true,
                                                cssClassNames:{
                                                    tableCell :"ca_reports_table_cell",
                                                    headerCell :"ca_reports_header_cell",
                                                    tableRow :"ca_reports_table_row", 
                                                    selectedTableRow  : "ca_reports_selected_table_row",
                                                    hoverTableRow : "ca_reports_hover_table_row",
                                                    oddTableRow :"ca_reports_table_row" 
                                                }
                                            }}
                                            />
                                        </>
                                    }
                                </>
                            }
                        </>
                    }
                </Container>

            </>
            :   
            <div className='main_conatiner_spinner'>
                <Spinner animation="border"/>
            </div>
        }
      </div>
    );

}

export default ReportComparativeAnalysis



function get_all_unique_ads(data, group_by){
    let res = alasql(`SELECT * FROM ? WHERE creative_link != "Link Missing"`,[data]);
    let all_unique_img_arr = alasql(`SELECT FIRST(creative_link) as unique_arr FROM ? GROUP BY ${group_by["value"]}`,[res]).map((val) => {
        return val["unique_arr"];
    });
    return all_unique_img_arr;
}


function get_line_charts_data(shop_domain,  insights_data, creative_data, metrics, group_by, where_arr){
    let where_clause = [];
    if(group_by.length > 0){
        group_by.forEach((groupby_iter) => {
            let temp_group_name = Object.keys(groupby_iter)[0];
            let or_clauses = [];
            groupby_iter[temp_group_name].forEach((val) => {
                or_clauses.push(`([${val[0]["value"]}] ${val[1]["value"]} '%${val[2].replaceAll("'", "''")}%')`);
            })
            let temp_where_clause_dict = {};
            temp_where_clause_dict[temp_group_name] = "("+or_clauses.join(" OR ")+")";
            where_clause.push(temp_where_clause_dict);
        })
        console.log(where_clause)
    }

    let filtered_data = alasql("SELECT * FROM ? AS arr1 JOIN ? AS arr2 ON arr1.ad_id = arr2.ad_id ", [creative_data, insights_data]);
    let final_res = [];
    let all_unique_dates = [...new Set(filtered_data.map(item => item["date_start"]))]; 
    where_clause.forEach((where_clause_iter) => {
        all_unique_dates.forEach((date_iter) => {
            let temp_group_name = Object.keys(where_clause_iter)[0];
            let group_data = alasql(`SELECT ${metrics.map((val) => {
                if(custom_metrics_arr.includes(val["label"])) return val["value"]+" AS ["+val["label"]+"]";
                return "SUM(CAST("+val["value"]+" AS FLOAT)) AS ["+val["label"]+"]";
            })} FROM ? WHERE (date_start = '${date_iter}') AND (${where_clause_iter[temp_group_name]})`,[filtered_data])[0];
            let res = {};
            res["Date"] = date_iter;
            res["group_name"] = temp_group_name;
            Object.keys(group_data).forEach((key) => {
                res[key] = group_data[key];
            })
            final_res.push(res)
        })
    })
    final_res = alasql("SELECT * FROM ? ORDER BY Date",[final_res]);
    
    let line_formatted_res = [];
    all_unique_dates.forEach((date_iter) => {
        let current_date_data = final_res.filter((val) => {return val["Date"] === date_iter})
        let row = {"Date":new Date(date_iter)};
        where_clause.forEach((where_clause_iter) => {
            let key = Object.keys(where_clause_iter)[0];
            let current_group_by_data = current_date_data.filter((val) => {return val["group_name"] === key});
            if(current_group_by_data.length > 0) row[key] = current_group_by_data[0][metrics[0]["label"]];
            else row[key] = 0;
        })
        line_formatted_res.push(row);
    })
    line_formatted_res = alasql("SELECT * FROM ? ORDER BY Date",[line_formatted_res]);


    // let res = alasql(`SELECT DATE(date_start) as Date, ${where_clause.map((where_clause_iter) => {
    //     let temp_group_name = Object.keys(where_clause_iter)[0];
    //     return ("SUM(CASE WHEN " + where_clause_iter[temp_group_name] + "THEN CAST("+metrics[0]["value"]+" AS FLOAT) ELSE 0 END) AS ["+temp_group_name+"]")
    // }).join(", ")} FROM ? GROUP BY date_start ORDER BY Date DESC`,[insights_data]);
    // console.log(res)
    let all_metrics = where_clause.map((val) => {return [Object.keys(val)[0],{ role: 'tooltip', type: 'string', p: { html: true } }]}).flat();
    return {creative_ids:[],line_chart_data:jsonToArrayObject_for_line_charts(shop_domain, line_formatted_res, [{"name":"Date", "value":"Date"}, ...all_metrics], metrics, group_by, filtered_data, where_clause)};
}



function get_bar_charts_data(shop_domain, insights_data, creative_data, metrics, group_by, where_arr){
    let filtered_data = alasql("SELECT * FROM ? AS arr1 JOIN ? AS arr2 ON arr1.ad_id = arr2.ad_id ", [creative_data, insights_data]);
    let where_clause = [];
    if(group_by.length > 0){
        group_by.forEach((groupby_iter) => {
            let temp_group_name = Object.keys(groupby_iter)[0];
            let or_clauses = [];
            groupby_iter[temp_group_name].forEach((val) => {
                or_clauses.push(`([${val[0]["value"]}] ${val[1]["value"]} '%${val[2].replaceAll("'", "''")}%')`);
            })
            let temp_where_clause_dict = {};
            temp_where_clause_dict[temp_group_name] = "("+or_clauses.join(" OR ")+")";
            where_clause.push(temp_where_clause_dict);
        })
        console.log(where_clause)
    }

    let final_res = [];
    where_clause.forEach((where_clause_iter) => {
        let temp_group_name = Object.keys(where_clause_iter)[0];
        let group_data = alasql(`SELECT ${metrics.map((val) => {
            if(custom_metrics_arr.includes(val["label"])) return val["value"]+" AS ["+val["label"]+"]";
            return "SUM(CAST("+val["value"]+" AS FLOAT)) AS ["+val["label"]+"]";
        })} FROM ? WHERE ${where_clause_iter[temp_group_name]}`,[filtered_data])[0];
        let res = {};
        res["group_name"] = temp_group_name;
        Object.keys(group_data).forEach((key) => {
            res[key] = group_data[key];
        })
        final_res.push(res)
    })
    console.log(final_res)
    let all_metrics = metrics.map((val) => {return val["label"]});
    all_metrics = all_metrics.map((val) => {return [val,{ role: 'tooltip', type: 'string', p: { html: true } }]}).flat();
    return jsonToArrayObject_for_bar_charts(shop_domain, final_res, ["Group Name", ...all_metrics], metrics, group_by, creative_data);
}


function get_overview_table_data(shop_domain, insights_data, creative_data, metrics, group_by, where_arr){
    let filtered_data = alasql("SELECT * FROM ? AS arr1 JOIN ? AS arr2 ON arr1.ad_id = arr2.ad_id ", [creative_data, insights_data]);
    metrics = [{ value:"clicks", label:"Clicks"},{ value:"impressions", label:"Impressions"},{ value:"spend", label:"Spend"},{ value:"purchase_value", label:"Purchase"},{ value:"SUM(CAST(purchase_value AS FLOAT))/SUM(CAST(spend AS FLOAT))", label:"ROAS"},{ value:"SUM(CAST(spend AS FLOAT))/SUM(CAST(clicks AS FLOAT))*100", label:"CPC"},{ value:"SUM(CAST(clicks AS FLOAT))/SUM(CAST(impressions AS FLOAT))*100", label:"CTR"}];
    let where_clause = [];
    if(group_by.length > 0){
        group_by.forEach((groupby_iter) => {
            let temp_group_name = Object.keys(groupby_iter)[0];
            let or_clauses = [];
            groupby_iter[temp_group_name].forEach((val) => {
                or_clauses.push(`([${val[0]["value"]}] ${val[1]["value"]} '%${val[2].replaceAll("'", "''")}%')`);
            })
            let temp_where_clause_dict = {};
            temp_where_clause_dict[temp_group_name] = "("+or_clauses.join(" OR ")+")";
            where_clause.push(temp_where_clause_dict);
        })
        console.log(where_clause)
    }

    let final_res = [];
    where_clause.forEach((where_clause_iter) => {
        let temp_group_name = Object.keys(where_clause_iter)[0];
        let group_data = alasql(`SELECT COUNT(DISTINCT ad_id) as ads_count, ${metrics.map((val) => {
            if(custom_metrics_arr.includes(val["label"])) return val["value"]+" AS ["+val["label"]+"]";
            return "SUM(CAST("+val["value"]+" AS FLOAT)) AS ["+val["label"]+"]";
        })} FROM ? WHERE ${where_clause_iter[temp_group_name]}`,[filtered_data])[0];
        let res = {};
        res["group_name"] = temp_group_name;
        Object.keys(group_data).forEach((key) => {
            res[key] = group_data[key];
        })
        final_res.push(res)
    })
    console.log(final_res)
    let all_metrics = metrics.map((val) => {return val["label"]});
    return jsonToArrayObject_for_table_charts(shop_domain, final_res, ["Group Name", ...all_metrics], metrics, group_by, creative_data);
}


function jsonToArrayObject_for_table_charts(shop_domain, res, graph_columns, metric=null, group_by=null, tooltip_data=null) {
    shop_domain = shop_domain.replace(".myshopify.com", "");
    var columns = Object.keys(res[0]);
    var resultArray = res.map(function (obj) {
      var res_arr = [];
      for (let column_name of columns) {
        if(column_name === "ads_count"){ continue }
        else if(column_name === "group_name"){
            res_arr.push(`
                <p>${obj[column_name]}</p>
                <b>${obj["ads_count"]} ads</b>
            `);
        }
        else{
            res_arr.push(obj[column_name]);
        }
      }
      return res_arr;
    });
    resultArray.unshift(graph_columns)
    console.log("---------------------------------")
    console.log("Table",resultArray)
    console.log("---------------------------------")
   return resultArray;
}


function checkIfImageExists(url, callback) {
    const img = new Image();
    img.src = url;
    
    if (img.complete) {
      callback(true);
    } else {
      img.onload = () => {
        callback(true);
      };
      
      img.onerror = () => {
        callback(false);
      };
    }
}


function jsonToArrayObject_for_bar_charts(shop_domain, res, graph_columns, metric=null, group_by=null, tooltip_data=null) {
    shop_domain = shop_domain.replace(".myshopify.com", "");
    var columns = Object.keys(res[0]);
    var resultArray = res.map(function (obj) {
      var res_arr = [];
      var col_num = 0;
      for (let column_name of columns) {
        if(col_num > 0){
            res_arr.push(obj[column_name]);
            if(tooltip_data !== null){
                res_arr.push(`
                    <div style="background-color:white; padding:20px; width:fit-content; border-radius:5px; box-shadow:0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); text-align:center;">
                        <h6 style="margin:3px;">${obj["group_name"]}</h6>
                        <p style='margin:3px; font-size:medium;'>${column_name}: ${obj[column_name].toLocaleString('en-US', {minimumFractionDigits: 0,maximumFractionDigits: 1})}</p>
                    </div>
                `);
            }
        }
        else{
            res_arr.push(obj[column_name]);
        }
        col_num ++;
      }
      return res_arr;
    });
    resultArray.unshift(graph_columns)
    console.log(resultArray)
   return resultArray;
}


function jsonToArrayObject_for_line_charts(shop_domain,res, graph_columns, metric=null, group_by=null, tooltip_data=null, where_clause=null) {
    shop_domain = shop_domain.replace(".myshopify.com", "");
    var columns = Object.keys(res[0]);
    var resultArray = res.map(function (obj) {
      var res_arr = [];
      for (let column_name of columns) {
        if(column_name !== "Date"){
            res_arr.push(obj[column_name]);
            if(tooltip_data !== null){
                let temp_res = alasql(`SELECT ${metric.map((val) => {
                    if(custom_metrics_arr.includes(val["label"])) return val["value"]+" AS ["+val["label"]+"]";
                    return "SUM(CAST("+val["value"]+" AS FLOAT)) AS ["+val["label"]+"]";
                })} FROM ? WHERE ((date_start = '${formatDate(obj["Date"])}') AND (${where_clause.filter((where_clause_iter) => { return Object.keys(where_clause_iter)[0] === column_name})[0][column_name]}))`,[tooltip_data])
                // console.log(temp_res)
                res_arr.push(`
                    <div style="background-color:white; padding:20px; width:fit-content; border-radius:5px; box-shadow:0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); text-align:left;">
                        <h6 style="margin:3px;">${column_name}</h6>
                        <p style="margin:3px; font-size:medium;">Date: ${formatDate(obj["Date"])}</b></p>
                        ${metric.map((val) => {
                            if(custom_metrics_arr.includes(val["label"])) return "<p style='margin:3px; font-size:medium;'>"+val["label"]+": "+ ((temp_res[0] === null) || (temp_res[0] === undefined) || (!temp_res[0][val["label"]]) ? 0 : temp_res[0][val["label"]].toLocaleString('en-US', {minimumFractionDigits: 0,maximumFractionDigits: 1})) + (val["label"] === "ROAS"? "" : "%") +"</p>"
                            return "<p style='margin:3px; font-size:medium;'>"+val["label"]+": "+ ((temp_res[0] === null) || (temp_res[0] === undefined) || (!temp_res[0][val["label"]]) ? 0 : temp_res[0][val["label"]].toLocaleString('en-US', {minimumFractionDigits: 0,maximumFractionDigits: 1})) +"</p>"
                        }).join("")}
                    </div>
                `);
            }
        }
        else{
            res_arr.push(obj[column_name]);
        }
      }
      return res_arr;
    });
    resultArray.unshift(graph_columns)
    // console.log(resultArray)
   return resultArray;
}


function get_filter_date_from_data(data){
    let min_max_date_dict = alasql(`SELECT MIN(DATE(date_start)) as min_date, MAX(DATE(date_start)) as max_date FROM ?`,[data])[0];
    return [min_max_date_dict["min_date"], min_max_date_dict["max_date"]];
}

function formatDate(date, is_increase_date=false) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + (is_increase_date? d.getDate() + 1: d.getDate()),
      year = d.getFullYear();
  
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
  
    return [year, month, day].join('-');
}