import React, { useState, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ReMultiSelect from 'react-select';
import ReMultiSelectAnimated from 'react-select/animated';
import axios from 'axios';
import Cookies from 'js-cookie';
import {showToast as toast} from '../toastManager';

const GroupsSettings = ({ViewedPage, shop_domain, app_url}) => {
    ViewedPage(21)

    const [loading, setLoading] = useState(true)
    const [show_google_groups, showGoogleGroups] = useState(true)
    const [show_facebook_groups, showFacebookGroups] = useState(true)
    const [facebook_save_btn_loading, setFacebookSaveBtnLoading] = useState(false)
    const [google_save_btn_loading, setGoogleSaveBtnLoading] = useState(false)
    const [all_facebook_groups, setAllFacebookGroups] = useState([])
    const [all_google_groups, setAllGoogleGroups] = useState([])
    const [all_facebook_filters_data, setAllFacebookFiltersData] = useState([])
    const [all_google_filters_data, setAllGoogleFiltersData] = useState([])
    const [google_current_selected_ads_level, setGoogleCurrentSelectedAdsLevel] = useState({ value: 'campaign_name', label: 'Campaign name' });
    const [google_current_selected_ads_level_operator, setGoogleCurrentSelectedAdsLevelOperator] = useState({ value: 'LIKE', label: 'contains' });
    const [google_current_selected_ads_level_input_value, setGoogleCurrentSelectedAdsLevelInputValue] = useState("");
    const [google_current_selected_filters_data, setGoogleCurrentSelectedFilterData] = useState([]);
    const [google_current_group_name, setGoogleCurrentGroupName] = useState("");
    const [facebook_current_selected_ads_level, setFacebookCurrentSelectedAdsLevel] = useState({ value: 'campaign_name', label: 'Campaign name' });
    const [facebook_current_selected_ads_level_operator, setFacebookCurrentSelectedAdsLevelOperator] = useState({ value: 'LIKE', label: 'contains' });
    const [facebook_current_selected_ads_level_input_value, setFacebookCurrentSelectedAdsLevelInputValue] = useState("");
    const [facebook_current_selected_filters_data, setFacebookCurrentSelectedFilterData] = useState([]);
    const [facebook_current_group_name, setFacebookCurrentGroupName] = useState("");

    const [facebook_buffer_group, setFacebookBufferGroup] = useState(null);
    const [google_buffer_group, setGoogleBufferGroup] = useState(null);

    useEffect(() => {
        setLoading(true);
        try {
            if(Cookies.get('shop')){
                axios.post(app_url+"/api/settings/get_groups",{
                    shop_domain:Cookies.get('shop'), 
                }, {withCredentials:true})
                .then(res => res.data)
                .then(data => {
                    console.log(data)
                    setAllFacebookGroups(data["data"]["groups_data"]["facebook_groups"])
                    setAllGoogleGroups(data["data"]["groups_data"]["google_groups"])
                    setAllFacebookFiltersData(data["data"]["filters_data"]["meta"])
                    setAllGoogleFiltersData(data["data"]["filters_data"]["google"])
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                    toast.error("Please try after some time.");
                    console.log(err);
                })
            }
        } catch (error) {
            setLoading(false)
            toast.error("Please try after some time.");
            console.log(error);
        }
    }, [app_url, shop_domain])


    const handleSaveGoogleGroup = (e) => {
        e.preventDefault();
        if((google_current_group_name === null) || (google_current_group_name === undefined) || (google_current_group_name.trim() === '')){
            toast.error("Group name cannot be empty");
            return;
        }

        if((google_current_selected_filters_data === null) || (google_current_selected_filters_data === undefined) || (google_current_selected_filters_data.length === 0)){
            toast.error("Add atleast one filter");
            return;
        }

        setGoogleBufferGroup(null);

        let temp_curr_add_group = {}
        temp_curr_add_group[google_current_group_name] = google_current_selected_filters_data
        let temp_all_google_groups = [all_google_groups, temp_curr_add_group].flat();
        setGoogleSaveBtnLoading(true);
        axios.post(app_url+"/api/settings/save_google_groups",{
            shop_domain:Cookies.get('shop'), 
            google_groups: temp_all_google_groups
        }, {withCredentials:true})
        .then(res => res.data)
        .then(data => {
            console.log(data)
            setAllFacebookGroups(data["data"]["groups_data"]["facebook_groups"])
            setAllGoogleGroups(data["data"]["groups_data"]["google_groups"])
            setGoogleCurrentSelectedAdsLevel({ value: 'campaign_name', label: 'Campaign name' });
            setGoogleCurrentSelectedAdsLevelOperator({ value: 'LIKE', label: 'contains' });
            setGoogleCurrentSelectedAdsLevelInputValue("");
            setGoogleCurrentSelectedFilterData([]);
            setGoogleCurrentGroupName("");
            setGoogleSaveBtnLoading(false);
            showGoogleGroups(true);
        })
        .catch(err => {
            setGoogleSaveBtnLoading(false);
            toast.error("Please try after some time.");
            console.log(err);
        })
    }


    const handleSaveFacebookGroup = (e) => {
        e.preventDefault();
        if((facebook_current_group_name === null) || (facebook_current_group_name === undefined) || (facebook_current_group_name.trim() === '')){
            toast.error("Group name cannot be empty");
            return;
        }
        
        if((facebook_current_selected_filters_data === null) || (facebook_current_selected_filters_data === undefined) || (facebook_current_selected_filters_data.length === 0)){
            toast.error("Add atleast one filter");
            return;
        }

        setFacebookBufferGroup(null);

        let temp_curr_add_group = {}
        temp_curr_add_group[facebook_current_group_name] = facebook_current_selected_filters_data
        let temp_all_facebook_groups = [all_facebook_groups, temp_curr_add_group].flat();
        setFacebookSaveBtnLoading(true);
        axios.post(app_url+"/api/settings/save_facebook_groups",{
            shop_domain:Cookies.get('shop'), 
            facebook_groups: temp_all_facebook_groups
        }, {withCredentials:true})
        .then(res => res.data)
        .then(data => {
            console.log(data)
            setAllFacebookGroups(data["data"]["groups_data"]["facebook_groups"])
            setAllGoogleGroups(data["data"]["groups_data"]["google_groups"])
            setFacebookCurrentSelectedAdsLevel({ value: 'campaign_name', label: 'Campaign name' });
            setFacebookCurrentSelectedAdsLevelOperator({ value: 'LIKE', label: 'contains' });
            setFacebookCurrentSelectedAdsLevelInputValue("");
            setFacebookCurrentSelectedFilterData([]);
            setFacebookCurrentGroupName("");
            setFacebookSaveBtnLoading(false);
            showFacebookGroups(true);
        })
        .catch(err => {
            setFacebookSaveBtnLoading(false);
            toast.error("Please try after some time.");
            console.log(err);
        })
    }

    return (
        <div className='main-container chatbot' style={{position:"relative"}}>
            {!shop_domain?
                <div className='go_to_shopify_admin_div'>
                    <h5 style={{display:'inline-block'}}>!! Go to Shopify Admin and open our app !!</h5>
                </div>
                :
                <>
                    { !loading ?
                        <>
                            <div className='main-title mt-4'>
                                <h2>Groups</h2>
                            </div>

                            <div className='main-accordion-body mt-4 p-2'>
                                <Accordion defaultActiveKey="1">
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header><h5>Google</h5></Accordion.Header>
                                        <Accordion.Body>
                                            <Container>
                                                <Row className='justify-content-md-left my-4' lg={1} xs={1}>
                                                    <Col>
                                                        <h5 style={{margin:0,display:"inline-block"}}><b>Groups</b></h5>
                                                        {show_google_groups === true &&
                                                            <Button className='groups_show_groups_button mx-3' onClick={(e) => {
                                                                e.preventDefault();
                                                                showGoogleGroups(false)
                                                            }}>Create</Button>
                                                        }
                                                    </Col>
                                                </Row>
                                                {(show_google_groups === false) &&
                                                    <>
                                                        <Row className='justify-content-md-left' lg={5} xs={1}>
                                                            <Col className='py-2'>
                                                                <Form.Control style={{display:"inline-block"}} type="text" value={google_current_group_name} placeholder="Group name" onChange={(e) => {
                                                                    e.preventDefault();
                                                                    setGoogleCurrentGroupName(e.target.value);
                                                                }}/>
                                                            </Col>
                                                            <Col className='py-2'>
                                                                <ReMultiSelect 
                                                                    id="x_axis"
                                                                    closeMenuOnSelect={true}
                                                                    components={ReMultiSelectAnimated()}
                                                                    value={google_current_selected_ads_level}
                                                                    options={Object.keys(all_google_filters_data).map((key) => { return { value: key, label: key.replaceAll("_"," ")[0].toUpperCase() + key.replaceAll("_"," ").slice(1) } })}
                                                                    onChange={(selected_vals) => {
                                                                        setGoogleCurrentSelectedAdsLevel(selected_vals);
                                                                        setGoogleCurrentSelectedAdsLevelOperator({ value: 'LIKE', label: 'contains' });
                                                                        setGoogleCurrentSelectedAdsLevelInputValue("");
                                                                    }}
                                                                />
                                                            </Col>
                                                            <Col className='py-2'>
                                                                <ReMultiSelect 
                                                                    id="x_axis"
                                                                    closeMenuOnSelect={true}
                                                                    components={ReMultiSelectAnimated()}
                                                                    value={google_current_selected_ads_level_operator}
                                                                    options={[
                                                                        { value: 'LIKE', label: 'contains' },
                                                                        { value: 'NOT LIKE', label: "doesn't contain" },
                                                                        { value: 'IN', label: "equal to" },
                                                                        { value: 'NOT IN', label: "not equal to" },
                                                                    ]}
                                                                    onChange={(selected_vals) => {
                                                                        setGoogleCurrentSelectedAdsLevelOperator(selected_vals);
                                                                        if(selected_vals["value"] === "IN" || selected_vals["value"] === "NOT IN"){
                                                                            setGoogleCurrentSelectedAdsLevelInputValue([]);
                                                                        }
                                                                        else{
                                                                            setGoogleCurrentSelectedAdsLevelInputValue("");
                                                                        }
                                                                    }}
                                                                />
                                                            </Col>
                                                            <Col className='py-2'>
                                                                { (! google_current_selected_ads_level_operator.value.includes("IN") ) ?
                                                                    <Form.Control type="text" placeholder="Value" value={google_current_selected_ads_level_input_value} onChange={(e) => {
                                                                        setGoogleCurrentSelectedAdsLevelInputValue(e.target.value);
                                                                    }} />
                                                                    :
                                                                    <ReMultiSelect 
                                                                        closeMenuOnSelect={true}
                                                                        components={ReMultiSelectAnimated()}
                                                                        isMulti
                                                                        value={google_current_selected_ads_level_input_value}
                                                                        options={all_google_filters_data[google_current_selected_ads_level.value]}
                                                                        onChange={(selected_vals) => {
                                                                            setGoogleCurrentSelectedAdsLevelInputValue(selected_vals);
                                                                        }}
                                                                    />
                                                                }
                                                            </Col>
                                                            <Col className='py-2'>
                                                                <Button variant='primary' onClick={(e) => {
                                                                    e.preventDefault();
                                                                    if((google_current_group_name === null) || (google_current_group_name === undefined) || (google_current_group_name.trim() === '')){
                                                                        toast.error("Group name cannot be empty");
                                                                    }
                                                                    else if((google_current_selected_ads_level_input_value === null) ||
                                                                    (google_current_selected_ads_level_input_value === undefined) ||
                                                                    ((typeof(google_current_selected_ads_level_input_value) !== 'string') && (google_current_selected_ads_level_input_value.length === 0)) ||
                                                                    ((typeof(google_current_selected_ads_level_input_value) === 'string') && (google_current_selected_ads_level_input_value.trim() === ''))){
                                                                        toast.error("Value cannot be empty");
                                                                    }
                                                                    else{
                                                                        let temp_current_selected_ads_level_input_value = google_current_selected_ads_level_input_value;
                                                                        let temp_current_selected_filters_data = [google_current_selected_filters_data, [[google_current_selected_ads_level, google_current_selected_ads_level_operator, temp_current_selected_ads_level_input_value]]].flat();
                                                                        setGoogleCurrentSelectedFilterData(temp_current_selected_filters_data);
                                                                    }
                                                                }} className='groups_page_button w-100'>Add</Button>
                                                            </Col>
                                                        </Row>

                                                        <Row className='justify-content-md-left' lg={1} xs={1}>
                                                            <Col>
                                                                <div>
                                                                    {google_current_selected_filters_data.map((val, idx) => {
                                                                        return (
                                                                            <p style={{margin:"5px", backgroundColor:"#edefff", padding:"0.5rem", borderRadius:"5px", display:"inline-block"}}>
                                                                                {val[0]["label"]} {val[1]["label"]} {(typeof(val[2]) === "string" ? val[2] : ("('" + val[2].map((val2) => { return val2["label"] }).join("' OR '")) + "')")}
                                                                                <button style={{backgroundColor:"#edefff", border:"none", padding:"0px 5px 0px 10px", marginLeft:"5px", borderLeft:"1px solid #ccc"}} onClick={(e) => {
                                                                                    e.preventDefault();

                                                                                    let temp_current_selected_filters_data = google_current_selected_filters_data.filter((all_val,all_idx) => { return all_idx !== idx })
                                                                                    setGoogleCurrentSelectedFilterData(temp_current_selected_filters_data);
                                                                                }}>
                                                                                    &#10005;
                                                                                </button>
                                                                            </p> 
                                                                        );
                                                                    })}
                                                                </div>
                                                                { google_save_btn_loading ? 
                                                                    <Button className='groups_page_button mt-3'><Spinner animation="border" size='sm'></Spinner></Button>
                                                                :
                                                                    <Button className='groups_page_button mt-3' onClick={handleSaveGoogleGroup}>Save</Button>
                                                                }
                                                                <Button className='groups_page_button mx-3 mt-3' onClick={(e) => {
                                                                    e.preventDefault();
                                                                    if(google_buffer_group !== null){
                                                                        let temp_all_google_groups = all_google_groups;
                                                                        temp_all_google_groups.push(google_buffer_group);
                                                                        setAllGoogleGroups(temp_all_google_groups)
                                                                        setGoogleBufferGroup(null)
                                                                    }
                                                                    setGoogleCurrentSelectedAdsLevel({ value: 'campaign_name', label: 'Campaign name' });
                                                                    setGoogleCurrentSelectedAdsLevelOperator({ value: 'LIKE', label: 'contains' });
                                                                    setGoogleCurrentSelectedAdsLevelInputValue("");
                                                                    setGoogleCurrentSelectedFilterData([]);
                                                                    setGoogleCurrentGroupName("");
                                                                    showGoogleGroups(true);
                                                                }}>Cancel</Button>
                                                            </Col>
                                                        </Row>
                                                    </>
                                                }
                                                
                                                {((show_google_groups === true) && (all_google_groups.length > 0)) && 
                                                    <>
                                                        <Row className='justify-content-md-left' lg={4} xs={1}>
                                                            {all_google_groups.map((filter_iter, filter_iter_idx) => {
                                                                let temp_current_selected_group_name = Object.keys(filter_iter)[0];
                                                                return (
                                                                    <div style={{minWidth:"10rem", textAlign:"center", maxWidth:"fit-content", border:"1px solid rgb(179 173 173)", borderRadius:"0.5rem", margin:"0.5rem", cursor:"pointer", padding:"1rem"}} onClick={(e) => {
                                                                        e.preventDefault();
                                                                        let temp_buffer_group = {};
                                                                        temp_buffer_group[temp_current_selected_group_name] = filter_iter[temp_current_selected_group_name];
                                                                        setGoogleBufferGroup(temp_buffer_group);
                                                                        setGoogleCurrentGroupName(temp_current_selected_group_name);
                                                                        setGoogleCurrentSelectedFilterData(filter_iter[temp_current_selected_group_name]);
                                                                        setGoogleCurrentSelectedAdsLevel({ value: 'campaign_name', label: 'Campaign name' });
                                                                        setGoogleCurrentSelectedAdsLevelInputValue("");
                                                                        let temp_all_google_groups = all_google_groups.filter((all_val,all_idx) => { return all_idx !== filter_iter_idx });
                                                                        setAllGoogleGroups(temp_all_google_groups);
                                                                        showGoogleGroups(false);
                                                                    }}>
                                                                        Group Name - <b>{temp_current_selected_group_name}</b>
                                                                    </div>
                                                                );
                                                            })}
                                                        </Row>
                                                    </>
                                                }
                                            </Container>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header><h5>Facebook</h5></Accordion.Header>
                                        <Accordion.Body>
                                            <Container>
                                            <Row className='justify-content-md-left my-4' lg={1} xs={1}>
                                                <Col>
                                                    <h5 style={{margin:0,display:"inline-block"}}><b>Groups</b></h5>
                                                    { show_facebook_groups === true && 
                                                        <Button className='groups_show_groups_button mx-3' onClick={(e) => {
                                                            e.preventDefault();
                                                            showFacebookGroups(false)
                                                        }}>Create</Button>
                                                    }
                                                </Col>
                                                <Col>
                                                </Col>
                                            </Row>
                                                { (show_facebook_groups === false) &&
                                                    <>
                                                        <Row className='justify-content-md-left' lg={5} xs={1}>
                                                            <Col className='py-2'>
                                                                <Form.Control style={{display:"inline-block"}} type="text" placeholder="Group name" value={facebook_current_group_name} onChange={(e) => {
                                                                    e.preventDefault();
                                                                    setFacebookCurrentGroupName(e.target.value);
                                                                }}/>
                                                            </Col>
                                                            <Col className='py-2'>
                                                                <ReMultiSelect 
                                                                    id="x_axis"
                                                                    closeMenuOnSelect={true}
                                                                    components={ReMultiSelectAnimated()}
                                                                    value={facebook_current_selected_ads_level}
                                                                    options={Object.keys(all_facebook_filters_data).map((key) => { return { value: key, label: key.replaceAll("_"," ")[0].toUpperCase() + key.replaceAll("_"," ").slice(1) } })}
                                                                    onChange={(selected_vals) => {
                                                                        setFacebookCurrentSelectedAdsLevel(selected_vals);
                                                                        setFacebookCurrentSelectedAdsLevelOperator({ value: 'LIKE', label: 'contains' });
                                                                        setFacebookCurrentSelectedAdsLevelInputValue("");
                                                                    }}
                                                                />
                                                            </Col>
                                                            <Col className='py-2'>
                                                                <ReMultiSelect 
                                                                    id="x_axis"
                                                                    closeMenuOnSelect={true}
                                                                    components={ReMultiSelectAnimated()}
                                                                    value={facebook_current_selected_ads_level_operator}
                                                                    options={[
                                                                        { value: 'LIKE', label: 'contains' },
                                                                        { value: 'NOT LIKE', label: "doesn't contain" },
                                                                        { value: 'IN', label: "equal to" },
                                                                        { value: 'NOT IN', label: "not equal to" },
                                                                    ]}
                                                                    onChange={(selected_vals) => {
                                                                        setFacebookCurrentSelectedAdsLevelOperator(selected_vals);
                                                                        if(selected_vals["value"] === "IN" || selected_vals["value"] === "NOT IN"){
                                                                            setFacebookCurrentSelectedAdsLevelInputValue([]);
                                                                        }
                                                                        else{
                                                                            setFacebookCurrentSelectedAdsLevelInputValue("");
                                                                        }
                                                                    }}
                                                                />
                                                            </Col>
                                                            <Col className='py-2'>
                                                            { (! facebook_current_selected_ads_level_operator.value.includes("IN") ) ?
                                                                <Form.Control type="text" placeholder="Value" value={facebook_current_selected_ads_level_input_value} onChange={(e) => {
                                                                    setFacebookCurrentSelectedAdsLevelInputValue(e.target.value);
                                                                }} />
                                                                :
                                                                <ReMultiSelect 
                                                                    closeMenuOnSelect={true}
                                                                    components={ReMultiSelectAnimated()}
                                                                    isMulti
                                                                    value={facebook_current_selected_ads_level_input_value}
                                                                    options={all_facebook_filters_data[facebook_current_selected_ads_level.value]}
                                                                    onChange={(selected_vals) => {
                                                                        setFacebookCurrentSelectedAdsLevelInputValue(selected_vals);
                                                                    }}
                                                                />
                                                            }
                                                            </Col>
                                                            <Col className='py-2'>
                                                                <Button variant='primary' onClick={(e) => {
                                                                    e.preventDefault();
                                                                    if((facebook_current_group_name === null) || (facebook_current_group_name === undefined) || (facebook_current_group_name.trim() === '')){
                                                                        toast.error("Group name cannot be empty");
                                                                    }
                                                                    else if((facebook_current_selected_ads_level_input_value === null) ||
                                                                    (facebook_current_selected_ads_level_input_value === undefined) ||
                                                                    ((typeof(facebook_current_selected_ads_level_input_value) !== 'string') && (facebook_current_selected_ads_level_input_value.length === 0)) ||
                                                                    ((typeof(facebook_current_selected_ads_level_input_value) === 'string') && (facebook_current_selected_ads_level_input_value.trim() === ''))){
                                                                        toast.error("Value cannot be empty");
                                                                    }
                                                                    else{
                                                                        let temp_current_selected_ads_level_input_value = facebook_current_selected_ads_level_input_value;
                                                                        let temp_current_selected_filters_data = [facebook_current_selected_filters_data, [[facebook_current_selected_ads_level, facebook_current_selected_ads_level_operator, temp_current_selected_ads_level_input_value]]].flat();
                                                                        setFacebookCurrentSelectedFilterData(temp_current_selected_filters_data);
                                                                    }
                                                                }} className='groups_page_button w-100'>Add</Button>
                                                            </Col>
                                                        </Row>

                                                        <Row className='justify-content-md-left' lg={1} xs={1}>
                                                            <Col>
                                                                <div>
                                                                    {facebook_current_selected_filters_data.map((val, idx) => {
                                                                        return (
                                                                            <p style={{margin:"5px", backgroundColor:"#edefff", padding:"0.5rem", borderRadius:"5px", display:"inline-block"}}>
                                                                                {val[0]["label"]} {val[1]["label"]} {(typeof(val[2]) === "string" ? val[2] : ("('" + val[2].map((val2) => { return val2["label"] }).join("' OR '")) + "')" )}
                                                                                <button style={{backgroundColor:"#edefff", border:"none", padding:"0px 5px 0px 10px", marginLeft:"5px", borderLeft:"1px solid #ccc"}} onClick={(e) => {
                                                                                    e.preventDefault();

                                                                                    let temp_current_selected_filters_data = facebook_current_selected_filters_data.filter((all_val,all_idx) => { return all_idx !== idx })
                                                                                    setFacebookCurrentSelectedFilterData(temp_current_selected_filters_data);
                                                                                }}>
                                                                                    &#10005;
                                                                                </button>
                                                                            </p> 
                                                                        );
                                                                    })}
                                                                </div>
                                                                { facebook_save_btn_loading ? 
                                                                    <Button className='groups_page_button mt-3'><Spinner animation="border" size='sm'></Spinner></Button>
                                                                :
                                                                    <Button className='groups_page_button mt-3' onClick={handleSaveFacebookGroup}>Save</Button>
                                                                }
                                                                <Button className='groups_page_button mx-3 mt-3' onClick={(e) => {
                                                                    e.preventDefault();
                                                                    if(facebook_buffer_group !== null){
                                                                        let temp_all_facebook_groups = all_facebook_groups;
                                                                        temp_all_facebook_groups.push(facebook_buffer_group);
                                                                        setAllFacebookGroups(temp_all_facebook_groups)
                                                                        setFacebookBufferGroup(null)
                                                                    }
                                                                    setFacebookCurrentSelectedAdsLevel({ value: 'campaign_name', label: 'Campaign name' });
                                                                    setFacebookCurrentSelectedAdsLevelOperator({ value: 'LIKE', label: 'contains' });
                                                                    setFacebookCurrentSelectedAdsLevelInputValue("");
                                                                    setFacebookCurrentSelectedFilterData([]);
                                                                    setFacebookCurrentGroupName("");
                                                                    showFacebookGroups(true);
                                                                }}>Cancel</Button>
                                                            </Col>
                                                        </Row>
                                                    </>
                                                }


                                                {((show_facebook_groups === true) && (all_facebook_groups.length > 0)) && 
                                                    <>
                                                        <Row className='justify-content-md-left' lg={4} xs={1}>
                                                            {all_facebook_groups.map((filter_iter, filter_iter_idx) => {
                                                                let temp_current_selected_group_name = Object.keys(filter_iter)[0];
                                                                return (
                                                                    <div style={{minWidth:"10rem", textAlign:"center", maxWidth:"fit-content", border:"1px solid rgb(179 173 173)", borderRadius:"0.5rem", margin:"0.5rem", cursor:"pointer", padding:"1rem"}} onClick={(e) => {
                                                                        e.preventDefault();
                                                                        let temp_buffer_group = {};
                                                                        temp_buffer_group[temp_current_selected_group_name] = filter_iter[temp_current_selected_group_name];
                                                                        setFacebookBufferGroup(temp_buffer_group);
                                                                        setFacebookCurrentGroupName(temp_current_selected_group_name);
                                                                        setFacebookCurrentSelectedFilterData(filter_iter[temp_current_selected_group_name]);
                                                                        setFacebookCurrentSelectedAdsLevel({ value: 'campaign_name', label: 'Campaign name' });
                                                                        setFacebookCurrentSelectedAdsLevelInputValue("");
                                                                        let temp_all_facebook_groups = all_facebook_groups.filter((all_val,all_idx) => { return all_idx !== filter_iter_idx });
                                                                        setAllFacebookGroups(temp_all_facebook_groups);
                                                                        showFacebookGroups(false);
                                                                    }}>
                                                                        Group Name - <b>{temp_current_selected_group_name}</b>
                                                                    </div>
                                                                );
                                                            })}
                                                        </Row>
                                                    </>
                                                }
                                            </Container>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </>
                    :
                        <div className='main_conatiner_spinner'>
                            <Spinner animation="border"/>
                        </div>
                    }
                </>
            }
        </div>
    );
}

export default GroupsSettings;