import React, {useState, useEffect, useRef} from 'react'
import axios from 'axios';
import Cookies from 'js-cookie';
import {showToast as toast} from '../toastManager';
import {OverlayTrigger, Tooltip, Spinner, Container, Col, Row, Card, Button, FormLabel as Label, Accordion} from 'react-bootstrap';
import ReMultiSelect from 'react-select';
import ReMultiSelectAnimated from 'react-select/animated';
import alasql from 'alasql';
import settings_icon from '../images/settings_icon.png';

alasql.fn.add_id_string = function(product_id){
    return `${"gid://shopify/Product/"+product_id}`
}

function MetaCreativeGeneration({ViewedPage, shop_domain, app_url}) {
    ViewedPage(14);

    const [loading, setLoading] = useState(true);
    const [save_to_sheet_loading, setSaveToSheetLoading] = useState(false); 
    const [creative_button_loading, setCreativeButtonLoading] = useState(false); 
    const [headlines_loading_btn, setHeadlinesLoadingBtn] = useState(false);
    const [show_advanced_settings, setShowAdvancedSettings] = useState(false);
    const [all_creative_data, setCreativeData] = useState([]);
    const [all_insights_data, setInsightsData] = useState([]);
    const [all_google_asset_data, setGoogleAssetData] = useState([]);
    const [all_google_keyword_data, setGoogleKeywordData] = useState([]);
    const [all_shopify_order_data, setShopifyOrderData] = useState([]);
    const [all_dropdown_options, setDropdownOptions] = useState([]);
    const [selected_channel, setSelectedChannel] = useState({value:"google",label:"Google"});
    const [selected_dropdown_1, setDropdown1] = useState({value:"None",label:"None"});
    const [selected_dropdown_2, setDropdown2] = useState([]);
    const [all_facebook_groups ,setAllFacebookGroups] = useState([]);
    const [all_google_groups ,setAllGoogleGroups] = useState([]);
    const [all_headlines_to_display ,setHeadlinesToDisplay] = useState([]);
    const [advanced_settings_campaign_filters ,setAdvancedSettingCampaignFilters] = useState([]);
    const [selected_theme ,setSelectedTheme] = useState(null);
    const [themes_options ,setThemesOptions] = useState(["Performance Based","Theme Based","Reviews","Meta","Keyword based","Best Sellers"]); 
    const [product_creative_image_urls, setProductCreativeImageURLs] = useState([]); 
    const [selected_product_creative_image_idx_arr, setSelectedProductCreativeImageIndex] = useState([]); 


    const channel_dropdown_options = {
        "google":[
            {"value":"groups","label": "Groups"},
            {"value":"campaign_name","label": "Campaign Name"},
            {"value":"adgroup_name","label": "Adgroup Name"},
            {"value":"None","label": "None"},
        ],
        "facebook":[
            {"value":"groups","label": "Groups"},
            {"value":"campaign_name","label": "Campaign Name"},
            {"value":"adset_name","label": "Adset Name"},
            {"value":"None","label": "None"},
        ]
    }


    const channel_based_themes_dict = {
        "None":[],
        "google":["Performance Based","Theme Based","Reviews","Meta","Keyword based","Best Sellers"],
        "facebook":["Performance Based","Theme Based","Reviews","Personalization","Best Search Copies","Best Pmax Copies","Best Sellers"]
    };


    const [loadedImages, setLoadedImages] = useState([]);
    const [page, setPage] = useState(1);
    const loader = useRef(null);
    const [selected_headline_arr, setSelectedHeadline] = useState([])


    useEffect(() => {
        setLoading(true);
        try{
            var created_at_max = new Date();
            var created_at_min = new Date(new Date().setDate(created_at_max.getDate() - 90));
            
            axios.post(app_url+"/api/settings/get_groups",{
                shop_domain:Cookies.get('shop'), 
            }, {withCredentials:true})
            .then(res => res.data)
            .then(data => {
                setAllFacebookGroups(data["data"]["groups_data"]["facebook_groups"].map((item) => {
                    let key = Object.keys(item)[0]
                    let value = "((" + item[key].map((val) => { return `${val[0]["value"]} ${val[1]["value"]} ${(typeof(val[2]) === "string" ? ("'%" + val[2].replaceAll("'","''") + "%'") : ("('" + val[2].map((val2) => { return val2["value"] }).join("','")) + "')")}` }).join(") AND (") + "))"
                    return {value: value, label: key}
                }))
                setAllGoogleGroups(data["data"]["groups_data"]["google_groups"].map((item) => {
                    let key = Object.keys(item)[0]
                    let value = "((" + item[key].map((val) => { return `${val[0]["value"]} ${val[1]["value"]} ${(typeof(val[2]) === "string" ? ("'%" + val[2].replaceAll("'","''") + "%'") : ("('" + val[2].map((val2) => { return val2["value"] }).join("','")) + "')")}` }).join(") AND (") + "))"
                    return {value: value, label: key}
                }))
            })
            .catch(err => {
                toast.error("Please try after some time.");
                console.log(err);
            })

            axios.post(app_url+"/api/facebook_and_google/get_creative_ideation_data",{
                shop_domain:Cookies.get('shop'),
                date_start:formatDate(created_at_min),
                date_end:formatDate(created_at_max),
                func_call_type:"redis"
            }, {withCredentials:true})
            .then(res => res.data)
            .then(data => {
                console.log(data)
                setCreativeData(data["data"]["facebook"]["creative_data"]);
                setInsightsData(data["data"]["facebook"]["insights_data"]);
                setGoogleAssetData(data["data"]["google"]["chatbot_asset_data"]);
                setGoogleKeywordData(data["data"]["google"]["chatbot_keywords_data"]);
                setShopifyOrderData(data["data"]["shopify"]["order_data"]);
                setDropdownOptions(get_dropdown_options(data["data"]["facebook"]["insights_data"], data["data"]["google"]["chatbot_asset_data"]))
                setLoading(false)
            })
            .catch(err => {
                setLoading(false)
                toast.error("Please try after some time.");
                console.log(err);
            })
        }
        catch(error){
            console.error("Error:",error);
            toast.error("Please try after some time.");
            setLoading(false);
        }
    },[app_url, shop_domain])


    const generate_headlines_using_chatbot = (themes) => {
        setSelectedTheme(themes);
        setHeadlinesLoadingBtn(true);
        try{
            let temp_shopify_data = [];
            if(themes === "Personalization"){
                temp_shopify_data = get_shopify_analysis_data(all_shopify_order_data, "city");
            }
            else if(themes === "Best Sellers"){
                temp_shopify_data = get_shopify_analysis_data(all_shopify_order_data, "product_title");
            }
            
            let temp_meta_data = [];
            if((selected_channel.value === "google") && (![].includes(themes))){
                console.log("Google")
                if(["Meta"].includes(themes)){
                    temp_meta_data = get_meta_analysis_data(all_insights_data, all_creative_data, advanced_settings_campaign_filters.length > 0 ? {"value":"campaign_name","label":"Campaign Name"} : {"value":"None","label": "None"}, advanced_settings_campaign_filters);
                }
                else{
                    temp_meta_data = get_google_analysis_data(all_google_asset_data, all_google_keyword_data, selected_dropdown_1, selected_dropdown_2);
                }
            }
            else if(selected_channel.value === "facebook"){
                console.log("Facebook")
                if(["Best Search Copies","Best Pmax Copies"].includes(themes)){
                    temp_meta_data = get_google_analysis_data(all_google_asset_data, all_google_keyword_data, advanced_settings_campaign_filters.length > 0 ? {"value":"campaign_name","label":"Campaign Name"} : {"value":"None","label": "None"}, advanced_settings_campaign_filters);
                }
                else{
                    temp_meta_data = get_meta_analysis_data(all_insights_data, all_creative_data, selected_dropdown_1, selected_dropdown_2);
                }
            }

            let prompt = "";
            let send_business_context = false;
            let send_business_reviews = false;
            let send_to_chatbot = true;
            // Themes: Context + headlines based on 10 different themes + tactic modification
            // Reviews: Context + headlines based on Reviews + Tactic Modification
            // Best Search: Context + Variations of best performing + Tactic modification
            // City: Context + Localize for these cities
            // "Meta","Keyword based","Best Sellers","Best Search Copies","Best Pmax Copies"
            if(themes === "Performance Based"){
                if(selected_channel.value === "google"){
                    prompt = `${(Object.keys(temp_meta_data[0]).includes("headlines") ? ("\nBelow are the top performing headlines:\n"+temp_meta_data[0]["headlines"].join("\n ") + "\n\n") : "")}. Provide variations of best performing headlines in less than 30 character.`;
                }
                else{
                    prompt = `${"\nBelow are the top performing copies:\n"+temp_meta_data.map((val) => {return "Headline:'"+val["title"]+"', Primary Text:'"+val["descriptor"]+"'"}).join("\n ") + "\n\n"}. Provide variations of best performing headlines in less than 50 character.`;
                }
                send_business_context = true;
            }
            else if(themes === "Theme Based"){
                if(selected_channel.value === "google"){
                    prompt = `${(Object.keys(temp_meta_data[0]).includes("headlines") ? ("\nBelow are the top performing headlines:\n"+temp_meta_data[0]["headlines"].join("\n ") + "\n\n") : "")}. Provide variations of best performing headlines in less than 30 character based on 10 different themes (2 headlines for each theme).`;
                }
                else{
                    prompt = `${"\nBelow are the top performing copies:\n"+temp_meta_data.map((val) => {return "Headline:'"+val["title"]+"', Primary Text:'"+val["descriptor"]+"'"}).join("\n ") + "\n\n"}. Provide variations of best performing headlines in less than 50 character based on 10 different themes (2 headlines for each theme).`;
                }
                send_business_context = true;
            }
            else if(themes === "Reviews"){
                if(selected_channel.value === "google"){
                    prompt = `${(Object.keys(temp_meta_data[0]).includes("headlines") ? ("\nBelow are the top performing headlines:\n"+temp_meta_data[0]["headlines"].join("\n ") + "\n\n") : "")}. Provide variations of best performing headlines in less than 30 character based on reviews.`;
                }
                else{
                    prompt = `${"\nBelow are the top performing copies:\n"+temp_meta_data.map((val) => {return "Headline:'"+val["title"]+"', Primary Text:'"+val["descriptor"]+"'"}).join("\n ") + "\n\n"}. Provide variations of best performing headlines in less than 50 character based on reviews.`;
                }
                send_business_context = true;
                send_business_reviews = true;
            }
            else if(themes === "Keyword based"){
                prompt = `${(Object.keys(temp_meta_data[0]).includes("headlines") ? ("\nBelow are the best performing keywords:\n"+temp_meta_data[0]["keywords"]) + ("\nBelow are the top performing headlines:\n"+temp_meta_data[0]["headlines"].join("\n ") + "\n\n") : "")}. Provide variations of best performing headlines in less than 30 character based on keywords.`;
                send_business_context = true;
            }
            else if(themes === "Personalization"){
                prompt = `${"\nBelow are the top performing copies:\n"+temp_meta_data.map((val) => {return "Headline:'"+val["title"]+"', Primary Text:'"+val["descriptor"]+"'"}).join("\n ") + "\n\n"} Cities : \n${temp_shopify_data.map((val) => {return val["city"]}).join("\n")} \n\nLocalize above headlines based on above cities.`;
                send_business_context = true;
            }
            else if(themes === "Best Sellers"){
                if(selected_channel.value === "google"){
                    prompt = `${(Object.keys(temp_meta_data[0]).includes("headlines") ? ("\nBelow are the top performing headlines:\n"+temp_meta_data[0]["headlines"].join("\n ") + "\n\n") : "")} \n\nBest sellers : \n\n${temp_shopify_data.map((val) => {return val["product_title"]}).join("\n")} \n\n Provide 10 variations of best performing headlines in less than 30 character based on above best sellers.`;
                }
                else{
                    prompt = `${"\nBelow are the top performing copies:\n"+temp_meta_data.map((val) => {return "Headline:'"+val["title"]+"', Primary Text:'"+val["descriptor"]+"'"}).join("\n ") + "\n\n"} \n\nBest sellers : \n\n${temp_shopify_data.map((val) => {return val["product_title"]}).join("\n")} \n\n Provide 10 variations of best performing headlines in less than 50 character character based on above best sellers.`;
                }
                send_business_context = true;
            }
            else if(themes === "Meta"){
                let temp_all_headlines = temp_meta_data.map((val) => {return val["title"]});
                send_business_context = true;
                send_to_chatbot = false;
                console.log(temp_all_headlines)
                setHeadlinesToDisplay(temp_all_headlines);
                setHeadlinesLoadingBtn(false)
            }
            else if(themes === "Best Search Copies"){
                let temp_all_headlines = temp_meta_data[0]["headlines"];
                send_business_context = true;
                send_to_chatbot = false;
                console.log(temp_all_headlines)
                setHeadlinesToDisplay(temp_all_headlines);
                setHeadlinesLoadingBtn(false)
            }
            else if(themes === "Best Pmax Copies"){
                let temp_all_headlines = temp_meta_data[0]["headlines"];
                send_business_context = true;
                send_to_chatbot = false;
                console.log(temp_all_headlines)
                setHeadlinesToDisplay(temp_all_headlines);
                setHeadlinesLoadingBtn(false)
            }

            console.log(prompt)

            if(send_to_chatbot){
                axios.post(app_url+"/recommendations_for_creative_generation",{
                    new_message:prompt+". Response should be in json format and strictly follow this format: {'headline':['headline_1','headline_2','headline_3','headline_4']}", 
                    old_message:[],
                    format:"json_object",
                    send_business_context:send_business_context,
                    send_business_reviews:send_business_reviews,
                }, {withCredentials:true})
                .then(res => res.data)
                .then(data => {
                    console.log(JSON.parse(data.data))
                    let temp_all_headlines_json = JSON.parse(data.data);
                    let temp_all_headlines = Object.keys(temp_all_headlines_json).map((key) => { return temp_all_headlines_json[key] }).flat();
                    setHeadlinesToDisplay(temp_all_headlines)
                    setHeadlinesLoadingBtn(false)
                })
                .catch(err => {
                    setHeadlinesLoadingBtn(false)
                    toast.error("Please try after some time.");
                    console.log(err);
                })
            }
        }
        catch(error){
            toast.error("Please try after some time.");
            console.log(error);
        }
    }


    const get_more_variations_of_headline = (headline) => {
        toast.success("Generating Variations");
        let additional_prompt = `Headline : ${headline}. \nProvide 5 variation of above headline in less than ${selected_channel.value === "google" ? "30" : "50"} characters.`
        axios.post(app_url+"/recommendations_for_creative_generation",{
            new_message:`${additional_prompt}. Response should be in json format and strictly follow this format: {'headline':['headline_1','headline_2','headline_3','headline_4']}`, 
            old_message:[],
            format:"json_object",
            send_business_context:false,
            send_business_reviews:false,
        }, {withCredentials:true})
        .then(res => res.data)
        .then(data => {
            console.log(JSON.parse(data.data))
            let temp_all_headlines_json = JSON.parse(data.data);
            let more_variations = Object.keys(temp_all_headlines_json).map((key) => { return temp_all_headlines_json[key] }).flat();
            let temp_all_headlines = [...all_headlines_to_display,...more_variations];
            toast.success("Variations Generated");
            setHeadlinesToDisplay(temp_all_headlines);
        })
        .catch(err => {
            toast.error("Please try after some time.");
            console.log(err);
        })
    }

    useEffect(() => {
        const handleObserver = (entities) => {
            const target = entities[0];
            console.log("-----------------------")
            console.log("Target")
            console.log(target)
            console.log("-----------------------")
            if (target.isIntersecting) {
                setPage((page) => {
                    const nextPage = page + 1;
                    loadMoreImages(product_creative_image_urls, nextPage);
                    return nextPage;
                });
            }
        };

        const observer = new IntersectionObserver(handleObserver, {
            root: null,
            rootMargin: '20px',
            threshold: 1.0
        });
        if (loader.current) {
            observer.observe(loader.current);
        }
    },[loader, product_creative_image_urls])

    const loadMoreImages = (images, page) => {
        const itemsPerPage = 6;
        const startIndex = (page - 1) * itemsPerPage;
        const endIndex = page * itemsPerPage;
        const newImages = images.slice(startIndex, endIndex);
        console.log("--------------------------------------------------------")
        console.log("Loader Images")
        console.log((prevImages) => [...prevImages, ...newImages])
        console.log("--------------------------------------------------------")
        setLoadedImages((prevImages) => [...prevImages, ...newImages]);
    };

    const handleSelectedHeadline = (element_id,value) => {
        
        let selected_div = document.getElementById(element_id);
        let temp_selected_headline_arr = [...selected_headline_arr];
        if(temp_selected_headline_arr.includes(value)){
            selected_div.className = "creative_ideation_headline_cards m-2"
            temp_selected_headline_arr.splice(temp_selected_headline_arr.indexOf(value),1)
        }
        else{
            selected_div.className = "selected-headline-type-div creative_ideation_headline_cards m-2"
            temp_selected_headline_arr.push(value)
        }
        // console.log(temp_selected_headline_arr)
        setSelectedHeadline(temp_selected_headline_arr);
    }

    const generate_creative = (e) => {
        e.preventDefault()
    
        try {
            if(Cookies.get('shop')){
                axios.post(app_url+"/api/shopify/generate_creative_meta",
                {
                    "headline_arr":selected_headline_arr,
                }, {withCredentials:true})
                pollCreativeStatus();
            }
        }
        catch(err){
            setCreativeButtonLoading(false);
            toast.error("Please try after some time.");
            console.log(err)
        }
    }

    const pollCreativeStatus = () => {
        setCreativeButtonLoading(true);
        const timeout = 60000; // 60 seconds
        const pollInterval = 5000; // 5 seconds
        const startTime = Date.now();
        var current_remaining_headline = selected_headline_arr;
        const intervalId = setInterval(async () => {
            try {
                const response = await axios.get(app_url+`/api/shopify/get_pending_creative?headline_arr=${JSON.stringify(current_remaining_headline)}`, {withCredentials:true});
                if(response.data.success){
                    const { creatives_arr,remaining_headline,status } = response.data.data;
                    console.log(current_remaining_headline)
                    console.log(remaining_headline)
                    current_remaining_headline = remaining_headline;
                    let temp_product_creative_image_urls = [product_creative_image_urls,creatives_arr].flat();
                    setProductCreativeImageURLs([...new Set(temp_product_creative_image_urls)])
                    if (status === "completed") {
                        clearInterval(intervalId);
                        console.log('Polling Completed.');
                        setCreativeButtonLoading(false);
                    }
                }
                if (Date.now() - startTime > timeout) {
                    clearInterval(intervalId);
                    console.error('Polling timed out.');
                    setCreativeButtonLoading(false);
                }   
            } catch (error) {
                setCreativeButtonLoading(false);
                console.error('Error checking status:', error);
            }
        }, pollInterval);
    };

    return (
        <div className='main-container'>
            {!shop_domain?
                <div className='go_to_shopify_admin_div'>
                    <h5 style={{display:'inline-block'}}>!! Go to Shopify Admin and open our app !!</h5>
                </div>
                :
                <>
                    { !loading ?
                        <>
                            <div className='main-title mt-4'>
                                <h2>Creative Ideation</h2>
                            </div>

                            <Container className='mt-2'>
                                <Row className='justify-content-md-left' lg={4} xs={1}>
                                    <Col className='p-2'>
                                        <Label className="charts_filter_label">Channel</Label>
                                        <br />
                                        <ReMultiSelect 
                                            id="channel_value"
                                            closeMenuOnSelect={true}
                                            components={ReMultiSelectAnimated()}
                                            value={selected_channel}
                                            options={[
                                                {"value":"facebook","label": "Facebook"},
                                                {"value":"google","label": "Google"},
                                            ]}
                                            onChange={(selected_vals) => {
                                                setSelectedChannel(selected_vals)
                                                setDropdown1({value:"None",label:"None"})
                                                setDropdown2([])
                                                setSelectedTheme(null);
                                            }}
                                        />
                                    </Col>
                                    <Col className='p-2'>
                                        <Label className="charts_filter_label">Category</Label>
                                        <br />
                                        <ReMultiSelect 
                                            id="scattered_tags_value"
                                            closeMenuOnSelect={true}
                                            components={ReMultiSelectAnimated()}
                                            value={selected_dropdown_1}
                                            options={channel_dropdown_options[selected_channel["value"]]}
                                            onChange={(selected_vals) => {
                                                setDropdown1(selected_vals)
                                                setDropdown2([])
                                                setSelectedTheme(null);
                                            }}
                                        />
                                    </Col>
                                    <Col className='p-2'>
                                        <Label className="charts_filter_label">Subcategory</Label>
                                        <br />
                                        <ReMultiSelect 
                                            style={{height:"300px"}}
                                            id="scattered_tags_value"
                                            closeMenuOnSelect={true}
                                            components={ReMultiSelectAnimated()}
                                            isMulti
                                            value={selected_dropdown_2}
                                            options={selected_dropdown_1["value"] === "groups" ? (selected_channel["value"] === "facebook" ? all_facebook_groups : all_google_groups) : all_dropdown_options[selected_channel["value"]][selected_dropdown_1["value"]]}
                                            onChange={(selected_vals) => {
                                                setDropdown2(selected_vals)
                                            }}
                                        />
                                    </Col>
                                    <Col className='p-2 d-flex align-items-end'>
                                        <Button className='enrich_bid_button' onClick={(e) => {
                                            e.preventDefault();
                                            setThemesOptions(channel_based_themes_dict[selected_channel["value"]]);
                                        }}>Apply</Button>
                                    </Col>
                                </Row>
                                
                                {selected_channel["value"] !== "None" && <h6 className='mt-4'>Select Communication Theme</h6>}
                                <Row className='justify-content-md-left' lg={10} xs={1}>
                                    {themes_options.map((theme,index) => {
                                        if((shop_domain.includes("dummy")) && (theme === "Best Sellers")){
                                            return null;
                                        }
                                        return (
                                            <Col id={"creative_theme_option_"+index} className='creative_ideation_theme_cards m-2' onClick={(e) => {
                                                e.preventDefault();
                                                document.getElementById("creative_theme_option_"+index).classList.add("selected-headline-type-div");
                                                generate_headlines_using_chatbot(theme);
                                            }}>
                                                {theme}
                                            </Col>
                                        )
                                    })}
                                </Row>
                                { headlines_loading_btn ?
                                    <div className='d-flex justify-content-center align-items-center' style={{width:"100%", height:"10rem"}}>
                                        <Spinner animation="border"/>
                                    </div>

                                :
                                    <>
                                        {all_headlines_to_display.length > 0 && 
                                            <div className={`mt-4 d-flex ${["Meta","Best Search Copies","Best Pmax Copies"].includes(selected_theme)? "justify-content-between" : "start"}`}>
                                                <h6 className='m-0'>Select Headlines</h6>
                                                {["Meta","Best Search Copies","Best Pmax Copies"].includes(selected_theme) && <p className='m-0 show_options_in_creative_btn' onClick={(e) => { 
                                                    e.preventDefault(); 
                                                    setShowAdvancedSettings(!show_advanced_settings) 
                                                }}>
                                                    Show Options
                                                </p>}
                                            </div>
                                        }
                                        { (show_advanced_settings && ["Best Search Copies","Best Pmax Copies"].includes(selected_theme)) &&
                                            <Accordion defaultActiveKey={"001"}>
                                                <Accordion.Item eventKey="000">
                                                    <Accordion.Header>Options</Accordion.Header>
                                                    <Accordion.Body>
                                                        <Row className='justify-content-md-left' lg={3} xs={1}>
                                                            <Col className='p-2'>
                                                                <Label className="charts_filter_label">Choose Relevant Google Campaigns</Label>
                                                                <ReMultiSelect 
                                                                    style={{height:"300px"}}
                                                                    id="scattered_tags_value"
                                                                    closeMenuOnSelect={true}
                                                                    components={ReMultiSelectAnimated()}
                                                                    isMulti
                                                                    value={advanced_settings_campaign_filters}
                                                                    options={all_dropdown_options["google"]["campaign_name"]}
                                                                    onChange={(selected_vals) => {
                                                                        setAdvancedSettingCampaignFilters(selected_vals)
                                                                    }}
                                                                />
                                                            </Col>
                                                            <Col className='p-2 d-flex align-items-end'>
                                                                <Button className='enrich_bid_button' onClick={(e) => {
                                                                    e.preventDefault();
                                                                    generate_headlines_using_chatbot(selected_theme);
                                                                }}>Apply</Button>
                                                            </Col>
                                                        </Row>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        }
                                        { (show_advanced_settings && ["Meta"].includes(selected_theme)) &&
                                            <Accordion className='my-3' defaultActiveKey={"01"}>
                                                <Accordion.Item eventKey="00">
                                                    <Accordion.Header>Options</Accordion.Header>
                                                    <Accordion.Body>
                                                        <Row className='justify-content-md-left' lg={3} xs={1}>
                                                            <Col className='p-2'>
                                                                <Label className="charts_filter_label">Choose Relevant Meta Campaigns</Label>
                                                                <ReMultiSelect 
                                                                    style={{height:"300px"}}
                                                                    id="scattered_tags_value"
                                                                    closeMenuOnSelect={true}
                                                                    components={ReMultiSelectAnimated()}
                                                                    isMulti
                                                                    value={advanced_settings_campaign_filters}
                                                                    options={all_dropdown_options["google"]["campaign_name"]}
                                                                    onChange={(selected_vals) => {
                                                                        setAdvancedSettingCampaignFilters(selected_vals)
                                                                    }}
                                                                />
                                                            </Col>
                                                            <Col className='p-2 d-flex align-items-end'>
                                                                <Button className='enrich_bid_button' onClick={(e) => {
                                                                    e.preventDefault();
                                                                    generate_headlines_using_chatbot(selected_theme);
                                                                }}>Apply</Button>
                                                            </Col>
                                                        </Row>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        }
                                        <Row className='justify-content-md-left' lg={5} xs={1}>
                                            {all_headlines_to_display.map((headline,index) => {
                                                return (
                                                    <Col id={"creative_headlines_div_"+index} className='creative_ideation_headline_cards m-2' onClick={(e) => {
                                                        e.preventDefault();
                                                        handleSelectedHeadline("creative_headlines_div_"+index, headline.length > 0 ? (headline[0].toUpperCase() + headline.slice(1)) : headline)
                                                    }}>
                                                        <p className="m-0">{ headline.length > 0 ? (headline[0].toUpperCase() + headline.slice(1)) : headline}</p>
                                                        <img src={settings_icon} alt='Edit' width={15} onClick={(e) => {
                                                            e.preventDefault();
                                                            get_more_variations_of_headline(headline);
                                                        }}/>
                                                    </Col>
                                                )
                                            })}
                                        </Row>
                                    </>
                                }
                            </Container>
                            {all_headlines_to_display.length > 0 && 
                                <div className='mt-2'>
                                    { ! creative_button_loading ?
                                        <Button className='mx-2' style={{width:"11rem", height:"3rem"}} variant='primary' onClick={generate_creative}>Generate Creatives</Button>
                                        :
                                        <Button className='mx-2' style={{width:"11rem", height:"3rem"}} variant='primary' disabled >
                                            <div className='main_conatiner_spinner'>
                                                <Spinner size='sm' animation="border"/> &nbsp; Generating
                                            </div>
                                        </Button>
                                    }
                                </div>
                            }

                            { product_creative_image_urls.length > 0 ?
                                <>
                                <h6 className='mx-2'>Select Creative(s)</h6>
                                <Container className='my-4 mx-2' style={{backgroundColor:"white", width:"98%", borderRadius:"0.5rem", padding:"10px 20px"}}>
                                    <Row className='justify-content-md-left' lg={3} xs={1}>
                                        {loadedImages.map((val,idx) => {
                                            return (
                                                <Col className='p-3'>
                                                    <Card className='product-creative-image-div' style={{height:"350px", position:"relative", overflow:"hidden",boxShadow:"5px 5px 10px rgba(17,44,123,.1)"}}  key={idx} onClick={(e) => {
                                                        e.preventDefault();
                                                        let prod_creative_div_ele = document.getElementsByClassName("product-creative-image-div"); 
                                                        let temp_selected_creative_idx_arr= selected_product_creative_image_idx_arr;
                                                        console.log(selected_product_creative_image_idx_arr)
                                                        if(temp_selected_creative_idx_arr.includes(idx)){
                                                            temp_selected_creative_idx_arr = temp_selected_creative_idx_arr.filter((creative_val_idx) => creative_val_idx !== idx);
                                                            Array.from(prod_creative_div_ele).forEach((el,el_idx) => {
                                                                el.className = temp_selected_creative_idx_arr.includes(el_idx) ? "selected-creative-border product-creative-image-div" : "product-creative-image-div";
                                                            });
                                                            setSelectedProductCreativeImageIndex(temp_selected_creative_idx_arr); 
                                                        }
                                                        else{
                                                            temp_selected_creative_idx_arr.push(idx);
                                                            Array.from(prod_creative_div_ele).forEach((el,el_idx) => {
                                                                el.className = temp_selected_creative_idx_arr.includes(el_idx) ? "selected-creative-border product-creative-image-div" : "product-creative-image-div";
                                                            });
                                                            setSelectedProductCreativeImageIndex(temp_selected_creative_idx_arr); 
                                                        }
                                                    }}>
                                                    <Card.Img variant="top" height={350} src={val} />
                                                    </Card>
                                                </Col>
                                            )
                                        })}
                                        <div ref={loader}></div>
                                    </Row>
                                </Container>
                                {/* <div style={{textAlign:"end", width:"99.5%"}}>
                                    { ! save_to_sheet_loading ?
                                        <Button className='mx-2' style={{width:"11rem", height:"3rem"}} variant='primary' onClick={save_to_sheet}>Upload To Sheet</Button> 
                                        :
                                        <Button className='mx-2' style={{width:"11rem", height:"3rem"}} variant='primary' disabled >
                                            <div className='main_conatiner_spinner'>
                                                <Spinner size='sm' animation="border"/> &nbsp; Uploading
                                            </div>
                                        </Button>
                                    }
                                </div> */}

                                </>
                                :
                                ""
                            }
                        </>

                        :
                        <div className='main_conatiner_spinner'>
                            <Spinner animation="border"/>
                        </div>
                    }
                </>
            }
        </div>
    )

}

export default MetaCreativeGeneration

function formatDate(date, is_increase_date=false) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + (is_increase_date? d.getDate() + 1: d.getDate()),
      year = d.getFullYear();
  
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
  
    return [year, month, day].join('-');
}

function get_dropdown_options(insights_data, asset_data){
    let dropdown_options = {
        "None":{
            "None":[],
        },
        "google":{
            "None":[],
            "groups":[
                {value:"Brand",label:"Brand"},
                {value:"Competition",label:"Competition"},
            ],
            "campaign_name":[],
            "adgroup_name":[],
        },
        "facebook":{
            "None":[],
            "groups":[
                {value:"Remarketing",label:"Remarketing"},
                {value:"Prospecting",label:"Prospecting"},
            ],
            "campaign_name":[],
            "adset_name":[],
        }
    };
    let fb_campaign_name_arr = alasql(`SELECT ARRAY(DISTINCT campaign_name) AS campaign_name FROM ?`,[insights_data])[0]["campaign_name"];
    fb_campaign_name_arr.forEach((val) => {
        dropdown_options["facebook"]["campaign_name"].push({value:val,label:val})
    })
    let fb_adset_name_arr = alasql(`SELECT ARRAY(DISTINCT adset_name) AS adset_name FROM ?`,[insights_data])[0]["adset_name"];
    fb_adset_name_arr.forEach((val) => {
        dropdown_options["facebook"]["adset_name"].push({value:val,label:val})
    })
    let google_campaign_name_arr = alasql(`SELECT ARRAY(DISTINCT campaign_name) AS campaign_name FROM ?`,[asset_data])[0]["campaign_name"];
    google_campaign_name_arr.forEach((val) => {
        dropdown_options["google"]["campaign_name"].push({value:val,label:val})
    })
    let google_adgroup_name_arr = alasql(`SELECT ARRAY(DISTINCT ad_group_name) AS ad_group_name FROM ?`,[asset_data])[0]["ad_group_name"];
    google_adgroup_name_arr.forEach((val) => {
        dropdown_options["google"]["adgroup_name"].push({value:val,label:val})
    })
    return dropdown_options
}


function get_google_analysis_data(all_google_asset_data, all_google_keyword_data, selected_dropdown_1, selected_dropdown_2){
    let filtered_data = all_google_asset_data;
    console.log(selected_dropdown_2)
    if(selected_dropdown_1.value === "None"){
        filtered_data = alasql(`SELECT * FROM ? WHERE asset_field_type = 'HEADLINE' ORDER BY impressions DESC`,[filtered_data]);
    }
    else if(selected_dropdown_1.value === "groups"){
        filtered_data = alasql(`SELECT * FROM ? WHERE (asset_field_type = 'HEADLINE') ORDER BY impressions DESC`,[filtered_data]);
        filtered_data = alasql(`SELECT * FROM ? WHERE (${selected_dropdown_2.map((val) => val.value).join(" OR ")}) ORDER BY impressions DESC`,[filtered_data]);
    }
    else if(selected_dropdown_1.value === "campaign_name"){
        filtered_data = alasql(`SELECT * FROM ? WHERE ((asset_field_type = 'HEADLINE') AND (campaign_name IN ('${selected_dropdown_2.map((val) => val.value.replaceAll("'","''")).join("','")}'))) ORDER BY impressions DESC`,[filtered_data]);
    }
    else if(selected_dropdown_1.value === "adgroup_name"){
        filtered_data = alasql(`SELECT * FROM ? WHERE ((asset_field_type = 'HEADLINE') AND (ad_group_name IN ('${selected_dropdown_2.map((val) => val.value.replaceAll("'","''")).join("','")}'))) ORDER BY impressions DESC`,[filtered_data]);
    }
    filtered_data = alasql(`SELECT asset_text, FIRST(campaign_name) as campaign_name, FIRST(ad_group_name) as ad_group_name, SUM(impressions) as impressions FROM ? GROUP BY asset_text ORDER BY impressions DESC LIMIT 4`,[filtered_data]);
    filtered_data = alasql(`SELECT ARRAY(asset_text) as headlines, ARRAY(campaign_name) as campaign_name, ARRAY(ad_group_name) as ad_group_name, Array(impressions) as impressions FROM ?`,[filtered_data])[0];
    
    // for keywords
    if(selected_dropdown_1.value === "adgroup_name"){
        let temp_asset_data = alasql(`SELECT * FROM ? WHERE ((ad_group_name IN ('${selected_dropdown_2.map((val) => val.value.replaceAll("'","''")).join("','")}'))) ORDER BY impressions DESC LIMIT 1`,[all_google_keyword_data]);
        temp_asset_data = alasql(`SELECT keyword_text, match_type, SUM(impressions) as impressions FROM ? GROUP BY keyword_text, match_type`,[temp_asset_data]);
        filtered_data["keywords"] = alasql(`SELECT keyword_text FROM ? ORDER BY impressions DESC LIMIT 1`,[temp_asset_data])[0]["keyword_text"];
        filtered_data["match_type"] = alasql(`SELECT match_type FROM ? ORDER BY impressions DESC LIMIT 1`,[temp_asset_data])[0]["match_type"];
    }

    console.log(filtered_data)
    return [filtered_data];
}

function get_meta_analysis_data(insight_data, creative_data, selected_dropdown_1, selected_dropdown_2){
    let temp_insights_data = alasql("SELECT ad_name, FIRST(campaign_id) AS campaign_id, FIRST(adset_id) AS adset_id, FIRST(campaign_name) AS campaign_name, FIRST(adset_name) AS adset_name, FIRST(ad_id) AS ad_id, SUM(CAST(spend AS FLOAT)) AS spend, SUM(CAST(purchase_value AS FLOAT)) AS purchase_value, SUM(CAST(impressions AS FLOAT)) AS impressions, SUM(CAST(clicks AS FLOAT)) AS clicks FROM ? GROUP BY ad_name", [insight_data]);
    let filtered_data = alasql("SELECT * FROM ? AS arr1 LEFT JOIN ? AS arr2 ON arr1.ad_id = arr2.ad_id ", [creative_data, temp_insights_data]);
    filtered_data = alasql("SELECT * FROM ? ORDER BY ad_name, spend", [filtered_data]);
    // filtered_data = alasql("SELECT ad_name, FIRST(campaign_id) AS campaign_id, FIRST(adset_id) AS adset_id, FIRST(campaign_name) AS campaign_name, FIRST(adset_name) AS adset_name, FIRST(ad_id) AS ad_id, FIRST(creative_id) AS creative_id, FIRST(title) AS title, FIRST(descriptor) AS descriptor, SUM(CAST(spend AS FLOAT)) AS spend, SUM(CAST(purchase_value AS FLOAT)) AS purchase_value, SUM(CAST(impressions AS FLOAT)) AS impressions, SUM(CAST(clicks AS FLOAT)) AS clicks FROM ? GROUP BY ad_name", [filtered_data]);
    if(selected_dropdown_1.value === "None"){
        filtered_data = alasql(`SELECT * FROM ? ORDER BY COALESCE(CAST(spend AS FLOAT), 0) DESC LIMIT 4`,[filtered_data]);
    }
    else if(selected_dropdown_1.value === "groups"){
        filtered_data = alasql(`SELECT * FROM ? WHERE ${selected_dropdown_2.map((val) => val.value).join(" OR ")} ORDER BY COALESCE(CAST(spend AS FLOAT), 0) DESC LIMIT 4`,[filtered_data]);
        console.log(filtered_data)
    }
    else if(selected_dropdown_1.value === "campaign_name"){
        filtered_data = alasql(`SELECT * FROM ? WHERE ((campaign_name IN ('${selected_dropdown_2.map((val) => val.value.replaceAll("'","''")).join("','")}'))) ORDER BY COALESCE(CAST(spend AS FLOAT), 0) DESC LIMIT 4`,[filtered_data]);
    }
    else if(selected_dropdown_1.value === "adset_name"){
        filtered_data = alasql(`SELECT * FROM ? WHERE ((adset_name IN ('${selected_dropdown_2.map((val) => val.value.replaceAll("'","''")).join("','")}'))) ORDER BY COALESCE(CAST(spend AS FLOAT), 0) DESC LIMIT 4`,[filtered_data]);
    }
    console.log(filtered_data)
    return filtered_data;
}


function get_shopify_analysis_data(order_data, group_by){
    let temp_analysis_data = [];
    if(group_by === "city"){
        temp_analysis_data = alasql(`SELECT ${group_by}, SUM(CAST(total_price AS FLOAT)) AS revenue FROM ? GROUP BY ${group_by} ORDER BY revenue DESC LIMIT 5`, [order_data]);
    }
    else if(group_by === "product_title"){
        let product_data = [];
        order_data.forEach((val)=>{
            val[group_by].split("<br />").forEach((product)=>{
                product_data.push({"product_title":product})
            })
        })
        temp_analysis_data = alasql(`SELECT product_title, COUNT(product_title) AS total_order FROM ? GROUP BY product_title ORDER BY total_order DESC LIMIT 5`, [product_data]);
        
    }

    console.log(temp_analysis_data)

    return temp_analysis_data;
}