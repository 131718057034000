import React, {useState,useEffect} from 'react'
import { Chart } from "react-google-charts";
import Spinner from 'react-bootstrap/Spinner';
import Container from 'react-bootstrap/Container';
import Label from 'react-bootstrap/FormLabel';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import { Link } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion';
import FormSelect from 'react-bootstrap/FormSelect';
import { DateRangePicker } from "rsuite";
import ReMultiSelect from 'react-select';
import ReMultiSelectAnimated from 'react-select/animated';
import axios from 'axios';
import Cookies from 'js-cookie';
import {showToast as toast} from '../toastManager';
import alasql from 'alasql';


alasql.fn.ReplaceNullValues = function(val){
  return ((val === Infinity) || (val === undefined)) ? 0 : val;
}

alasql.fn.Add_Image_HTML_tag = function(val){
  return `<img src="${val}" alt="Image" width=50 height=50> </img>`;
}

const all_scattered_charts_type = {
  "Keyword": [
    { value: 'clicks', label: 'Clicks' },
    { value: 'conversions', label: 'Conv.' },
    { value: 'conversions_value', label: 'Conv. value' },
    { value: 'all_conversions', label: 'All Conv.' },
    { value: 'all_conversions_value', label: 'All Conv. value' },
    { value: 'cost', label: 'Cost' },
    { value: 'impressions', label: 'Impr.' },
  ],
  "Ad group": [
    { value: 'clicks', label: 'Clicks' },
    { value: 'conversions', label: 'Conv.' },
    { value: 'all_conversions', label: 'All Conv.' },
    { value: 'conversions_value', label: 'Conv. value' },
    { value: 'all_conversions_value', label: 'All Conv. value' },
    { value: 'cost', label: 'Cost' },
    { value: 'impressions', label: 'Impr.' },
  ],
}


function LandingPageOptimization({ViewedPage, shop_domain, app_url}) {
    ViewedPage(19);

    const { combine, before, afterToday } = DateRangePicker;
    const max_date = new Date();
    max_date.setDate(max_date.getDate() - 365);

    const [loading, setLoading] = useState(true);
    const [analyze_btn_loading, setAnalyzeBtnLoading] = useState(false);
    const [generate_btn_loading, setGenerateBtnLoading] = useState(false);
    const [show_landing_page, setShowLandingPageURL] = useState(false);
    const [title_x_axis, setTitleXAxis] = useState("Cost");
    const [title_y_axis, setTitleYAxis] = useState("Clicks");
    const [x_axis, setXAxis] = useState({ value: 'cost', label: 'Cost' });
    const [y_axis, setYAxis] = useState({ value: 'clicks', label: 'Clicks' });
    const [price_range, setPriceRange] = useState([["All","All"]]);
    const [landing_page_exp, setAdStrengthValue] = useState("All");
    const [scattered_plot_type, setScatteredPlotType] = useState("Keyword");
    const [all_keywords, setAllKeywords]  = useState([]);
    const [selected_keywords, setSelectedKeywords] = useState("");
    const [scatter_overview_data, setScatterOverviewData] = useState([]);
    const [suggestions, setSuggestions] = useState([]);
    const [selected_suggestion, setSelectedSuggestion] = useState("");
    const [table_data_level, setTableDataLevel] = useState("Ad group");
    const [custom_input, setCustomInput] = useState("");
    const [filter_date, setFilterDate] = useState([]);
    const [keyword_level_data, setKeywordLevelData] = useState([]);
    const [ad_group_level_data, setAdGroupLevelData] = useState([]);

    const [current_metric_options, setCurrentMetricOptions] = useState(all_scattered_charts_type[scattered_plot_type]);

    useEffect(() => {
      console.log("In Report",shop_domain)
      setLoading(true);
      try {
          if(Cookies.get('shop')){
              // Facebook Loader data
              var created_at_max = new Date();
              var created_at_min = new Date(new Date().setDate(created_at_max.getDate() - 30));
              setFilterDate([created_at_min, created_at_max]);
              axios.post(app_url+"/api/google/get_landing_page_optimization_data",{
                  shop_domain:shop_domain,
                  date_start:formatDate(created_at_min),
                  date_end:formatDate(created_at_max),
                  func_call_type:"redis",
              }, {withCredentials:true})
              .then(res => res.data)
              .then(data => {
                  console.log(data)
                  let keyword_level_data = data["data"]["lp_keywords_data"];
                  let ad_group_level_data = data["data"]["lp_ad_group_data"];
                  setKeywordLevelData(keyword_level_data);
                  setAdGroupLevelData(ad_group_level_data);
                  let temp_all_keywords = get_all_keywords(ad_group_level_data)
                  setAllKeywords(temp_all_keywords)
                  setSelectedKeywords(temp_all_keywords[0])
                  setScatterOverviewData(get_scatter_overview_data(keyword_level_data, 'clicks', 'cost', [['All','All']], 'All', "Keyword"))
                  
                  setAnalyzeBtnLoading(false);
                  setGenerateBtnLoading(false);
                  setShowLandingPageURL(false);
                  setTitleXAxis("Cost");
                  setTitleYAxis("Clicks");
                  setXAxis({ value: 'cost', label: 'Cost' });
                  setYAxis({ value: 'clicks', label: 'Clicks' });
                  setPriceRange([["All","All"]]);
                  setAdStrengthValue("All");
                  setScatteredPlotType("Keyword");
                  setSuggestions([]);
                  setSelectedSuggestion("");
                  setTableDataLevel("Ad group");
                  setCustomInput("");

                  setLoading(false)
              })
              .catch(err => {
                  setLoading(false)
                  toast.error("Please try after some time.");
                  console.log(err);
              })
          }
      } catch (error) {
          setLoading(false)
          toast.error("Please try after some time.");
          console.log(error);
      }

  }, [app_url, shop_domain]);


  const handleDateChange = async (date_range) => {
    setLoading(true)
    if((date_range !== undefined) && (date_range !== null) && (date_range.length > 0)){
        setFilterDate(date_range);
        var created_at_max = formatDate(date_range[1]);
        var created_at_min =  formatDate(date_range[0]);
        axios.post(app_url+"/api/google/get_landing_page_optimization_data",{
          shop_domain:shop_domain,
          date_start:formatDate(created_at_min),
          date_end:formatDate(created_at_max),
          func_call_type:"db",
      }, {withCredentials:true})
      .then(res => res.data)
      .then(data => {
          console.log(data)
          let keyword_level_data = data["data"]["lp_keywords_data"];
          let ad_group_level_data = data["data"]["lp_ad_group_data"];
          setKeywordLevelData(keyword_level_data);
          setAdGroupLevelData(ad_group_level_data);
          let temp_all_keywords = get_all_keywords(ad_group_level_data)
          setAllKeywords(temp_all_keywords)
          setSelectedKeywords(temp_all_keywords[0])
          setScatterOverviewData(get_scatter_overview_data(keyword_level_data, 'clicks', 'cost', [['All','All']], 'All', "Keyword"))
          
          setAnalyzeBtnLoading(false);
          setGenerateBtnLoading(false);
          setShowLandingPageURL(false);
          setTitleXAxis("Cost");
          setTitleYAxis("Clicks");
          setXAxis({ value: 'cost', label: 'Cost' });
          setYAxis({ value: 'clicks', label: 'Clicks' });
          setPriceRange([["All","All"]]);
          setAdStrengthValue("All");
          setScatteredPlotType("Keyword");
          setSuggestions([]);
          setSelectedSuggestion("");
          setTableDataLevel("Ad group");
          setCustomInput("");

          setLoading(false)
      })
      .catch(err => {
          setLoading(false)
          toast.error("Please try after some time.");
          console.log(err);
      })
    }
    else{
        setFilterDate([]);
    }
}


    const handleSuggestionSelect = ({chartWrapper}) => {
      const chart = chartWrapper.getChart();
      const selection = chart.getSelection();
      if (selection.length === 0) return;
      const rowIndex = selection[0].row;
      console.log(suggestions[rowIndex+1]);
      setSelectedSuggestion(suggestions[rowIndex+1]);
    };

  
    const handleSaveDescriptionToSheet = (e) => {
      e.preventDefault();
      setGenerateBtnLoading(true);
      try {
        if(Cookies.get('shop')){
            axios.post(app_url+"/api/shopify/send_landing_page_to_sheet",{
              sheet_data: ((custom_input.trim() !== "") && (custom_input !== null) && (custom_input !== undefined)) ? [custom_input] : selected_suggestion,
            }, {withCredentials:true})
            .then(request => request.data)
            .then(data => {
                setTimeout(() => {
                  toast.success("Added")
                  setShowLandingPageURL(true);
                  setGenerateBtnLoading(false);
                }, 30000)
            })
            .catch(err => {
              setGenerateBtnLoading(false);
                toast.error("No Data to Show.");
                console.log(err)
              });
            }
          }
      catch(err){
        setGenerateBtnLoading(false);
          toast.error("No Data to Show.");
          console.log(err)
      }
    }

    const handleAnalyze = (e) => {
      e.preventDefault();
      setAnalyzeBtnLoading(true);
      try{
          axios.post(app_url+"/chats",{
              new_message:`Keyword : ${selected_keywords} \n\n Using above details, Provide 5 ad copies description in less than 120 characters. Make sure description follows intent of above keyword. And each description uses a different communication theme. Response should in json format. Example, {"Description":["Description 1","Description 2","Description 3","Description 4","Description 5"]}.`, 
              old_message:[],
              format:"json_object"
          }, {withCredentials:true})
          .then(res => res.data)
          .then(data => {
              console.log(JSON.parse(data.data))
              let temp_suggestions_key = Object.keys(JSON.parse(data.data))[0];
              let temp_suggestions = [[["Page Descriptions"]] , JSON.parse(data.data)[temp_suggestions_key].map((val) => { return [val] })].flat();
              console.log("Suggestion",temp_suggestions)
              setSuggestions(temp_suggestions)
              setAnalyzeBtnLoading(false)
          })
          .catch(err => {
              setAnalyzeBtnLoading(false)
              toast.error("Please try after some time.");
              console.log(err);
          })
      }
      catch(error){
          toast.error("Please try after some time.");
          console.log(error);
      }
  }

    return (
        <div className='main-container'>
            {!shop_domain?
                <div className='go_to_shopify_admin_div'>
                    <h5 style={{display:'inline-block'}}>!! Go to Shopify Admin and open our app !!</h5>
                </div>
                :
                <>
                    { !loading ?
                        <>
                            <div className='main-title mt-4'>
                                <h2>Keyword Intent Optimization</h2>
                            </div>

                            <Container className='my-4'>
                                <Row className='justify-content-md-left' lg={3} xs={1}>
                                    <Col className='p-2'>
                                        <Label className="charts_filter_label">Time Period</Label>
                                        <br />
                                        <DateRangePicker shouldDisableDate={combine(before(max_date), afterToday())} editable={false} value={filter_date} id='date_range' format="yyyy-MM-dd" character=" - " showOneCalendar block onChange={handleDateChange} />
                                    </Col>
                                </Row>
                                <Row className='justify-content-md-left' lg={6} xs={1}>
                                    <Col className='p-2'>
                                        <Label className="charts_filter_label">Data Level</Label>
                                        <ReMultiSelect 
                                            id="data_level"
                                            className='popup_filter_include_exclude'
                                            closeMenuOnSelect={true}
                                            components={ReMultiSelectAnimated()}
                                            value={{ value: scattered_plot_type, label: scattered_plot_type }}
                                            options={[
                                              { value: "Keyword", label: "Keyword" },
                                              { value: "Ad group", label: "Ad group" },
                                            ]}
                                            onChange={(selected_vals) => {
                                              setScatteredPlotType(selected_vals.value);
                                              setXAxis({ value: 'cost', label: 'Cost' });
                                              setYAxis({ value: 'clicks', label: 'Clicks' });
                                              setCurrentMetricOptions(all_scattered_charts_type[selected_vals.value]);
                                              setAdStrengthValue("All");
                                            }}
                                        />
                                    </Col>
                                    <Col className='p-2'>
                                        <Label className="charts_filter_label">X-Axis</Label>
                                        <ReMultiSelect 
                                            id="x_axis"
                                            className='popup_filter_include_exclude'
                                            closeMenuOnSelect={true}
                                            components={ReMultiSelectAnimated()}
                                            value={x_axis }
                                            options={current_metric_options}
                                            onChange={(selected_vals) => {
                                              setXAxis(selected_vals);
                                            }}
                                        />
                                    </Col>
                                    <Col className='p-2'>
                                        <Label className="charts_filter_label">Y-Axis</Label>
                                        <ReMultiSelect 
                                            id="y_axis"
                                            className='popup_filter_include_exclude'
                                            closeMenuOnSelect={true}
                                            components={ReMultiSelectAnimated()}
                                            value={ y_axis }
                                            options={current_metric_options}
                                            onChange={(selected_vals) => {
                                              setYAxis(selected_vals);
                                            }}
                                        />
                                    </Col>
                                    {/* <Col className='p-2'>
                                        <Label className="charts_filter_label">Cost Filter</Label>
                                        <ReMultiSelect 
                                            className='popup_filter_include_exclude'
                                            closeMenuOnSelect={true}
                                            components={ReMultiSelectAnimated()}
                                            value={[{ value: price_range[0][0] !== "All"? price_range[0][0]+'-'+price_range[0][1] : "All", label: price_range[0][0] !== "All"? price_range[0][0]+'-'+price_range[0][1] : "All" }]}
                                            options={[
                                                { value: 'All', label: 'All' },
                                                { value: '0-50', label: '0-50' },
                                                { value: '50-100', label: '50-100' },
                                                { value: '100-150', label: '100-150' },
                                                { value: '150-200', label: '150-200' },
                                                { value: '200-250', label: '200-250' },
                                                { value: '250-300', label: '250-300' },
                                                { value: '300-350', label: '300-350' },
                                                { value: '350-400', label: '350-400' },
                                                { value: '400-450', label: '400-450' },
                                                { value: '450-500', label: '450-500' },
                                                { value: '500-100000', label: '500+' },
                                            ]}
                                            onChange={(selected_vals) => {
                                                var temp_list = [];
                                                if(selected_vals.value !== "all"){
                                                    temp_list.push([selected_vals.value.split('-')[0], selected_vals.value.split('-')[1]]);
                                                    setPriceRange(temp_list);
                                                }
                                                else{
                                                    setPriceRange(["all","all"]);
                                                }
                                            }}
                                        />
                                    </Col> */}
                                    <Col className='p-2'>
                                        <Label className="charts_filter_label">Creative Quality Score</Label>
                                        <ReMultiSelect 
                                            id="y_axis"
                                            className='popup_filter_include_exclude'
                                            closeMenuOnSelect={true}
                                            components={ReMultiSelectAnimated()}
                                            value={{ value: landing_page_exp, label: landing_page_exp }}
                                            options={[
                                                { value: 'All', label: 'All' },
                                                { value: 'BELOW_AVERAGE', label: 'Below average' },
                                                { value: 'AVERAGE', label: 'Average' },
                                                { value: 'ABOVE_AVERAGE', label: 'Above average' },
                                            ]}
                                            onChange={(selected_vals) => {
                                              setAdStrengthValue(selected_vals.value);
                                            }}
                                        />
                                    </Col>
                                    <Col className='p-2 d-flex align-items-end'>
                                        <Button className='d-flex align-items-center justify-content-center' style={{backgroundColor:"white",color:"#00d96d",border:"none",boxShadow:"0 2px 6px rgba(71,86,102,.05)",fontWeight:'500'}} onClick={() => {
                                            setLoading(true);
                                            setTimeout(()=> {
                                              setTitleXAxis(x_axis["label"])
                                              setTitleYAxis(y_axis["label"])
                                              setScatterOverviewData(get_scatter_overview_data(scattered_plot_type === "Keyword" ? keyword_level_data : ad_group_level_data, x_axis["value"], y_axis["value"], price_range, landing_page_exp, scattered_plot_type))
                                              setLoading(false);
                                            }, 500)
                                        }}>Apply</Button>
                                    </Col>
                                </Row>
                            </Container>

                            <div className='cro-overview mb-5'>
                                { scatter_overview_data.length > 0 ?
                                    <Chart
                                        chartType="ScatterChart"
                                        width="100%"
                                        height="500px"
                                        data={scatter_overview_data}
                                        options={{
                                            pointSize:10,
                                            chartArea: {
                                                width: "70%",
                                                height: "80%"
                                            },
                                            hAxis: { 
                                                title:'',
                                                viewWindow: {
                                                    min: 0
                                                },
                                                gridlines: {
                                                    color: '#e7e7e7',
                                                },
                                            },
                                            vAxis: {
                                              title:'',
                                                viewWindow: {
                                                    min: 0
                                                },
                                                gridlines: {
                                                    color: '#e7e7e7',
                                                },
                                            },
                                            curveType: "function",
                                            tooltip: { isHtml: true },
                                            legend: {
                                              alignment: 'center',
                                              
                                            },
                                            series: [
                                              { color: 'darkgreen', labelInLegend: 'Above average' },
                                              { color: 'red', labelInLegend: 'Below average' },
                                              { color: 'yellow', labelInLegend: 'Average' },
                                              { color: 'orange', labelInLegend: '--' },
                                            ]
                                        }}
                                    />
                                    :
                                    "No data to Show."
                                }
                            </div>

                            <Container className='my-4'>
                              <Row className='justify-content-md-left' lg={3} xs={1}>
                                <Col className='p-2'>
                                  <Label className="charts_filter_label">Data Level</Label>
                                  <ReMultiSelect 
                                      className='popup_filter_include_exclude'
                                      closeMenuOnSelect={true}
                                      components={ReMultiSelectAnimated()}
                                      value={{ value: table_data_level, label: table_data_level }}
                                      options={[
                                        { value: "Keyword", label: "Keyword" },
                                        { value: "Ad group", label: "Ad group" },
                                      ]}
                                      onChange={(selected_vals) => {
                                        setTableDataLevel(selected_vals.value);
                                        let temp_keywords = get_all_keywords(selected_vals.value === "Keyword" ? keyword_level_data : ad_group_level_data);
                                        setAllKeywords(temp_keywords)
                                        setSelectedKeywords(temp_keywords[0])
                                      }}
                                  />
                                </Col>
                                <Col className='p-2'>
                                  <Label className="charts_filter_label">Keywords</Label>
                                  <ReMultiSelect 
                                      id="select_keyword"
                                      className='popup_filter_include_exclude'
                                      closeMenuOnSelect={true}
                                      components={ReMultiSelectAnimated()}
                                      value={{ value: selected_keywords, label: selected_keywords }}
                                      options={all_keywords.map((val) => { return { value: val, label: val }})}
                                      onChange={(selected_vals) => {
                                        setSelectedKeywords(selected_vals.value);
                                      }}
                                  />
                                </Col>
                                <Col className='p-2 d-flex align-items-end'>
                                  {analyze_btn_loading ?
                                    <Button className='d-flex align-items-center justify-content-center' style={{backgroundColor:"white",color:"#00d96d",border:"none",boxShadow:"0 2px 6px rgba(71,86,102,.05)",fontWeight:'500'}}><Spinner animation="border"/></Button>
                                    :
                                    <Button className='d-flex align-items-center justify-content-center' style={{backgroundColor:"white",color:"#00d96d",border:"none",boxShadow:"0 2px 6px rgba(71,86,102,.05)",fontWeight:'500'}} onClick={handleAnalyze}>Analyze</Button>
                                  }
                              </Col>
                            </Row>

                            {suggestions.length > 0 &&
                              <>
                                <Row className='justify-content-md-left mt-3' lg={1} xs={1}>
                                  <Chart
                                    chartType="Table"
                                    width="100%"
                                    data={suggestions}
                                    chartEvents={[
                                      {
                                        eventName: 'select',
                                        callback: handleSuggestionSelect,
                                      },
                                    ]}
                                    options={{
                                        width: '100%',
                                        curveType: "function",
                                        legend: { position: "bottom" },
                                        allowHtml: true,
                                        cssClassNames:{
                                            tableCell :"ad_copy_optimization_table_cell",
                                            headerCell :"ad_copy_optimization_header_cell",
                                            tableRow :"ad_copy_optimization_table_row", 
                                            selectedTableRow  : "ad_copy_optimization_selected_table_row",
                                            hoverTableRow : "ad_copy_optimization_hover_table_row",
                                            oddTableRow :"ad_copy_optimization_table_row" 
                                        }
                                    }}
                                  />
                                </Row>

                                <Row className='justify-content-md-left mt-4' lg={2} xs={1}>
                                  <Col>
                                    <label id="custom_description" style={{fontWeight:"bold"}}>Custom Page Description</label>
                                    <br/>
                                    <input
                                        id="custom_description"
                                        style={{width:"65%"}}
                                        className='mt-2 custom-input-landing-page'
                                        type="text"
                                        placeholder="Type here ..."
                                        value={custom_input}
                                        onChange={(e) => setCustomInput(e.target.value)}
                                    />
                                  </Col>
                                </Row>
                                <Row className='justify-content-md-left mt-3' lg={2} xs={1}>
                                  <Col style={{textAlign:"left"}}>
                                    {generate_btn_loading ?
                                      <Button className='mt-3' variant='success'><Spinner animation="border" /></Button>
                                    :
                                      <Button className='mt-3' variant='success' onClick={handleSaveDescriptionToSheet}>Generate Landing Page</Button>
                                    }
                                  </Col>
                                </Row>

                                  {show_landing_page &&
                                    <div className='px-2 py-3 mt-4' style={{width:"100%", backgroundColor:"white", color:"black", borderRadius:"5px"}} >
                                      Landing Page URL - <Link to="https://cadenceotc.com/pages/morning-after-pill-stores-near-me?88d150f-3f2e-4a0e4-87d0=5" target='_blank'>https://cadenceotc.com/pages/morning-after-pill-stores-near-me?88d150f-3f2e-4a0e4-87d0=5</Link>
                                    </div>
                                  }
                              </>
                            }
                          </Container>
                        </>
                        :
                        <div className='main_conatiner_spinner'>
                            <Spinner animation="border"/>
                        </div>
                    }
                </>
            }
        </div>
    )

}

export default LandingPageOptimization


function jsonToArrayObject(res, graph_columns) {
  var columns = Object.keys(res[0]);
  var resultArray = res.map(function (obj) {
    var res_arr = [];
    for (let column_name of columns) {
      res_arr.push(obj[column_name]);
    }
    return res_arr;
  });
  resultArray.unshift(graph_columns)
 return resultArray;
}


function get_all_keywords(keyword_level_data){
  var res = alasql(`SELECT ARRAY(DISTINCT keyword_text) as all_keywords FROM ? WHERE ([creative_quality_score] in ('BELOW_AVERAGE','AVERAGE')) AND ([campaign_name] NOT LIKE '%competition%') AND ([ad_group_name] NOT LIKE '%competition%') AND ([campaign_name] NOT LIKE '%Competition%') AND ([ad_group_name] NOT LIKE '%Competition%') AND ([keyword_text] NOT LIKE '%Plan B%') AND ([keyword_text] NOT LIKE '%plan b%') AND ([keyword_text] NOT LIKE '%Plan b%') AND ([keyword_text] NOT LIKE '%plan B%')`, [keyword_level_data]);
  console.log(res[0]["all_keywords"]);
  return res[0]["all_keywords"];
}


function get_scatter_overview_data(keyword_level_data, x_axis, y_axis, price_range, landing_page_exp, scattered_plot_type){
    var filtered_data = keyword_level_data;

    // if(price_range.length > 0){
    //   for(let price of price_range){
    //     if( price[0] !== "All"){
    //       filtered_data = alasql(`SELECT * FROM ? WHERE (([cost] >= ${price[0]}) AND ([cost] <= ${price[1]}))`, [filtered_data]);
    //     }
    //   }
    // }
    
    if(landing_page_exp !== "All"){
      filtered_data = alasql(`SELECT * FROM ? WHERE [creative_quality_score] = '${landing_page_exp}'`, [filtered_data]);
    }
    

    var res = alasql(`SELECT * FROM ?`, [filtered_data]);
    if(res.length > 0){
      var final_res = [[{label:x_axis,type:"number"},{label:"Above average",type:"number"}, {type:'string','role': 'tooltip', 'p': {'html': true}}, {label:"Below average",type:"number"}, {type:'string','role': 'tooltip', 'p': {'html': true}}, {label:"Average",type:"number"}, {type:'string','role': 'tooltip', 'p': {'html': true}}, {label:"--",type:"number"}, {type:'string','role': 'tooltip', 'p': {'html': true}}]]
      for(let obj of res ){
          final_res.push([
              obj[x_axis],
              
              obj["creative_quality_score"] === "ABOVE_AVERAGE" ? obj[y_axis] : null,
              `
              <div style="background-color:white; padding:20px; width:fit-content; border-radius:5px; box-shadow:0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)">
                  <h6 style="margin:3px;">${obj["ad_group_name"]}</h6>
                  <br>
                  <p style="margin:3px; font-size:medium;">creative_quality_score : <b>${obj["creative_quality_score"]}</b></p>
                  <p style="margin:3px; font-size:medium;">Keyword : <b>${obj["keyword_text"]}</b></p>
                  ${all_scattered_charts_type[scattered_plot_type].map((item, index) => {
                    return '<p style="margin:3px; font-size:medium;">'+ item["label"] +': <b>'+parseFloat(obj[item["value"]]).toFixed(1)+'</b></p>';
                  }).join('\n')}
              </div>
              `,
                obj["creative_quality_score"] === "BELOW_AVERAGE" ? obj[y_axis] : null,
                `
              <div style="background-color:white; padding:20px; width:fit-content; border-radius:5px; box-shadow:0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)">
                  <h6 style="margin:3px;">${obj["ad_group_name"]}</h6>
                  <br>
                  <p style="margin:3px; font-size:medium;">Creative Quality Score : <b>${obj["creative_quality_score"]}</b></p>
                  <p style="margin:3px; font-size:medium;">Keyword : <b>${obj["keyword_text"]}</b></p>
                  ${all_scattered_charts_type[scattered_plot_type].map((item, index) => {
                    return '<p style="margin:3px; font-size:medium;">'+ item["label"] +': <b>'+parseFloat(obj[item["value"]]).toFixed(1)+'</b></p>';
                  }).join('\n')}
              </div>
              `,

                obj["creative_quality_score"] === "AVERAGE" ? obj[y_axis] : null,              
                `
              <div style="background-color:white; padding:20px; width:fit-content; border-radius:5px; box-shadow:0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)">
                  <h6 style="margin:3px;">${obj["ad_group_name"]}</h6>
                  <br>
                  <p style="margin:3px; font-size:medium;">creative_quality_score : <b>${obj["creative_quality_score"]}</b></p>
                  <p style="margin:3px; font-size:medium;">Keyword : <b>${obj["keyword_text"]}</b></p>
                  ${all_scattered_charts_type[scattered_plot_type].map((item, index) => {
                    return '<p style="margin:3px; font-size:medium;">'+ item["label"] +': <b>'+parseFloat(obj[item["value"]]).toFixed(1)+'</b></p>';
                  }).join('\n')}
              </div>
              `,

                (obj["creative_quality_score"] === "--") || (obj["creative_quality_score"] === "nan") ? obj[y_axis] : null,
                `
              <div style="background-color:white; padding:20px; width:fit-content; border-radius:5px; box-shadow:0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)">
                  <h6 style="margin:3px;">${obj["ad_group_name"]}</h6>
                  <br>
                  <p style="margin:3px; font-size:medium;">creative_quality_score : <b>${obj["creative_quality_score"]}</b></p>
                  <p style="margin:3px; font-size:medium;">Keyword : <b>${obj["keyword_text"]}</b></p>
                  ${all_scattered_charts_type[scattered_plot_type].map((item, index) => {
                    return '<p style="margin:3px; font-size:medium;">'+ item["label"] +': <b>'+parseFloat(obj[item["value"]]).toFixed(1)+'</b></p>';
                  }).join('\n')}
              </div>
              `]);
      }
      console.log(final_res);
      return final_res;
    }
    return [[{label:x_axis,type:"number"},{label:"Above average",type:"number"}, {type:'string','role': 'tooltip', 'p': {'html': true}}, {label:"Below average",type:"number"}, {type:'string','role': 'tooltip', 'p': {'html': true}}, {label:"Average",type:"number"}, {type:'string','role': 'tooltip', 'p': {'html': true}}, {label:"--",type:"number"}, {type:'string','role': 'tooltip', 'p': {'html': true}}]];
}


function get_filter_date_from_data(data){
  let min_max_date_dict = alasql(`SELECT MIN(DATE(date_start)) as min_date, MAX(DATE(date_start)) as max_date FROM ?`,[data])[0];
  return [min_max_date_dict["min_date"], min_max_date_dict["max_date"]];
}


function formatDate(date, is_increase_date=false) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + (is_increase_date? d.getDate() + 1: d.getDate()),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('-');
}