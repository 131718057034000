import React, { useState, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import number_1 from '../images/number-1.png';
import number_2 from '../images/number-2.png';
import google_icon from '../images/adwords.png';
import facebook_icon from '../images/facebook.png';
import connected_icon from '../images/correct.png';
import axios from 'axios';
import Cookies from 'js-cookie';
import {showToast as toast} from '../toastManager';


const Home = ({ViewedPage, shop_domain, app_url}) => {
    ViewedPage(4)

    // Render Select Ads Account and conversion action setting
    const [show_google_login_btn, setShowGoogleLogin] = useState(true);
    const [show_facebook_login_btn, setShowFacebookLogin] = useState(true);
    const [Step1Indicator, setStep1Indicator] = useState(!show_google_login_btn);
    const [Step2Indicator, setStep2Indicator] = useState(false);
    const [google_loading, setGoogleLoading] = useState(true);
    const [facebook_loading, setFacebookLoading] = useState(true);

    const handle_sign_out = async (e) => {
        e.preventDefault();
        try {
            if(Cookies.get('shop')){
                const { data } = await axios.post(app_url+"/api/google/sign_out",{
                    shop_domain:Cookies.get('shop')
                }, {withCredentials:true});
                if(data.success){
                    toast.success(data.message);
                    setShowGoogleLogin(true);
                    setStep1Indicator(false);
                    setStep2Indicator(false);
                }
                else{
                    toast.error(data.message);
                }
            }
        } catch (error) {
            toast.error("Please try again.");
        }
    };
  
    useEffect(() => {
        setShowGoogleLogin(true)
        setShowFacebookLogin(true)
        try {
            if(Cookies.get('shop')){
                axios.post(app_url+"/api/google/get_loader_data",{
                    shop_domain:Cookies.get('shop')
                }, {withCredentials:true})
                .then(res => res.data)
                .then(data => {
                    if(data.success){
                        var temp_loader_data = data.data;
                        setShowGoogleLogin(temp_loader_data["show_google_login_btn"]);
                        setStep1Indicator(!temp_loader_data["show_google_login_btn"]);
                        setGoogleLoading(false);
                    }
                })
                .catch(error => {
                    setGoogleLoading(false);
                    toast.error("Google Data not synced.")
                })
                axios.post(app_url+"/api/facebook/get_loader_data",{
                    shop_domain:Cookies.get('shop')
                }, {withCredentials:true})
                .then(res => res.data)
                .then(data => {
                    if(data.success){
                        var temp_loader_data = data.data;
                        setShowFacebookLogin(temp_loader_data["is_show_continue_with_facebook_btn"]);                        
                        setStep2Indicator(!temp_loader_data["is_show_continue_with_facebook_btn"]);
                        setFacebookLoading(false);
                    }
                })
                .catch(error => {
                    setFacebookLoading(false);
                    toast.error(error)
                })
            }
        } catch (error) {
            setGoogleLoading(false);
            setFacebookLoading(false);
            toast.error(error)
        }
    }, [app_url, shop_domain]);
    
    return (
        <div className='main-container'>
            {!shop_domain?
                <div className='go_to_shopify_admin_div'>
                    <h6 style={{display:'inline-block'}}>!! Go to Shopify Admin and open our app !!</h6>
                </div>
                :
                <>
                    { ((google_loading !== true) && (facebook_loading !== true)) ?
                        <>
                        <div className='main-title mt-4'>
                            <h2>Google Ads Integration</h2>
                        </div>
                        <div className='main-accordion-body mt-4 p-2'>
                        <Accordion defaultActiveKey={ Step1Indicator ? Step2Indicator ? "0" : "1" : "0" }>
                            {/* Step - 1 (Google Ads Integration) */}
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                    <img src={number_1} alt='1' width={"15px"} height={"15px"} /> 
                                    <b style={{marginLeft:"10px"}}>Integrate Google Ads</b> 
                                    { Step1Indicator && <img alt='connected' style={{padding:"6px", marginRight:"10px"}} src={connected_icon} width={"30px"}/> }
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Container>
                                        <Row>
                                            <Col sm={8}>Connect Google Ads Account with Shopify to get started.</Col>
                                            <Col sm={4}>
                                            { show_google_login_btn === true &&
                                                <button style={{backgroundColor:"#4285f4", color:"white", width:"8rem",border:"none","padding": "2px 10px 2px 2px","cursor":" pointer", display:"flex", alignItems:"center"}} onClick={() => {
                                                setGoogleLoading(true);
                                                fetch(
                                                    "https://us-central1-voice-reporting-engine.cloudfunctions.net/shopify-google-server",
                                                    {
                                                    method: "POST",
                                                    headers: {
                                                        "Content-Type": "application/x-www-form-urlencoded",
                                                    },
                                                    body: JSON.stringify({
                                                        calling_func: "google_authorize",
                                                        shop_domain: shop_domain,
                                                    }),
                                                    }
                                                )
                                                    .then((response) => response.json())
                                                    .then((data) => {
                                                        setGoogleLoading(false);
                                                        window.location.href = data["redirect_url"];
                                                    })
                                                    .catch((error) => {
                                                        setGoogleLoading(false);
                                                        toast.error("Please try again.")
                                                    });

                                                }}>
                                                <img style={{background:"white", padding:"6px", marginRight:"10px"}} alt='Google Ads' src={google_icon} width={"30px"}/> 
                                                <h6 style={{margin:"auto"}}>
                                                    Sign In
                                                </h6>
                                                </button>
                                            }
                                            { show_google_login_btn === false &&
                                                <button style={{color:"blue", border:"none", background:"transparent", "cursor":" pointer", display:"flex", alignItems:"center"}} 
                                                onClick={handle_sign_out}
                                                >
                                                <h6 style={{margin:"auto"}}>Sign Out</h6>
                                                </button>
                                            }
                                            </Col>
                                        </Row>
                                    </Container>
                                </Accordion.Body>
                            </Accordion.Item>

                            {/* Step - 2 (Facebook Ads Integration) */}
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>
                                    <img src={number_2} alt='2' width={"15px"} height={"15px"} /> 
                                    <b style={{marginLeft:"10px"}}>Integrate Facebook Ads</b> 
                                    { Step2Indicator && <img alt='connected' style={{padding:"6px", marginRight:"10px"}} src={connected_icon} width={"30px"}/> }
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Container>
                                        <Row>
                                            <Col sm={8}>Connect Facebook Ads Account with Shopify to get started.</Col>
                                            <Col sm={4}>
                                            { show_facebook_login_btn === true &&
                                                <button style={{backgroundColor:"#4285f4", color:"white", width:"8rem",border:"none","padding": "2px 10px 2px 2px","cursor":" pointer", display:"flex", alignItems:"center"}} onClick={() => {
                                                    setFacebookLoading(true);
                                                    fetch(
                                                        "https://us-central1-voice-reporting-engine.cloudfunctions.net/shopify-facebook-server",
                                                        {
                                                        method: "POST",
                                                        headers: {
                                                            "Content-Type": "application/x-www-form-urlencoded",
                                                        },
                                                        body: JSON.stringify({
                                                            calling_func: "facebook_authorize",
                                                            shop_domain: shop_domain,
                                                        }),
                                                        }
                                                    )
                                                        .then((response) => response.json())
                                                        .then((data) => {
                                                            setFacebookLoading(false);
                                                            window.location.href = data["redirect_url"];
                                                        })
                                                        .catch((error) => {
                                                            setFacebookLoading(false);
                                                            toast.error("Please try again.")
                                                        });
                                                }}>
                                                    <img style={{background:"white", padding:"6px", marginRight:"10px"}} alt='Facebook Ads' src={facebook_icon} width={"30px"}/> 
                                                    <h6 style={{margin:"auto"}}>
                                                        Sign In
                                                    </h6>
                                                </button>
                                            }
                                            { show_facebook_login_btn === false &&
                                                <button style={{color:"blue", border:"none", background:"transparent", "cursor":" pointer", display:"flex", alignItems:"center"}}>
                                                <h6 style={{margin:"auto"}}>Sign Out</h6>
                                                </button>
                                            }
                                            </Col>
                                        </Row>
                                    </Container>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        </div>
                        </>
                        :
                        <div className='main_conatiner_spinner'>
                            <Spinner animation="border"/>
                        </div>
                    }
                </>
            }
        </div>
    )
}

export default Home

// import React, { useState, useEffect } from 'react';
// import Accordion from 'react-bootstrap/Accordion';
// import Container from 'react-bootstrap/Container';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
// import Card from 'react-bootstrap/Card';
// import Button from 'react-bootstrap/Button';
// import Spinner from 'react-bootstrap/Spinner';
// import Modal from 'react-bootstrap/Modal';
// import Form from 'react-bootstrap/Form';
// import FormSelect from 'react-bootstrap/FormSelect';
// import FloatingLabel from 'react-bootstrap/FloatingLabel';
// import number_1 from '../images/number-1.png';
// import number_2 from '../images/number-2.png';
// import number_3 from '../images/number-3.png';
// import number_4 from '../images/number-4.png';
// import google_icon from '../images/adwords.png';
// import connected_icon from '../images/correct.png';
// import axios from 'axios';
// import Cookies from 'js-cookie';
// import {showToast as toast} from '../toastManager';


// const Home = ({ViewedPage, shop_domain, app_url}) => {
//     ViewedPage(4)
  
//     const [loaderData, setLoaderData ] = useState(null);

//     // Render Select Ads Account and conversion action setting
//     const [ads_account_select_dict, setAdsAccountSelectDict] = useState([]);
//     const [show_google_login_btn, setShowGoogleLogin] = useState(true);
//     const [selected_account, setSelectedAccount] = useState([]);
//     var [all_account_details, setAllAccountDetails] = useState({});
//     var [selected_account_details, setSelectedAccountDetails] = useState({});
//     const [active, setActive] = useState(false);
//     const [edit_Modal_active, setEditModalActive] = useState(false);
//     const [Step1Indicator, setStep1Indicator] = useState(!show_google_login_btn);
//     const [Step2Indicator, setStep2Indicator] = useState(false);
//     const [Step3Indicator, setStep3Indicator] = useState(false);
//     const [loading, setLoading] = useState(true);
//     const [setup_setting, setSetupSetting] = useState({"conv_display_name":"","id":"","db_id":"","name":"","detail":"","formula":""});
//     const [recommendation_checkbox, setRecommendation_checkbox] = useState(false);
//     const [send_revenue_with_tax, setSendRevenueWithTax] = useState(false);

//     const handle_sign_out = async (e) => {
//         e.preventDefault();
//         try {
//             if(Cookies.get('shop')){
//                 const { data } = await axios.post(app_url+"/api/google/sign_out",{
//                     shop_domain:Cookies.get('shop')
//                 }, {withCredentials:true});
//                 if(data.success){
//                     toast.success(data.message);
//                     setShowGoogleLogin(true);
//                     setSelectedAccount("");
//                     setAllAccountDetails({});
//                     setSelectedAccountDetails({
//                         send_adjustment_in_main_conv:"null",
//                         is_show_new_customer_setup_btn:true,
//                         is_show_profit_setup_btn: true,
//                         is_show_netRevenue_setup_btn: true,
//                         is_show_aov_setup_btn: true,
//                         is_show_new_customer_sync_off_btn:true,
//                         is_show_profit_sync_off_btn: true,
//                         is_show_netRevenue_sync_off_btn: true,
//                         is_show_aov_sync_off_btn:true,
//                         global_site_tag: "",
//                         new_customer_event_snippet:"",
//                         profit_event_snippet: "",
//                         netRevenue_event_snippet: "",
//                         aov_event_snippet: "",
//                         profit_conv_label: "",
//                         new_customer_conv_label:"",
//                         netRevenue_conv_label: "",
//                         aov_conv_label: "",
//                         aov_value_limit:100,
//                     });
//                     setStep1Indicator(false);
//                     setStep2Indicator(false);
//                     setStep3Indicator(false);
//                 }
//                 else{
//                     toast.error(data.message);
//                 }
//             }
//         } catch (error) {
//             toast.error("Please try again.");
//         }
//     };


//     const handle_change_ads_account = async (e) => {
//         e.preventDefault();
//         try {
//             if(Cookies.get('shop')){
//                 var ad_account = document.getElementById("ads_account_select").value;
//                 const { data } = await axios.post(app_url+"/api/google/change_ads_account",{
//                     shop_domain:Cookies.get('shop'),
//                     ads_account:ad_account
//                 }, {withCredentials:true});
//                 if(data.success){
//                     toast.success(data.message);
//                     setSelectedAccount(ad_account);
//                     setSelectedAccountDetails(all_account_details[ad_account]);
//                     var step2_flag = false;
//                     if( (show_google_login_btn === false) &&
//                     ((all_account_details[ad_account]["is_show_profit_setup_btn"] === false) ||  
//                     (all_account_details[ad_account]["is_show_netRevenue_setup_btn"] === false) ||
//                     (all_account_details[ad_account]["is_show_new_customer_setup_btn"] === false) ||    
//                     (all_account_details[ad_account]["is_show_aov_setup_btn"] === false))){    
//                       step2_flag = true;
//                     }
//                     setStep2Indicator(step2_flag)
//                 }
//                 else{
//                     toast.error(data.message);
//                 }
//             }
//         } catch (error) {
//             toast.error("Please try again.");
//         }
//     };

  
//     useEffect(() => {
//         try {
//             if(Cookies.get('shop')){
//                 axios.post(app_url+"/api/google/get_loader_data",{
//                     shop_domain:Cookies.get('shop')
//                 }, {withCredentials:true})
//                 .then(res => res.data)
//                 .then(data => {
//                     if(data.success){
//                         setLoaderData(data.data);
//                         // Render Select Ads Account and conversion action setting
//                         var temp_loader_data = data.data;
//                         setAdsAccountSelectDict(temp_loader_data["account_list"].map((ad_account) => { return {"label":ad_account, "value":ad_account.split(" - ")[0]}}));
//                         setShowGoogleLogin(temp_loader_data["show_google_login_btn"]);
//                         setSelectedAccount(temp_loader_data["selected_account"]);
//                         setAllAccountDetails(temp_loader_data["all_account_details"]);
//                         setSelectedAccountDetails(temp_loader_data["account_details"]);
//                         setActive(false);
//                         setEditModalActive(false);
//                         var step2_flag = false;
//                         if( (temp_loader_data["show_google_login_btn"] === false) &&
//                         ((temp_loader_data["account_details"]["is_show_profit_setup_btn"] === false) ||  
//                         (temp_loader_data["account_details"]["is_show_netRevenue_setup_btn"] === false) ||
//                         (temp_loader_data["account_details"]["is_show_aov_setup_btn"] === false) ||  
//                         (temp_loader_data["account_details"]["is_show_new_customer_setup_btn"] === false))){    
//                             step2_flag = true;
//                         }
//                         setStep1Indicator(!temp_loader_data["show_google_login_btn"]);
//                         setStep2Indicator(step2_flag);
//                         setStep3Indicator(false);
//                         setLoading(false);
//                         setSetupSetting({"conv_display_name":"","id":"","db_id":"","name":"","detail":"","formula":""});
//                         setRecommendation_checkbox(temp_loader_data["send_recommendations"]);
//                         setSendRevenueWithTax(temp_loader_data["send_revenue_with_tax"]);
//                     }
//                 })
//                 .catch(error => {
//                     toast.error(error)
//                 })
//             }
//         } catch (error) {
//             toast.error(error)
//         }
//     }, [app_url]);
    
//     const handleClose = () => setActive(false);
//     const handleShow = () => setActive(true);
//     const handleSubmitSetup = () => {
//         setLoading(true);
//         setActive(false);
//         fetch(
//             "https://us-central1-voice-reporting-engine.cloudfunctions.net/shopify-create-conversion-action",
//             {
//               method: "POST",
//               headers: {
//                 "Content-Type": "application/x-www-form-urlencoded",
//               },
//               body: JSON.stringify({
//                 "shop_domain": shop_domain,
//                 "customer_id": selected_account,
//                 "conversion_name": setup_setting["db_id"],
//               }),
//             }
//           )
//           .then((response) => response.json())
//           .then((data) => {
//             selected_account_details[`is_show_${setup_setting["id"]}_setup_btn`] = false;
//             selected_account_details[`is_show_${setup_setting["id"]}_sync_off_btn`] = false;
//             selected_account_details["global_site_tag"] = data["global_site_tag"]
//             if(setup_setting["db_id"] === "profit"){
//               selected_account_details["profit_event_snippet"] = data["snippet"];
//               selected_account_details["profit_conv_label"] = data["conversion_label"];
//             }
//             else if(setup_setting["db_id"] === "new_customer"){
//               selected_account_details["new_customer_event_snippet"] = data["snippet"];
//               selected_account_details["new_customer_conv_label"] = data["conversion_label"];
//             }
//             else if(setup_setting["db_id"] === "net_revenue"){
//               selected_account_details["netRevenue_event_snippet"] = data["snippet"];
//               selected_account_details["netRevenue_conv_label"] = data["conversion_label"];
//             }
//             else{
//               selected_account_details["aov_event_snippet"] = data["snippet"];
//               selected_account_details["aov_conv_label"] = data["conversion_label"];
//             }
//             all_account_details[selected_account] = selected_account_details
//             setAllAccountDetails(all_account_details);
//             setSelectedAccountDetails(selected_account_details);
//             setLoading(false);
//             if(
//               (selected_account_details["is_show_profit_setup_btn"] === false) ||
//               (selected_account_details["is_show_netRevenue_setup_btn"] === false) ||
//               (selected_account_details["is_show_new_customer_setup_btn"] === false) ||
//               (selected_account_details["is_show_aov_setup_btn"] === false)){    
//                 setStep2Indicator(true);
//             }
//           })
//           .catch((error) => {
//             setLoading(false);
//             toast.error("Conversion action not created.\nPlease try again.")
//             console.log("Error :- " + error);
//           });
//           if(setup_setting["db_id"] === "higher_aov"){
//             selected_account_details["aov_value_limit"] = document.getElementById("aov_value_limit_id").value;
//             all_account_details[selected_account] = selected_account_details
//             setAllAccountDetails(all_account_details);
//             setSelectedAccountDetails(selected_account_details)
//             axios.post(app_url+"/api/google/save_aov_value_limit",{
//                 "shop_domain":shop_domain.replace(".myshopify.com",""),
//                 "aov_value_limit":document.getElementById("aov_value_limit_id").value,
//                 "ad_account":selected_account
//             }, {withCredentials:true})
//         }
//     };
//     const handleEditClose = () => setEditModalActive(false);
//     const handleEditShow = () => setEditModalActive(true);
//     const handleSubmitEdit = () => {
//         if(setup_setting["db_id"] === "higher_aov"){
//             selected_account_details["aov_value_limit"] = document.getElementById("aov_value_limit_id").value;
//             all_account_details[selected_account] = selected_account_details
//             setAllAccountDetails(all_account_details);
//             setSelectedAccountDetails(selected_account_details)
//             axios.post(app_url+"/api/google/save_aov_value_limit",{
//                 "shop_domain":shop_domain.replace(".myshopify.com",""),
//                 "aov_value_limit":document.getElementById("aov_value_limit_id").value,
//                 "ad_account":selected_account
//             }, {withCredentials:true})
//             setEditModalActive(false)
//         }
//     };
    
//     function handleSyncStatusChange(conv_dict_id,conv_name,sync_status){
//         selected_account_details[conv_dict_id] = sync_status === "on" ? false:true;
//         all_account_details[selected_account] = selected_account_details
//         setAllAccountDetails(all_account_details);
//         setSelectedAccountDetails(selected_account_details);
//         axios.post(app_url+"/api/google/change_sync_status",{
//             "shop_domain":shop_domain.replace(".myshopify.com",""),
//             "ad_account":selected_account,
//             "conv_name":conv_name,
//             "sync_status":sync_status
//         }, {withCredentials:true})
//     }
    
//     return (
//         <div className='main-container'>
//             {!shop_domain?
//                 <div className='go_to_shopify_admin_div'>
//                     <h6 style={{display:'inline-block'}}>!! Go to Shopify Admin and open our app !!</h6>
//                 </div>
//                 :
//                 <>
//                         <Modal show={active} onHide={handleClose}>
//                             <Modal.Header closeButton>
//                             <Modal.Title>{`Conversion Action: ${setup_setting["conv_display_name"]}`}</Modal.Title>
//                             </Modal.Header>
//                             <Modal.Body>            
//                                 <p><b>Details</b> : {setup_setting["detail"]} (Formula : {setup_setting["formula"]})</p>
//                                 <p><b>Conversion Name {"("}on Google Ads{")"}</b> : {setup_setting["name"].split(" ").join("")}_Shopify_DM</p>
//                                 {setup_setting["db_id"] === "higher_aov" &&
//                                 <>
//                                     <label htmlFor="aov_value_limit_id"><b>Price</b> : </label>
//                                     <input style={{borderRadius:"10px", padding:"5px", border:"1px solid grey"}} type="number" id="aov_value_limit_id" placeholder={selected_account_details["aov_value_limit"]} />
//                                 </>
//                                 }
//                             </Modal.Body>
//                             <Modal.Footer>
//                                 <Button variant="secondary" onClick={handleClose}>
//                                     Close
//                                 </Button>
//                                 <Button variant="primary" onClick={handleSubmitSetup}>
//                                     Setup
//                                 </Button>
//                             </Modal.Footer>
//                         </Modal>

//                         <Modal show={edit_Modal_active} onHide={handleEditClose}>
//                             <Modal.Header closeButton>
//                             <Modal.Title>{`Conversion Action: ${setup_setting["conv_display_name"]}`}</Modal.Title>
//                             </Modal.Header>
//                             <Modal.Body>
//                                 <p><b>Details</b> : {setup_setting["detail"]} (Formula : {setup_setting["formula"]})</p>
//                                 <p><b>Conversion Name {"("}on Google Ads{")"}</b> : {setup_setting["name"].split(" ").join("")}_Shopify_DM</p>
//                                 {setup_setting["db_id"] === "higher_aov" &&
//                                 <>
//                                     <label htmlFor="aov_value_limit_id"><b>Price</b> : </label>
//                                     <input style={{borderRadius:"10px", padding:"5px", border:"1px solid grey"}} type="number" id="aov_value_limit_id" placeholder={selected_account_details["aov_value_limit"]} />
//                                 </>
//                                 }
//                             </Modal.Body>
//                             <Modal.Footer>
//                                 <Button variant="secondary" onClick={handleEditClose}>
//                                     Close
//                                 </Button>
//                                 <Button variant="primary" onClick={handleSubmitEdit}>
//                                     Save
//                                 </Button>
//                             </Modal.Footer>
//                         </Modal>
//                     {! loading ?
//                         <>
//                         <div className='main-title mt-4'>
//                             <h2>Google Ads Integration</h2>
//                         </div>
//                         <div className='main-accordion-body mt-4 p-2'>
//                         <Accordion defaultActiveKey={ Step1Indicator ? Step2Indicator ? Step3Indicator ? "3" : "2" : "1" : "0" }>
//                             {/* Step - 1 (Google Ads Integration) */}
//                             <Accordion.Item eventKey="0">
//                                 <Accordion.Header>
//                                     <img src={number_1} alt='1' width={"15px"} height={"15px"} /> 
//                                     <b style={{marginLeft:"10px"}}>Integrate Google Ads</b> 
//                                     { Step1Indicator && <img alt='connected' style={{padding:"6px", marginRight:"10px"}} src={connected_icon} width={"30px"}/> }
//                                 </Accordion.Header>
//                                 <Accordion.Body>
//                                     <Container>
//                                         <Row>
//                                             <Col sm={8}>Connect Google Ads Account with Shopify to get started.</Col>
//                                             <Col sm={4}>
//                                             { show_google_login_btn === true &&
//                                                 <button style={{backgroundColor:"#4285f4", color:"white", width:"8rem",border:"none","padding": "2px 10px 2px 2px","cursor":" pointer", display:"flex", alignItems:"center"}} onClick={() => {
//                                                 setLoading(true);
//                                                 fetch(
//                                                     "https://us-central1-voice-reporting-engine.cloudfunctions.net/shopify-google-server",
//                                                     {
//                                                     method: "POST",
//                                                     headers: {
//                                                         "Content-Type": "application/x-www-form-urlencoded",
//                                                     },
//                                                     body: JSON.stringify({
//                                                         calling_func: "google_authorize",
//                                                         shop_domain: shop_domain,
//                                                     }),
//                                                     }
//                                                 )
//                                                     .then((response) => response.json())
//                                                     .then((data) => {
//                                                         setLoading(false);
//                                                         window.location.href = data["redirect_url"];
//                                                     })
//                                                     .catch((error) => {
//                                                         setLoading(false);
//                                                         toast.error("Please try again.")
//                                                     });

//                                                 }}>
//                                                 <img style={{background:"white", padding:"6px", marginRight:"10px"}} alt='Google Ads' src={google_icon} width={"30px"}/> 
//                                                 <h6 style={{margin:"auto"}}>
//                                                     Sign In
//                                                 </h6>
//                                                 </button>
//                                             }
//                                             { show_google_login_btn === false &&
//                                                 <button style={{color:"blue", border:"none", background:"transparent", "cursor":" pointer", display:"flex", alignItems:"center"}} 
//                                                 onClick={handle_sign_out}
//                                                 >
//                                                 <h6 style={{margin:"auto"}}>Sign Out</h6>
//                                                 </button>
//                                             }
//                                             </Col>
//                                         </Row>
//                                     </Container>
//                                 </Accordion.Body>
//                             </Accordion.Item>

//                             {/* Step - 2 (Setup conversion action(s) ) */}
//                             <Accordion.Item eventKey="1">
//                                 <Accordion.Header>
//                                     <img src={number_2} alt='2' width={"15px"} height={"15px"} /> 
//                                     <b style={{marginLeft:"10px"}}>Set up Conversion Action(s)</b> 
//                                     { Step2Indicator && <img alt='connected' style={{padding:"6px", marginRight:"10px"}} src={connected_icon} width={"30px"}/> }
//                                 </Accordion.Header>
//                                 <Accordion.Body>
//                                     <FloatingLabel controlId='floatingSelect' label='Select Ads Account'>
//                                         <FormSelect defaultValue={selected_account} id='ads_account_select' onChange={handle_change_ads_account}>
//                                             { ads_account_select_dict.map((ads_acc_iter) => <option key={ads_acc_iter["value"]} value={ads_acc_iter["value"]}>{ads_acc_iter["label"]}</option>)}
//                                         </FormSelect>
//                                     </FloatingLabel>


//                                     <Row xs={1} sm={3}>
//                                         <Col className='main_conv_col'>
//                                             <Card className='main_conv_card'>
//                                                 <Card.Body>
//                                                     <div className='main-conv-card-body-center'>
//                                                         <h5 className='main-conv-card-heading' >Profit</h5>
//                                                         <p className='main-conv-card-p'>Total Sales after deducting Cost of Items</p>
//                                                         { selected_account_details["is_show_profit_setup_btn"] ?
//                                                             <Button variant='primary' onClick={() => {
//                                                                 setup_setting["conv_display_name"] = "Profit"
//                                                                 setup_setting["db_id"] = "profit"
//                                                                 setup_setting["id"] = "profit"
//                                                                 setup_setting["name"] = "Profit"
//                                                                 setup_setting["detail"] = "Total Sales after deducting Cost of Items"
//                                                                 setup_setting["formula"] = "Current Total - Current Tax - Unit Cost"
//                                                                 setSetupSetting(setup_setting);
//                                                                 handleShow();
//                                                             }}>Setup</Button>
//                                                             :
//                                                             selected_account_details["is_show_profit_sync_off_btn"] ?
//                                                                 <Button variant='secondary' onClick={() => {handleSyncStatusChange("is_show_profit_sync_off_btn","profit","on")}}>Sync Off</Button>
//                                                                 :
//                                                                 <Button variant='success' onClick={() => {handleSyncStatusChange("is_show_profit_sync_off_btn","profit","off")}}>Sync On</Button>
//                                                         }
//                                                     </div>
//                                                 </Card.Body>
//                                             </Card>
//                                         </Col>
//                                         <Col className='main_conv_col'>
//                                             <Card className='main_conv_card'>
//                                                 <Card.Body>
//                                                     <div className='main-conv-card-body-center'>
//                                                         <h5 className='main-conv-card-heading' >Net Revenue</h5>
//                                                         <p className='main-conv-card-p'>Total Sales after returns</p>
//                                                         { selected_account_details["is_show_netRevenue_setup_btn"] ?
//                                                             <Button variant='primary' onClick={() => {
//                                                                 setup_setting["conv_display_name"] = "Net Revenue"
//                                                                 setup_setting["db_id"] = "net_revenue"
//                                                                 setup_setting["id"] = "netRevenue"
//                                                                 setup_setting["name"] = "Net Revenue"
//                                                                 setup_setting["detail"] = "Total Sales after returns"
//                                                                 setup_setting["formula"] = "Current Total - Current Tax"
//                                                                 setSetupSetting(setup_setting);
//                                                                 handleShow();
//                                                             }}>Setup</Button>
//                                                             :
//                                                             selected_account_details["is_show_netRevenue_sync_off_btn"] ?
//                                                                 <Button variant='secondary' onClick={() => {handleSyncStatusChange("is_show_netRevenue_sync_off_btn","net_revenue","on")}}>Sync Off</Button>
//                                                                 :
//                                                                 <Button variant='success' onClick={() => {handleSyncStatusChange("is_show_netRevenue_sync_off_btn","net_revenue","off")}}>Sync On</Button>
//                                                         }
//                                                     </div>
//                                                 </Card.Body>
//                                             </Card>
//                                         </Col>
//                                         <Col className='main_conv_col'>
//                                             <Card className='main_conv_card'>
//                                                 <Card.Body>
//                                                     <div className='main-conv-card-body-center'>
//                                                         <h5 className='main-conv-card-heading' >Higher AOV</h5>
//                                                         <p className='main-conv-card-p'>Orders with revenue greater than {selected_account_details["aov_value_limit"]}</p>
//                                                         {selected_account_details["is_show_aov_setup_btn"] === false &&
//                                                             <>
//                                                                 <Button variant='primary' onClick={ () => {
//                                                                     setup_setting["conv_display_name"] = "Higher AOV"
//                                                                     setup_setting["db_id"] = "higher_aov"
//                                                                     setup_setting["id"] = "aov"
//                                                                     setup_setting["name"] = "Higher AOV"
//                                                                     setup_setting["detail"] = `Orders with revenue greater than ${selected_account_details["aov_value_limit"]}`
//                                                                     setup_setting["formula"] = `Revenue > ${selected_account_details["aov_value_limit"]}`
//                                                                     setSetupSetting(setup_setting);
//                                                                     handleEditShow();
//                                                                 }}>Edit</Button>
//                                                                 {" "}
//                                                             </>

//                                                         }
//                                                         { selected_account_details["is_show_aov_setup_btn"] ?
//                                                             <Button variant='primary' onClick={() => {
//                                                                 setup_setting["conv_display_name"] = "Higher AOV"
//                                                                 setup_setting["db_id"] = "higher_aov"
//                                                                 setup_setting["id"] = "aov"
//                                                                 setup_setting["name"] = "Higher AOV"
//                                                                 setup_setting["detail"] = `Orders with revenue greater than ${selected_account_details["aov_value_limit"]}`
//                                                                 setup_setting["formula"] = `Revenue > ${selected_account_details["aov_value_limit"]}`
//                                                                 setSetupSetting(setup_setting);
//                                                                 handleShow();
//                                                             }}>Setup</Button>
//                                                             :
//                                                             selected_account_details["is_show_aov_sync_off_btn"] ?
//                                                                 <Button variant='secondary' onClick={() => {handleSyncStatusChange("is_show_aov_sync_off_btn","higher_aov","on")}}>Sync Off</Button>
//                                                                 :
//                                                                 <Button variant='success' onClick={() => {handleSyncStatusChange("is_show_aov_sync_off_btn","higher_aov","off")}}>Sync On</Button>
//                                                         }
//                                                     </div>
//                                                 </Card.Body>
//                                             </Card>
//                                         </Col>
//                                         <Col className='main_conv_col'>
//                                             <Card className='main_conv_card'>
//                                                 <Card.Body>
//                                                     <div className='main-conv-card-body-center'>
//                                                         <h5 className='main-conv-card-heading' >New Customer</h5>
//                                                         <p className='main-conv-card-p'>First-time online shopper or buyer</p>
//                                                         { selected_account_details["is_show_new_customer_setup_btn"] ?
//                                                             <Button variant='primary' onClick={() => {
//                                                                 setup_setting["conv_display_name"] = "New Customer"
//                                                                 setup_setting["db_id"] = "new_customer"
//                                                                 setup_setting["id"] = "new_customer"
//                                                                 setup_setting["name"] = "New Customer"
//                                                                 setup_setting["detail"] = "First-time online shopper or buyer."
//                                                                 setup_setting["formula"] = ""
//                                                                 setSetupSetting(setup_setting);
//                                                                 handleShow();
//                                                             }}>Setup</Button>
//                                                             :
//                                                             selected_account_details["is_show_new_customer_sync_off_btn"] ?
//                                                                 <Button variant='secondary' onClick={() => {handleSyncStatusChange("is_show_new_customer_sync_off_btn","new_customer","on")}}>Sync Off</Button>
//                                                                 :
//                                                                 <Button variant='success' onClick={() => {handleSyncStatusChange("is_show_new_customer_sync_off_btn","new_customer","off")}}>Sync On</Button>
//                                                         }
//                                                     </div>
//                                                 </Card.Body>
//                                             </Card>
//                                         </Col>
//                                     </Row>

//                                     <div key={`inline-radio`} className="mt-5">
//                                         <Form.Label><b>Do you recognize revenue with or without tax on Google ads ?</b></Form.Label>
//                                         <br />
//                                         <Form.Check
//                                             inline
//                                             checked={!send_revenue_with_tax}
//                                             label="Without Tax"
//                                             name="group1"
//                                             type={'radio'}
//                                             id={`inline-radio-1`}
//                                             onChange={()=>{
//                                                 setSendRevenueWithTax(false);
//                                                 axios.post(app_url+"/api/google/send_revenue_with_tax",{
//                                                     "shop_domain":shop_domain.replace(".myshopify.com",""),
//                                                     "status":false
//                                                 }, {withCredentials:true})
//                                             }}
//                                             />
//                                         <Form.Check
//                                             inline
//                                             checked={send_revenue_with_tax}
//                                             label="With Tax"
//                                             name="group1"
//                                             type={'radio'}
//                                             id={`inline-radio-2`}
//                                             onChange={()=>{
//                                                 setSendRevenueWithTax(true);
//                                                 axios.post(app_url+"/api/google/send_revenue_with_tax",{
//                                                     "shop_domain":shop_domain.replace(".myshopify.com",""),
//                                                     "status":"true"
//                                                 }, {withCredentials:true})
//                                             }}
//                                             />
//                                     </div>
//                                 </Accordion.Body>
//                             </Accordion.Item>

//                             {/* Step - 3) Show GTM parameter */}
//                             { (loaderData["is_show_upload_from_code"] || loaderData["is_show_upload_from_gtm"]) &&
//                                 <>
//                                     <Accordion.Item eventKey="2">
//                                         <Accordion.Header>
//                                             <img src={number_3} alt='1' width={"15px"} height={"15px"} /> 
//                                             <b style={{marginLeft:"10px"}}>Copy the code to Shopify Store</b> 
//                                             { Step3Indicator && <img alt='connected' style={{padding:"6px", marginRight:"10px"}} src={connected_icon} width={"30px"}/> }
//                                         </Accordion.Header>
//                                         <Accordion.Body>
//                                         { loaderData["is_show_upload_from_code"] &&
//                                             <>
//                                             <Card className='my-3'>
//                                                 <Card.Body>
//                                                     <p>{selected_account_details["global_site_tag"]}</p>
//                                                 </Card.Body>
//                                                 <Card.Footer>
//                                                     <span className='gtm_conv_label'>1. Go to the "Online Store" section in "Sales channels".</span>
//                                                     <span className='gtm_conv_label'>2. Click on "•••" button just near "Customize" button.</span>
//                                                     <span className='gtm_conv_label'>3. After that, Click the "Edit code" button.</span>
//                                                     <span className='gtm_conv_label'>4. Finally, open the "theme.liquid" file </span>
//                                                     <span className='gtm_conv_label'>5. Paste the above code just above the {"</head>"} section</span>
//                                                 </Card.Footer>
//                                             </Card>
                                                    
//                                             <Card className='my-3'>
//                                                 <Card.Body>
//                                                     { ((selected_account_details["profit_event_snippet"] !== "") || (selected_account_details["netRevenue_event_snippet"] !== "") || (selected_account_details["aov_event_snippet"] !== "")) && 
//                                                         <p>
//                                                         {`<script>`}<br />
//                                                         &nbsp;&nbsp;{`function(){`}<br />
//                                                         &nbsp;&nbsp;&nbsp;&nbsp;{`var _uid_;`}<br />
//                                                         &nbsp;&nbsp;&nbsp;&nbsp;{`var formated_id = ""+_uid_;`}<br />
//                                                         &nbsp;&nbsp;&nbsp;&nbsp;{`var encodedString = btoa(formated_id);`}<br />
//                                                         &nbsp;&nbsp;&nbsp;&nbsp;{`window.localStorage.setItem("_uid_",_uid_);`}<br />
//                                                         &nbsp;&nbsp;{`}`}<br />
//                                                         {`<script/>`}
//                                                         </p>
//                                                     }
//                                                     <p>{selected_account_details["global_site_tag"]}</p>
//                                                     <p>{selected_account_details["profit_event_snippet"]}</p>
//                                                     <p>{selected_account_details["netRevenue_event_snippet"]}</p>
//                                                     <p>{selected_account_details["aov_event_snippet"]}</p>
//                                                     <p>{selected_account_details["new_customer_event_snippet"]}</p>
//                                                 </Card.Body>
//                                                 <Card.Footer>
//                                                     <span>
//                                                     1. Copy the above code and go to <b style={{ cursor:"pointer", textDecoration:"underline", color:"blue" }} onClick={() => {
//                                                         if((Step1Indicator) && (Step2Indicator)){
//                                                         setStep3Indicator(!Step3Indicator)
//                                                         }
//                                                         window.open(`https://admin.shopify.com/store/${shop_domain.replace(".myshopify.com","")}/settings/checkout`);
//                                                     }}>Settings{">"}Checkout{">"}Additional Script</b>
//                                                     </span>
//                                                     <span className='gtm_conv_label'>2. Paste the code in Additional Script section</span>
//                                                     <span className='gtm_conv_label'>3. Save the code</span>
//                                                 </Card.Footer>
//                                             </Card>

//                                             </>
//                                         }


//                                         { loaderData["is_show_upload_from_gtm"] &&
//                                             <Card className='my-3'>
//                                                 <Card.Body>
//                                                     { selected_account_details["profit_conv_label"] !== "" &&
//                                                         <span className='gtm_conv_label'>Profit - {selected_account_details["profit_conv_label"]}</span>
//                                                     }
//                                                     { selected_account_details["netRevenue_conv_label"] !== "" &&
//                                                         <span className='gtm_conv_label'>Net Revenue - {selected_account_details["netRevenue_conv_label"]}</span>
//                                                     }
//                                                     { selected_account_details["aov_conv_label"] !== "" &&
//                                                         <span className='gtm_conv_label'>Higher AOV - {selected_account_details["aov_conv_label"]}</span>
//                                                     }
//                                                     { selected_account_details["new_customer_conv_label"] !== "" &&
//                                                         <span className='gtm_conv_label'>New Customer - {selected_account_details["new_customer_conv_label"]}</span>
//                                                     }
//                                                 </Card.Body>
//                                                 <Card.Footer>Set up GTM with the above labels</Card.Footer>
//                                             </Card>
//                                         }
//                                         </Accordion.Body>
//                                     </Accordion.Item>
//                                 </>
//                             }

//                             {/* Step - 4 */}
//                             <Accordion.Item eventKey="3">
//                                 <Accordion.Header>
//                                     { ((loaderData["is_show_upload_from_code"] === false) && (loaderData["is_show_upload_from_gtm"] === false)) ?
//                                         <img src={number_3} alt='3' width={"15px"} height={"15px"} /> :
//                                         <img src={number_4} alt='4' width={"15px"} height={"15px"} />
//                                     } 
//                                     <b style={{marginLeft:"10px"}}>You are all set!</b>
//                                 </Accordion.Header>
//                                 <Accordion.Body>
//                                 </Accordion.Body>
//                             </Accordion.Item>
//                         </Accordion>
//                         </div>
//                         </>
//                         :
//                         <div className='main_conatiner_spinner'>
//                             <Spinner animation="border"/>
//                         </div>
//                     }
//                 </>
//             }
//         </div>
//     )
// }

// export default Home