import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import {showToast as toast} from '../toastManager';
import add_reports from '../images/add_reports.png';
import star_logo from '../images/star.png';
import comparative_logo from '../images/comparison.png';
import Cookies from "js-cookie";
import axios from 'axios';
import { v4 as uuidv4 } from "uuid";


function Reports({ViewedPage, shop_domain, app_url}) {
    ViewedPage(18);
    const [loading, setLoading] = useState(true);
    const [reports_settings, setReportsSettings] = useState([]);
    const [show_create_new_report, setShowCreateNewReport] = useState(false);
    const handle_hide_create_new_report = () => setShowCreateNewReport(false);
    const navigate = useNavigate();


    useEffect(() => {
        setLoading(true);
        try {
            if(Cookies.get('shop')){
                axios.post(app_url+"/api/facebook/meta_creative_settings",{
                    shop_domain:Cookies.get('shop'),
                }, {withCredentials:true})
                .then(res => res.data)
                .then(data => {
                    console.log(data["data"]["reports_settings"])
                    setReportsSettings(data["data"]["reports_settings"])
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                    toast.error("Please try after some time.");
                    console.log(err);
                })
            }
        } catch (error) {
            setLoading(false)
            toast.error("Please try after some time.");
            console.log(error);
        }

    }, [app_url, shop_domain]);

    
    const handle_create_new_report = (report_type) => {
        setShowCreateNewReport(false);
        var max_date = new Date();
        var min_date = new Date(new Date().setDate(max_date.getDate() - 15));
        const new_reportId = uuidv4(); // Replace with your dynamic ID
        const new_reportData = {
            "name": "Report",
            "type": report_type,
            "id": new_reportId,
            "start_date": formatDate(min_date),
            "end_date": formatDate(max_date),
            "group_of_ads": [],
            "filters": [],
            "metrics": [
                {
                "value": "spend",
                "label": "Spend"
                },
                {
                "value": "SUM(CAST(purchase_value AS FLOAT))/SUM(CAST(spend AS FLOAT))",
                "label": "ROAS"
                },
            ],
            "groupby": {
                "value": "ad_name",
                "label": "Ad name"
            },
            "detail": "Type a description for this report."
        };
        navigate(`/report/${report_type}/${new_reportId}`, { state: new_reportData });
    };
    
    

    const handle_open_report = (report_id, report_data) => {
        setShowCreateNewReport(false);
        navigate(`/report/${report_data["type"]}/${report_id}`, { state: report_data });
    }


    return (
    <div className='main-container'>
        { !loading ?
            <>
                <Modal show={show_create_new_report} onHide={handle_hide_create_new_report}>
                    <Modal.Header closeButton>
                        <Modal.Title><h5 style={{fontWeight:"bolder"}}>Create new report</h5></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='d-flex align-items-center flex-row' style={{backgroundColor:"#edf1f4", borderRadius:"0.5rem", padding:"0.5rem", margin:"1rem", cursor:"pointer"}} onClick={(e) => {
                            e.preventDefault();
                            handle_create_new_report("top_performing");
                        }}>
                            <div className='m-2'>
                                <img src={star_logo} alt="" width={30} />
                            </div>
                            <div className='m-2'>
                                <h6 className='mb-2'>Top Performing</h6>
                                <p style={{color:"#adadad", fontSize:"14px", lineHeight:"18px", marginBottom:"0"}}>See which creatives are performing best.</p>
                            </div>
                        </div>
                        <div className='d-flex align-items-center flex-row' style={{backgroundColor:"#edf1f4", borderRadius:"0.5rem", padding:"0.5rem", margin:"1rem", cursor:"pointer"}} onClick={(e) => {
                            e.preventDefault();
                            handle_create_new_report("comparative_analysis");
                        }}>
                            <div className='m-2'>
                                <img src={comparative_logo} alt="" width={30} />
                            </div>
                            <div className='m-2'>
                                <h6 className='mb-2'>Comparative Analysis</h6>
                                <p style={{color:"#adadad", fontSize:"14px", lineHeight:"18px", marginBottom:"0"}}>Compare multiple sets of creatives against one another.</p>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>


                <div className='main-title mt-4'>
                    <h2>Reports</h2>
                </div>

                <Container className='my-4'>
                    <Row className='justify-content-md-left' lg={6} xs={1}>
                        <Col className='d-flex justify-content-center align-items-center flex-direction-row flex-column m-2 p-3' style={{backgroundColor:"white", borderRadius:"0.5rem", height:"15rem", cursor:"pointer"}} onClick={(e) => {
                            e.preventDefault();
                            setShowCreateNewReport(true);
                        }}>
                            <h5><img src={add_reports} alt='+' width={30} /></h5>
                            <h5>Create Report</h5>
                        </Col>
                        {Object.keys(reports_settings).map((report_id, index) => {
                            return (
                                <Col className='d-flex justify-content-center align-items-center flex-direction-row flex-column m-2 p-3' style={{backgroundColor:"white", borderRadius:"0.5rem", height:"15rem", cursor:"pointer"}} onClick={(e) => {
                                    e.preventDefault();
                                    handle_open_report(report_id,reports_settings[report_id]);
                                }}>
                                    <h3 style={{ fontWeight:"bold", color:"#3db79d"}}>{index+1}</h3>
                                    <h5 className='mb-3'>{reports_settings[report_id]["name"]}</h5>
                                    <p style={{textAlign:"center", color:"#adadad", fontSize:"14px", lineHeight:"18px"}}><on_hover_tooltip title={reports_settings[report_id]["detail"]}>{reports_settings[report_id]["detail"].substring(0,80)+"..."}</on_hover_tooltip></p>
                                </Col>
                            )
                        })}
                    </Row>
                </Container>

            </>
            :   
            <div className='main_conatiner_spinner'>
                <Spinner animation="border"/>
            </div>
        }
      </div>
    );

}

export default Reports


function formatDate(date, is_increase_date=false) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + (is_increase_date? d.getDate() + 1: d.getDate()),
      year = d.getFullYear();
  
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
  
    return [year, month, day].join('-');
}