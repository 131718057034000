import React, { useEffect, useState } from 'react'
import { Chart } from "react-google-charts";
import { DateRangePicker } from "rsuite";
import alasql from "alasql";
import axios from 'axios';
import Cookies from 'js-cookie';
import {showToast as toast} from '../toastManager';
import Spinner from 'react-bootstrap/Spinner';
import Container from 'react-bootstrap/Container';
import Label from 'react-bootstrap/FormLabel';
import FormSelect from 'react-bootstrap/FormSelect';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


function RepurchaseRate({ViewedPage, shop_domain, app_url}) {
    ViewedPage(8)
    
    const { combine, before, afterToday } = DateRangePicker;
    const max_date = new Date();
    max_date.setDate(max_date.getDate() - 365);

    const [loading, setLoading] = useState(true);
    const [selected_date_range_value, setDateRange] = useState([]);
    const [order_raw_data, setOrderRawData] = useState([]);
    const [all_cust_repurchase_rate_data, setAllCustRepurchaseRate] = useState([]);
    const [new_cust_repurchase_rate_data, setNewCustRepurchaseRate] = useState([]);
    const [repeat_cust_repurchase_rate_data, setRepeatCustRepurchaseRate] = useState([]);

    const bar_charts_config = {
        width:"100%",
        height:"500px",
        vAxis: {
            ticks: [{v:0,f:"0%"}, {v:50,f:"50%"}, {v:100,f:"100%"}]  // Custom tick marks on the y-axis
        },
        legend: { position: "none" },
        tooltip: { isHtml: true }
    };
    
    useEffect(() => {
        setLoading(true);
        try {
            if(Cookies.get('shop')){
                var created_at_max = new Date();
                var created_at_min = new Date(new Date().setDate(created_at_max.getDate() - 60));
                setDateRange([created_at_min, created_at_max]);
                axios.post(app_url+"/api/shopify/get_shopify_order_data",{
                    shop_domain:Cookies.get('shop'),
                    created_at_min:formatDate(created_at_min),
                    created_at_max:formatDate(created_at_max)
                }, {withCredentials:true})
                .then(request => request.data)
                .then(data => {
                    var formated_data = formate_shopify_data(data.data.order_data);
                    setOrderRawData(formated_data);

                    setAllCustRepurchaseRate(get_high_repeat_product(formated_data, "product_title", "all", formatDate(created_at_min), formatDate(created_at_max)));
                    setNewCustRepurchaseRate(get_high_repeat_product(formated_data, "product_title", "new", formatDate(created_at_min), formatDate(created_at_max)));
                    setRepeatCustRepurchaseRate(get_high_repeat_product(formated_data, "product_title", "repeat", formatDate(created_at_min), formatDate(created_at_max)));

                    setLoading(false);
                })
                .catch(err => {
                    setLoading(false);
                    toast.error("No Data to Show.");
                    console.log(err)
                });                
            }
        }
        catch(err){
            toast.error("No Data to Show.");
            console.log(err)
        }
    }, [app_url, shop_domain]);


    const handleDateChange = async (date_range) => {
        setDateRange(date_range);
        var created_at_max = new Date();
        var created_at_min = new Date(new Date().setDate(created_at_max.getDate() - 60));
        if(date_range.length > 0){
            created_at_min = date_range[0];
            created_at_max = date_range[1];
        }
        var repurchase_rate_by = document.getElementById("repurchase_rate_by").value;
        setLoading(true);

        try {
            if(Cookies.get('shop')){
                axios.post(app_url+"/api/shopify/get_shopify_order_data",{
                    shop_domain:Cookies.get('shop'),
                    created_at_min:formatDate(created_at_min),
                    created_at_max:formatDate(created_at_max)
                }, {withCredentials:true})
                .then(request => request.data)
                .then(data => {
                    var formated_data = formate_shopify_data(data.data.order_data);
                    setAllCustRepurchaseRate(get_high_repeat_product(formated_data, repurchase_rate_by, "all", formatDate(created_at_min), formatDate(created_at_max)));
                    setNewCustRepurchaseRate(get_high_repeat_product(formated_data, repurchase_rate_by, "new", formatDate(created_at_min), formatDate(created_at_max)));
                    setRepeatCustRepurchaseRate(get_high_repeat_product(formated_data, repurchase_rate_by, "repeat", formatDate(created_at_min), formatDate(created_at_max)));
                    setLoading(false);
                })
                .catch(err => {
                    setLoading(false);
                    toast.error("No Data to Show.");
                    console.log(err)
                });
            }
        }
        catch(err){
            toast.error("No Data to Show.");
            console.log(err)
        }

    }
    
    const handleFilters = async (e) => {
        e.preventDefault();
        var repurchase_rate_by = document.getElementById("repurchase_rate_by").value;
        setLoading(true);
        try{
            var formated_data = formate_shopify_data(order_raw_data);
            setAllCustRepurchaseRate(get_high_repeat_product(formated_data, repurchase_rate_by, "all", formatDate(selected_date_range_value[0]), formatDate(selected_date_range_value[1])));
            setNewCustRepurchaseRate(get_high_repeat_product(formated_data, repurchase_rate_by, "new", formatDate(selected_date_range_value[0]), formatDate(selected_date_range_value[1])));
            setRepeatCustRepurchaseRate(get_high_repeat_product(formated_data, repurchase_rate_by, "repeat", formatDate(selected_date_range_value[0]), formatDate(selected_date_range_value[1])));
            setLoading(false);
        }
        catch(err){
            toast.error("No Data to Show.");
            console.log(err)
        }
    }



    return (
        <div className='main-container'>
            {!shop_domain?
                <div className='go_to_shopify_admin_div'>
                    <h5 style={{display:'inline-block'}}>!! Go to Shopify Admin and open our app !!</h5>
                </div>
                :
                <>
                    { !loading ?
                        <>
                            <div className='main-title mt-4'>
                                <h2>Repurchase rate</h2>
                            </div>


                            <Container className='mt-3'>
                                <Row className='justify-content-md-left' lg={3} xs={1}>
                                    <Col className='p-2'>
                                        <Label className="charts_filter_label">Time Period</Label>
                                        <br />
                                        <DateRangePicker shouldDisableDate={combine(before(max_date), afterToday())} editable={false} id='date_range' defaultValue={selected_date_range_value} format="yyyy-MM-dd" character=" - " showOneCalendar block onChange={handleDateChange} />
                                    </Col>
                                    <Col className='p-2'>
                                        <Label className="charts_filter_label">Repurchase rate by</Label>
                                        <br />
                                        <FormSelect defaultValue={"all"} id='repurchase_rate_by' onChange={handleFilters}>
                                            <option key="product_title" value="product_title">Product</option>
                                            <option key="product_type" value="product_type">Product Type</option>
                                            <option key="sku" value="sku">SKU</option>
                                        </FormSelect>                                        
                                    </Col>
                                </Row>
                            </Container>

                            
                            <Container>
                                <Row className='justify-content-md-left mt-3' sm={1} xs={1}>
                                    <Col  className='p-2'>
                                        <p className='metric-performance-charts-title'>All Customers</p>
                                        { all_cust_repurchase_rate_data.length > 0 ?
                                            <Chart
                                                chartType="ColumnChart"
                                                width={"100%"}
                                                height={"400px"}
                                                data={all_cust_repurchase_rate_data}
                                                options={bar_charts_config}
                                            />
                                            :
                                            <p className='metric-performance-charts-title' style={{background:"white", padding:"20px"}}>No Data</p>
                                        }
                                    </Col>
                                    <Col  className='p-2'>
                                        <p className='metric-performance-charts-title'>New Customers</p>
                                        { new_cust_repurchase_rate_data.length > 0 ?
                                            <Chart
                                                chartType="ColumnChart"
                                                width={"100%"}
                                                height={"400px"}
                                                data={new_cust_repurchase_rate_data}
                                                options={bar_charts_config}
                                            />
                                        :
                                            <p className='metric-performance-charts-title' style={{background:"white", padding:"20px"}}>No Data</p>
                                        }
                                    </Col>
                                    <Col  className='p-2'>
                                        <p className='metric-performance-charts-title'>Repeat Customers</p>
                                        { repeat_cust_repurchase_rate_data.length > 0 ?
                                            <Chart
                                            chartType="ColumnChart"
                                            width={"100%"}
                                            height={"400px"}
                                            data={repeat_cust_repurchase_rate_data}
                                            options={bar_charts_config}
                                            />
                                        :
                                            <p className='metric-performance-charts-title' style={{background:"white", padding:"20px"}}>No Data</p>
                                        }
                                    </Col>
                                </Row>
                            </Container>
                        </>
                        :
                        <div className='main_conatiner_spinner'>
                            <Spinner animation="border"/>
                        </div>
                    }
                </>
            }
        </div>
    )
}

export default RepurchaseRate


function formatDate(date, is_increase_date=false) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + (is_increase_date? d.getDate() + 1: d.getDate()),
      year = d.getFullYear();
  
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
  
    return [year, month, day].join('-');
  }


function jsonToArrayObject(res, graph_columns, key) {
    var columns = Object.keys(res[0]);
    var resultArray = res.map(function (obj) {
      var res_arr = [];
      let i=0;
      for (let column_name of columns) {
        if(i === 0){
            res_arr.push(obj[column_name]);
        }
        else if(i === 1){
            res_arr.push(obj[column_name]);
            res_arr.push("#28ccff");
            res_arr.push(`
                <div style="background-color:white; padding:20px; width:fit-content; border-radius:5px; box-shadow:0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)">
                    <h6 style="margin:3px;">${obj[key]}</h6>
                    <p style="margin:3px; font-size:medium;">Repurchase Rate: <b>${obj["repeat_per"]} %</b></p>
                    <p style="margin:3px; font-size:medium;">Orders: <b>${obj["total_order"]}</b></p>
                </div>
            `);
        }

        i++;
      }
      return res_arr;
    });
    resultArray.unshift(graph_columns)
   return resultArray;
}


function formate_shopify_data(data){
    var res = alasql('SELECT country, created_at, getDateString(created_at) as Date, currency_code, CAST(current_total_price AS FLOAT) as current_total_price, customer_id, customer_joining_date, customer_tags, discount_code, discount_codes, id, last_visit_campaign as utm_campaign, last_visit_content as utm_content, last_visit_medium as utm_medium, last_visit_source as utm_source, last_visit_term as utm_term, product_title, product_type, profit, sku, tags, CAST(total_price AS FLOAT) as total_price FROM ?',[data]);

    return res;
}


function get_high_repeat_product(data, repeat_by, customer_type, created_at_min, created_at_max){
    var filter_data = data;
    if(customer_type === "new"){
        filter_data = alasql(`SELECT * FROM ? WHERE (DATE(getDateString(customer_joining_date)) >= DATE('${created_at_min}') AND DATE(getDateString(customer_joining_date)) <= DATE('${created_at_max}'))`,[filter_data]);
    }
    if(customer_type === "repeat"){
        filter_data = alasql(`SELECT * FROM ? WHERE DATE(getDateString(customer_joining_date)) < DATE('${created_at_min}')`,[filter_data]);
    }
    let product_res = alasql(`SELECT ${repeat_by}, count(id) as total_order FROM ? GROUP BY ${repeat_by}`,[filter_data]);
    let res = alasql('SELECT * FROM ? ORDER BY customer_id, created_at',[filter_data]);
    res = alasql(`SELECT customer_id, FIRST(${repeat_by}) as ${repeat_by}, COUNT(id) as no_of_orders FROM ? GROUP BY customer_id`,[res]);
    res = alasql(`SELECT ${repeat_by}, COUNT(case when no_of_orders > 1 then customer_id else null end) as repeat_customer, COUNT(customer_id) as total_customer FROM ? GROUP BY ${repeat_by}`,[res]);
    let final_res = [];
    let final_product_res = [];
    
    for(const iter of res){
        for(const filter_on_iter of Array.from(new Set(iter[repeat_by].split("<br />")))){
            let filter_on_and_value_dict = {}
            filter_on_and_value_dict[repeat_by] = filter_on_iter;
            filter_on_and_value_dict["repeat_customer"] = iter["repeat_customer"];
            filter_on_and_value_dict["total_customer"] = iter["total_customer"];
            final_res.push(filter_on_and_value_dict);
        }
    }

    for(const iter of product_res){
        for(const filter_on_iter of Array.from(new Set(iter[repeat_by].split("<br />")))){
            let filter_on_and_value_dict = {}
            filter_on_and_value_dict[repeat_by] = filter_on_iter;
            filter_on_and_value_dict["total_order"] = iter["total_order"];
            final_product_res.push(filter_on_and_value_dict);
        }
    }

    
    final_product_res = alasql(`SELECT ${repeat_by}, SUM(total_order) as total_order FROM ? GROUP BY ${repeat_by}`,[final_product_res]);
    final_res = alasql(`SELECT ${repeat_by}, COALESCE(ROUND(((SUM(repeat_customer)/SUM(total_customer))*100),1)) as repeat_per FROM ? GROUP BY ${repeat_by}`,[final_res]);
    final_res = alasql(`SELECT * FROM ? a LEFT JOIN ? b ON a.${repeat_by} = b.${repeat_by}`,[final_res, final_product_res])
    let query_limit = (10/100)*final_res.length > 15 ? 15 : (10/100)*final_res.length;
    final_res = alasql(`SELECT * FROM ? ORDER BY total_order DESC LIMIT ${query_limit}`,[final_res]);

            
    let repeat_by_mapping = {
        "product_title":"Product Title",
        "product_type":"Product Type",
        "sku":"SKU"
    };
    return final_res.length > 0 ? jsonToArrayObject(final_res ,[repeat_by_mapping[repeat_by],'Repurchase Rate',{ role: 'style' },{'role': 'tooltip', 'p': {'html': true}}], repeat_by) : [];
}